import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import {
  ActorEmittedEvent,
  KeysAndValues,
  ask,
  getData,
  listenTo,
  stopListeningTo,
} from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
  UserRole,
} from "../../actors/auth/types";
import {
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
  Topic,
} from "../../actors/course/types";
import axios from "axios";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import addNoteDark from "./images/addnotedark.svg";
import bookMark from "./images/Bookmark.svg";
import bookMarkDark from "./images/Bookmarkdark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import "./DummyCoursePlayer.css";
import { any } from "zod";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
import NotesBookmarksButton from "../common/NotesBookmarksButton";
import { config } from "../../config";
import {
  SubscriptionClient_Name,
  SubscriptionType,
} from "../../actors/subscription/types";
import { useSubscriptionNavigation } from "../../appUtils";

const myName = "Dashboard-gui";

const DummyCoursePlayer = () => {
  const { courseId, moduleId, sessionName, sessionId } = useParams();
  const location = useLocation();
  const myObject = location.state;
  console.log("MY OBJECT FROM NAVIGATE PARAMS", myObject);

  const [htmlContent, setHtmlContent] = useState("");
  const [hidePreviousButton, setHidePreviousButton] = useState(false);

  const [isAddNotesOpen, setAddNotesOpen] = useState(false);
  const [isBookmarksOpen, setBookmarksOpen] = useState(false);

  const [addNotesValue, setAddNotesValue] = useState("");
  const [addBookMarksValue, setAddBookMarksValue] = useState("");

  const [isNotesEdit, setIsNotesEdit] = useState(false);
  const [isBookmarkView, setIsBookmarkView] = useState(false);

  const [menuLinks, setMenuLinks] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<Topic[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const [isNotesBookmarksFetched, setIsNotesBookmarksFetched] = useState(false);
  const [courseClientState, setCourseClientState] =
    useState<CourseClient_States>();
  const [authClientData, setAuthClientData] = useState<AuthClient_Data>();
  const [isNotesPresent, setIsNotesPresent] = useState(false);
  const [isBookmarksPresent, setIsBookmarksPresent] = useState(false);
  const [isTopicsFetched, setIsTopicsFetched] = useState(false);

  const [oldSessionIdValue, setOldSessionIdValue] = useState("");
  const [sessionIdValue, setSessionIdValue] = useState("");

  const [isHover, setIsHover] = useState(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] =
    useState<boolean>(false);

  const subscriptionNavigation = useSubscriptionNavigation();

  const handleAddNotesClick = async () => {
    setBookmarksOpen(false); // Close the Bookmarks popup
    setIsNotesEdit(false);
    setAddNotesValue("");
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { topicId: sessionId },
        message: CourseClient_Messages.GetSingleUserNote,
      });
      console.log("effect RESULT IS", result);
      if (result?.payload?.returnValue?.userNote !== null) {
        setAddNotesValue(result?.payload?.returnValue?.userNote?.note);
        setIsNotesEdit(true);
      }
    } catch (err) {
      // navigate(Pages.Dashboard);
      console.log("Error is", err);
    }
    setAddNotesOpen(!isAddNotesOpen);
  };

  const handleBookmarksClick = async () => {
    setAddNotesOpen(false); // Close the Add Notes popup
    setIsBookmarkView(false);
    setAddBookMarksValue("");
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { topicId: sessionId },
        message: CourseClient_Messages.GetSingleUserBookmark,
      });
      console.log("effect RESULT IS", result);
      if (result?.payload?.returnValue?.userBookmark !== null) {
        setAddBookMarksValue(
          result?.payload?.returnValue?.userBookmark?.bookmarkNote
        );
        setIsBookmarkView(true);
      }
      if (result?.payload?.returnValue?.userBookmark === null) {
        setAddBookMarksValue(addBookMarksValue);
      }
    } catch (err) {
      // navigate(Pages.Dashboard);
      console.log("Error is", err);
    }
    setBookmarksOpen(!isBookmarksOpen);
  };

  const closeAddNote = () => {
    setAddNotesOpen(false);
  };

  const closeBookmark = () => {
    setBookmarksOpen(false);
  };

  useEffect(() => {
    setHidePreviousButton(false);
    const allSessions = (getData(CourseClient_Name) as any).topicList;
    console.log("effect USER TOPICS", allSessions);
    const matchingTopic = allSessions.find(
      (obj: any) => obj.topicId === sessionId
    );
    console.log("effect Matching Topic name", matchingTopic?.topic?.name);
    setAddBookMarksValue(matchingTopic?.topic?.name);
    if (courseData?.state === CourseClient_States.Authenticated) {
      setTopics(courseData?.moduleList);
      // setLoading(false);
    }

    const fetchTopics = async () => {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { courseId: courseId },
          message: CourseClient_Messages.FetchModuleAndSession,
        });
        console.log(
          "RESULT FROM COURSE PLAYER FOR FETCH MODULE SESSION",
          result
        );

        if (!result!.error) {
          console.log("Error is", result?.error);
        }
      } catch (err) {
        console.log("Caught error", err);
        // navigate(Pages.Dashboard);
      }
      setIsTopicsFetched(true);
    };
    fetchTopics();
  }, []);

  useEffect(() => {
    // window.location.reload();
    if (myObject?.topic?.localOrder === 1) {
      setHidePreviousButton(false);
    }
    if (
      myObject?.topic?.name.includes("SESSION 1.1") &&
      myObject?.topic?.localOrder === 1
    ) {
      setHidePreviousButton(true);
    }
    if (myObject === null || undefined) {
      setHidePreviousButton(true);
    }
    if (myObject?.topic?.localOrder > 1) {
      setHidePreviousButton(false);
    }
    const checkFreeTrialAndSubscriptionStatus = async () => {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { sessionId },
        message: CourseClient_Messages.CheckFreeTrialAndSubscription,
      });
      console.log(">>> result after CheckFreeTrialAndSubscription", result);
      setIsSubscriptionChecked(true);
      if (result?.payload?.returnValue?.inactiveSubscription) {
        setShowSubscriptionModal(true);
      }
    };
    if (
      courseClientState === CourseClient_States.Authenticated &&
      !isSubscriptionChecked
    ) {
      checkFreeTrialAndSubscriptionStatus();
    }
  }, [myObject, courseClientState, isSubscriptionChecked]);

  const courseClientListener = useCallback(async (event: ActorEmittedEvent) => {
    let data: CourseClient_Data = event.payload as CourseClient_Data;
    console.log("APP.txt => CourseActorListener", event.payload.state, data);
    setCourseClientState(event.payload.state);
    // setWishActorData(data);
    // setWishActorError(event.payload.error);
  }, []);
  const AuthClientListener = useCallback(async (event: ActorEmittedEvent) => {
    let data: AuthClient_Data = event.payload as AuthClient_Data;
    console.log("APP.txt => AuthClientListener", event.payload.state, data);
    setAuthClientData(data);
    // setWishActorData(data);
    // setWishActorError(event.payload.error);
  }, []);

  useEffect(() => {
    listenTo(CourseClient_Name, courseClientListener);
    listenTo(AuthClient_Name, AuthClientListener);
    return () => {
      stopListeningTo(CourseClient_Name, courseClientListener);
      stopListeningTo(AuthClient_Name, AuthClientListener);
    };
  }, []);
  console.log(">>> Auth client data", authClientData);

  useEffect(() => {
    if (sessionIdValue === "") {
      setSessionIdValue(sessionId ?? "");
      setIsNotesBookmarksFetched(false);
    }
    if (sessionIdValue && sessionIdValue !== sessionId) {
      setSessionIdValue(sessionId ?? "");
      setIsNotesBookmarksFetched(false);
    }
  }, []);

  const fetchNotesBookmarksSecondary = async (sessionId: string) => {
    console.log("outside if 2", sessionId, isNotesBookmarksFetched);
    let result = await ask({
      from: myName,
      to: CourseClient_Name,
      payload: { topicId: sessionId },
      message: CourseClient_Messages.FetchNotesBookmarks,
    });
    console.log("txt NOTES RESULT IS", result?.payload?.returnValue);
    if (result?.payload?.returnValue?.userNote) {
      console.log(
        "txt notes are present",
        result?.payload?.returnValue?.userNote
      );
      setIsNotesPresent(true);
    }
    if (result?.payload?.returnValue?.userBookmark) {
      console.log(
        "txt bookmark are present",
        result?.payload?.returnValue?.userBookmark
      );
      setIsBookmarksPresent(true);
    }
  };
  const fetchNotesBookmarks = async () => {
    console.log("outside if", sessionIdValue, isNotesBookmarksFetched);
    if (
      courseClientState === CourseClient_States.Authenticated &&
      !isNotesBookmarksFetched &&
      isTopicsFetched
    ) {
      console.log(
        "txt is topics fetched",
        isTopicsFetched,
        courseClientState,
        isNotesBookmarksFetched,
        sessionIdValue
      );
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { topicId: sessionId },
        message: CourseClient_Messages.FetchNotesBookmarks,
      });
      console.log("txt NOTES RESULT IS", result?.payload?.returnValue);
      if (result?.payload?.returnValue?.userNote) {
        console.log(
          "txt notes are present",
          result?.payload?.returnValue?.userNote
        );
        setIsNotesPresent(true);
      }
      if (result?.payload?.returnValue?.userBookmark) {
        console.log(
          "txt bookmark are present",
          result?.payload?.returnValue?.userBookmark
        );
        setIsBookmarksPresent(true);
      }
      setIsNotesBookmarksFetched(true);
    }
  };
  useEffect(() => {
    fetchNotesBookmarks();
  }, [courseClientState, isTopicsFetched, sessionIdValue]);

  console.log(
    "SESSION NAME, CID, MID, SID",
    sessionName,
    courseId,
    moduleId,
    sessionId
  );
  console.log(
    "txt notes book",
    isNotesPresent,
    isBookmarksPresent,
    sessionIdValue
  );
  const navigate = useNavigate();

  const [topics, setTopics] = useState([]);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    useState<boolean>(false);

  const courseData = getData(CourseClient_Name) as any;

  const courses = courseData?.courseList;

  const subscriptionConfig = config.actors.find(
    (a: any) => a.name === SubscriptionClient_Name
  )?.config;
  // console.log(">>> Subscription config", subscriptionConfig);

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const submitNote = async () => {
    if (isNotesEdit) {
      closeAddNote();
      console.log("effect edit the note", addNotesValue, sessionId);
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { addNotesValue, sessionId },
          message: CourseClient_Messages.EditNote,
        });
        console.log("Effect Result from Edit note", result);
        if (!result?.error) {
          setIsNotesPresent(true);
        }
      } catch (error) {
        console.log("Error is", error);
      }
    } else {
      closeAddNote();
      console.log("effect note submit", addNotesValue);
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { addNotesValue, courseId, sessionId },
          message: CourseClient_Messages.AddNote,
        });
        console.log("Effect Result from submit note", result);
        if (!result?.error) {
          setIsNotesPresent(true);
        }
      } catch (error) {
        console.log("Error is", error);
      }
    }
  };

  const handleDeleteNote = async () => {
    closeAddNote();
    console.log("Effect Delete clicked", sessionId);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { sessionId },
        message: CourseClient_Messages.DeleteNote,
      });
      console.log("Effect Result from delete note", result);
      if (!result?.error) {
        setIsNotesPresent(false);
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const handleDeleteBookmark = async () => {
    closeBookmark();
    console.log("Effect Delete clicked", sessionId);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { sessionId },
        message: CourseClient_Messages.DeleteBookmark,
      });
      console.log("Effect Result from delete bookmark", result);
      if (!result?.error) {
        setIsBookmarksPresent(false);
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const submitBookMark = async () => {
    closeBookmark();
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { addBookMarksValue, courseId, sessionId },
        message: CourseClient_Messages.AddBookmark,
      });
      console.log("Effect Result from submit note", result);
      if (!result?.error) {
        setIsBookmarksPresent(true);
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const updateProgress = async (
    courseId: string,
    moduleId: string,
    sessionId: string
  ) => {
    console.log("SESSION ID", sessionId);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { courseId, moduleId, sessionId },
        message: CourseClient_Messages.UpdateUserProgress,
      });
      console.log("RESULT AFTER UPDATE USER PROGRESS", result?.payload);
      console.log("ONLYYYYYYYYYYYY RESULT AFTER UPDATE USER PROGRESS", result);
      const returnObject = result?.payload;
      console.log(">>> RETURN VALUE AFTER UPDATE", returnObject);

      if (
        returnObject?.returnValue?.updatedTopic &&
        returnObject?.returnValue?.quiz === false &&
        returnObject?.returnValue?.assessment === false
      ) {
        console.log("NAVIGATE TO NEXT TOPIC");
        setIsNotesPresent(false);
        setIsBookmarksPresent(false);
        fetchNotesBookmarksSecondary(
          returnObject?.returnValue?.updatedTopic?.topicId
        );
        navigate(
          `${Pages.Player}/${courseId}/${moduleId}/${returnObject?.returnValue?.updatedTopic?.topicId}/${returnObject?.returnValue?.updatedTopic?.topicId}`,
          { state: returnObject?.returnValue?.updatedTopic }
        );
        // setSessionIdValue(returnObject?.returnValue?.updatedTopic?.topicId);
        // setIsNotesBookmarksFetched(false);
      }
      if (
        returnObject?.returnValue?.updatedTopic &&
        returnObject?.returnValue?.quiz === true &&
        returnObject?.returnValue?.assessment === false
      ) {
        console.log("NAVIGATE TO QUIZ");
        // navigate(Pages.QuizPlayer);
        navigate(
          `${Pages.QuizPlayer}/${courseId}/${moduleId}/${returnObject?.returnValue?.updatedTopic?.topicId}/${returnObject?.returnValue?.updatedTopic?.topicId}`,
          { state: returnObject?.returnValue?.updatedTopic }
        );
      }
      if (
        returnObject?.returnValue?.updatedTopic === false &&
        returnObject?.returnValue?.quiz === false &&
        returnObject?.returnValue?.assessment === true
      ) {
        console.log("NAVIGATE TO ASSESSMENT");
        navigate(`${Pages.Assessment}/${courseId}`);
      }
      if (
        returnObject?.returnValue?.updatedTopic &&
        // returnObject?.returnValue?.quiz === false &&
        // returnObject?.returnValue?.assessment === false &&
        returnObject?.returnValue?.module
      ) {
        console.log("Effect Navigate to next module");
        navigate(
          `${Pages.CourseLanding}/${returnObject?.returnValue?.updatedTopic?.courseId}?currentModuleId=${returnObject?.returnValue?.updatedTopic?.id}`
        );
      }
      if (returnObject?.returnValue?.inactiveSubscription) {
        setShowSubscriptionModal(true);
      }
    } catch (error) {
      console.log("ERROR IS", error);
    }
  };

  const navigateToPreviousSession = async (sessionId: string | undefined) => {
    console.log("Effect SESSION ID", sessionId);
    // FETCHING THE PREVIOUS SESSION FROM THE BACKEND
    // AND NAVIGATING TO THE SESSION IN THE PLAYER
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { sessionId },
        message: CourseClient_Messages.GetPreviousSession,
      });
      console.log("RESULT FROM NAVIGATE TO PREVIOUS SESSION", result);
      if (
        result?.payload?.returnValue?.navigation &&
        result?.payload?.returnValue?.courseLanding === true
      ) {
        const navigationModule = result?.payload?.returnValue?.navigation;
        console.log("Result of navigation module", navigationModule);
        navigate(`${Pages.CourseLanding}/${courseId}`, {
          state: navigationModule,
        });
      } else if (
        result?.payload?.returnValue?.navigation &&
        result?.payload?.returnValue?.courseLanding === false
      ) {
        const navigationSession = result?.payload?.returnValue?.navigation;
        setIsNotesPresent(false);
        setIsBookmarksPresent(false);
        fetchNotesBookmarksSecondary(navigationSession?.topic?.id);
        navigate(
          `${Pages.Player}/${navigationSession?.topic?.courseId}/${navigationSession?.topic?.parentTopicId}/${navigationSession?.topic?.id}/${navigationSession?.topic?.name}`,
          { state: navigationSession }
        );
      }
    } catch (error) {
      console.log("ERROR IS", error);
    }
  };

  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  console.log("COURSE DATA", courseData);

  // const topics = courseData?.moduleList;

  const navigateToQuiz = async (
    course: string,
    module: string,
    session: string,
    item: any
  ) => {
    console.log("COURSE, MODULE, SESSION", course, module, session);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { course, module, session, item },
        message: CourseClient_Messages.NavigateToQuiz,
      });
      console.log("RESULT FROM NAVIGATE QUIZ", result);
      navigate(
        `${Pages.QuizPlayer}/${course}/${module}/${session}/${session}`,
        {
          state: item,
        }
      );
    } catch (error) {
      console.log("Caught error", error);
    }
  };

  const activateSubmenu = (topicId: any) => {
    if (!showSubMenu) {
      console.log("TOPIC ACTIVATION VALUE", topicId);
      setActiveSubMenu(topicId);
      const sessions: Topic[] = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      const sortedSessions = sessions.slice().sort((a, b) => {
        const orderA = a?.topic?.localOrder || 0;
        const orderB = b?.topic?.localOrder || 0;
        return orderA - orderB; // For ascending order
      });
      sortedSessions && setSubMenuItems(sortedSessions);
      setShowSubMenu(true);
    } else if (subMenuItems && showSubMenu) {
      setActiveSubMenu(null);
      setShowSubMenu(false);
      const sessions: Topic[] = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      if (
        sessions.every((element: any) =>
          subMenuItems.includes(element as never)
        )
      ) {
        setShowSubMenu(false);
      } else {
        const sortedSessions = sessions.slice().sort((a, b) => {
          const orderA = a?.topic?.localOrder || 0;
          const orderB = b?.topic?.localOrder || 0;
          return orderA - orderB; // For ascending order
        });
        sortedSessions && setSubMenuItems(sortedSessions);
        setShowSubMenu(true);
        setActiveSubMenu(topicId);
      }
    } else {
      setShowSubMenu(false);
      setActiveSubMenu(null);
    }
  };
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, []);

  return (
    <>
      {/*  */}
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-12 p-0 mytogclass ${
              isSidebarOpen ? "opened" : ""
            }`}
          >
            <div className="toggleitem">
              <div className="custombar" onClick={toggleSidebar}>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0 mymainparthtml">
            {showSubscriptionModal && (
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div id="exampleModal">
                  <div className="modal-dialog modal-dialog-centered my-modal">
                    <div className="modal-content">
                      <div className="modal-body p-0">
                        <div className="mainborder">
                          <div className="headeramount">
                            {/* <button
                              type="button"
                              className="btn-close"
                              // data-bs-dismiss="modal"
                              // aria-label="Close"
                              // onClick={() => setShowSubscriptionModal(false)}
                            ></button> */}
                            <h4>
                              Your free trial pack has expired Please Upgrade
                              subscription.
                            </h4>
                          </div>
                          <div
                            className="contentamountbody"
                            style={{ backgroundColor: "white" }}
                          >
                            <h6>
                              {authClientData?.user?.role ===
                              UserRole.IndividualUser
                                ? `$${subscriptionConfig?.individualSubscriptionCharge}`
                                : `$${subscriptionConfig?.corporateSubscriptionCharge}`}
                            </h6>
                            <ul>
                              <li>All module access</li>
                              <li>Test Knowledge (Quiz)</li>
                              <li>Certificate for course completion</li>
                            </ul>
                            <div
                              className="text-center contibtn"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="goldenbtn whitetext"
                                style={{
                                  marginLeft: "50px",
                                  paddingLeft: "25px",
                                  width: "100%",
                                }}
                                onClick={() =>
                                  subscriptionNavigation(
                                    courses[0]?.course,
                                    authClientData?.user?.role ===
                                      UserRole.IndividualUser
                                      ? SubscriptionType.Individual
                                      : SubscriptionType.Company
                                  )
                                }
                              >
                                continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- leftmenu Blue Part --> */}
            <CoursePlayerSidebar
              sessionId={sessionId}
              courseId={courseId}
              currentModule={moduleId}
              isSidebarOpen={isSidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <div className="mainbody">
              <div className="">
                {/* <!-- Start sticky icon --> */}
                {/* <NotesBookmarksButton
                  sessionId={sessionId}
                  courseId={courseId}
                /> */}
                <div className="stikeybutton">
                  <div className="sitckyinner">
                    <div>
                      <button
                        id="Addnote"
                        className={
                          isNotesPresent ? "addbtn2" : "stikeybuttons addbtn"
                        }
                        // className="addbtn2"
                        // className="stikeybuttons addbtn"
                        onClick={handleAddNotesClick}
                      >
                        <img src={isNotesPresent ? addNoteDark : addNote} />
                        Add notes
                      </button>
                    </div>
                    <div>
                      <button
                        className={
                          isBookmarksPresent
                            ? "addbtn2"
                            : "stikeybuttons bookbtn"
                        }
                        // className="stikeybuttons bookbtn"
                        id="Bookmark"
                        onClick={handleBookmarksClick}
                      >
                        <img
                          src={isBookmarksPresent ? bookMarkDark : bookMark}
                        />
                        BOOKMARK
                      </button>
                    </div>
                  </div>
                </div>
                {isAddNotesOpen && (
                  <div
                    className="addnotepopup"
                    id="addmain"
                    style={{ display: "block" }}
                  >
                    <div
                      className="popupinner"
                      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                    >
                      <div className="headerpopup">
                        <h4>TAKE NOTES</h4>
                        <button id="closeidadd" onClick={closeAddNote}>
                          X
                        </button>
                      </div>
                      <div className="popupbody">
                        <div className="textbody">
                          <textarea
                            className="input"
                            rows={7}
                            placeholder="Enter your notes here..."
                            value={addNotesValue}
                            onChange={(e) => setAddNotesValue(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="text-button mt-2 text-center">
                          <div className="notes">
                            <button
                              className="goldenbtn whitetext mb-2"
                              onClick={() => submitNote()}
                              style={{ marginRight: "8px" }}
                            >
                              {isNotesEdit ? "EDIT NOTE" : "ADD NOTE"}
                            </button>
                            {isNotesEdit && (
                              <button
                                className="goldenbtn whitetext"
                                onClick={() => handleDeleteNote()}
                              >
                                DELETE NOTE
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isBookmarksOpen && (
                  <div
                    className="addnotepopup"
                    id="bookmain"
                    style={{ display: "block", top: "12.5rem" }}
                  >
                    <div
                      className="popupinner"
                      style={{
                        height: "200px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <div className="headerpopup">
                        <h4>BOOKMARK</h4>
                        <button id="closeidbook" onClick={closeBookmark}>
                          X
                        </button>
                      </div>
                      <div className="popupbody">
                        <h4>{addBookMarksValue}</h4>
                        <div className="bookmarkBtn">
                          {isBookmarkView ? (
                            <button
                              className="goldenbtn whitetext"
                              onClick={() => handleDeleteBookmark()}
                            >
                              REMOVE
                            </button>
                          ) : (
                            <button
                              className="goldenbtn whitetext"
                              onClick={() => submitBookMark()}
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <!--End  sticky icon --> */}
                <div className="contentpart" style={{ padding: 0, margin: 0 }}>
                  {/* <div className="contentpart"> */}
                  <iframe
                    src={`https://afmdevbucket.s3.ap-south-1.amazonaws.com/${courseId}/${moduleId}/${sessionId}/index.html`}
                    width="100%"
                    height="100%"
                    title="External Content"
                    allowFullScreen
                  />
                  {/* <div className="col-md-12 mt-5"> */}
                  <div
                    className="col-md-12 buttondiv"
                    style={{
                      // marginTop: "-95px",
                      // marginTop: "-4%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      className="d-flex mobilebtn justify-content-between"
                      // style={{ marginTop: "-4%" }}
                    >
                      {!hidePreviousButton && sessionId ? (
                        <button
                          className="bluebtn whitetext cunsumflex"
                          // style={{ marginRight: "20px" }}
                          onClick={() => navigateToPreviousSession(sessionId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="11"
                            viewBox="0 0 5 11"
                            fill="none"
                          >
                            <path
                              d="M4.86648 0.151862C4.79117 0.0659041 4.69189 0.0128926 4.58592 0.00206323C4.47995 -0.00876615 4.37402 0.0232733 4.28658 0.0925993L4.22296 0.151863L0.132759 4.83056C0.0576132 4.91671 0.01127 5.03028 0.00180279 5.15149C-0.00766444 5.27271 0.0203442 5.39388 0.0809502 5.4939L0.13276 5.56668L4.22296 10.2454C4.30312 10.3375 4.41053 10.3919 4.52402 10.3979C4.63751 10.4039 4.74886 10.3611 4.83611 10.2778C4.92336 10.1946 4.9802 10.077 4.99541 9.94821C5.01062 9.81942 4.98311 9.68878 4.91829 9.58204L4.86649 9.50926L1.09714 5.19862L4.86648 0.887978C4.90881 0.839688 4.94239 0.782321 4.9653 0.719164C4.98821 0.656006 5 0.588299 5 0.51992C5 0.451541 4.98821 0.383834 4.9653 0.320677C4.94239 0.257519 4.90881 0.200153 4.86648 0.151862Z"
                              fill="white"
                            />
                          </svg>
                          <span>previous Session</span>
                        </button>
                      ) : (
                        <div></div>
                      )}
                      {/* <button className="goldenbtn whitetext">
                        Mark complete
                      </button> */}
                      {sessionId && moduleId && courseId && (
                        <button
                          className="bluebtn whitetext cunsumflex"
                          // style={{ marginRight: "20px" }}
                          onClick={() =>
                            updateProgress(courseId, moduleId, sessionId)
                          }
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                        >
                          <span>Next Session</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="11"
                            viewBox="0 0 5 11"
                            fill={isHover ? `black` : "white"}
                          >
                            <path
                              d="M0.133516 0.151862C0.208827 0.0659041 0.308114 0.0128926 0.414081 0.00206323C0.520048 -0.00876615 0.625977 0.0232733 0.713416 0.0925993L0.777041 0.151863L4.86724 4.83056C4.94239 4.91671 4.98873 5.03028 4.9982 5.15149C5.00766 5.27271 4.97966 5.39388 4.91905 5.4939L4.86724 5.56668L0.77704 10.2454C0.696882 10.3375 0.589473 10.3919 0.47598 10.3979C0.362487 10.4039 0.251142 10.3611 0.163889 10.2778C0.0766357 10.1946 0.0198031 10.077 0.00459121 9.94821C-0.0106207 9.81942 0.0168916 9.68878 0.0817059 9.58204L0.133515 9.50926L3.90286 5.19862L0.133516 0.887978C0.0911934 0.839688 0.0576147 0.782321 0.0347037 0.719164C0.0117927 0.656006 -9.89199e-08 0.588299 -8.74223e-08 0.51992C-7.59247e-08 0.451541 0.0117928 0.383834 0.0347038 0.320677C0.0576148 0.257519 0.0911935 0.200153 0.133516 0.151862Z"
                              fill={isHover ? `black` : "white"}
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer part --> */}
      <div className="col-md-12 p-0">
        <footer>
          <div className="footertext">
            <div>
              <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
            </div>
            <div className="quicklinks">
              <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default DummyCoursePlayer;
