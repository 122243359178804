import React from "react";
import logo from "../dummy/images/logo.svg";
import usericon from "../dummy/images/usericon.svg";
import whiteusericon from "../dummy/images/whiteusericon.svg";
import camera from "../dummy/images/camera.svg";
import loginicon from "../dummy/images/loginicon.png";
import "./CompanyAdminSidebar.css";
// import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Group318 from "../dummy/images/Group 318.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import Group319 from "../dummy/images/Group 319.png";
import Group164 from "../dummy/images/Group 164.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group325 from "../dummy/images/Group 325 (1).png";
import avilogo from "../dummy/images/logo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import CompanyUserSVG from "./CompanyUsers";

const CompanyAdminSidebar = ({
  isSidebarVisible,
  setIsSidebarVisible,
  setCompanyPage,
  navigateFunction,
  companyPage,
}: any) => {
  const navigate = useNavigate();
  console.log("outside company page", companyPage);
  return (
    <div className={`newSidebar ${isSidebarVisible ? "" : "hidden"}`}>
      <div
        style={{
          color: "#fff",
          display: isSidebarVisible ? "block" : "none",
          position: "absolute",
          top: "10px",
          right: "50px",
        }}
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        X
      </div>
      <div className="innersider">
        <img className="avilogo" src={avilogo} alt="Logo" />

        <div className="quickmenulinks">
          <ul>
            <li>
              <a
                href="#"
                onClick={() => navigate(Pages.CompanyInfo)}
                style={{
                  color:
                    companyPage === Pages.CompanyInfo ? "#C7B277" : "white",
                }}
              >
                <img
                  src={Group318}
                  alt="manageIcon"
                  className="sidebar_images"
                  id="hello"
                />
                <span className="menu-link-text">COMPANY INFO</span>
              </a>
            </li>
            <li
              onClick={() => {
                // navigateFunction(Pages.DownloadSamplebox);
                navigate(Pages.DownloadSamplebox);
                console.log("page ppp");
              }}
            >
              <a
                href="#"
                style={{
                  color:
                    companyPage === Pages.DownloadSamplebox
                      ? "#C7B277"
                      : "white",
                }}
              >
                <CompanyUserSVG />
                {/* <img
                  src={Group319}
                  alt="manageIcon"
                  className="sidebar_images"
                /> */}
                <span className="menu-link-text">MANAGE COMPANY USERS</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => navigate(Pages.UserdasBoard)}
                style={{
                  color:
                    companyPage === Pages.UserdasBoard ? "#C7B277" : "white",
                }}
              >
                <img src={Group320} alt="report" className="sidebar_images" />
                <span className="menu-link-text">
                  COMPANY COMPLIANCES REPORT
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => navigate(Pages.UserdasDetail)}
                style={{
                  color:
                    companyPage === Pages.UserdasDetail ? "#C7B277" : "white",
                }}
              >
                <img src={Group320} alt="status" className="sidebar_images" />
                <span className="menu-link-text">SUBSCRIPTION STATUS</span>
              </a>
            </li>
            {/* <li>
              <a
                href="#"
                // onClick={() => navigate(Pages.Bookmarks)}
              >
                <img src={Group321} alt="status" className="sidebar_images" />
                <span className="menu-link-text">SUPPORT</span>
              </a>
            </li> */}
            <button id="go_to_btn" onClick={() => navigate("/")}>
              GO TO COURSE
            </button>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyAdminSidebar;
