import React, { useState, useEffect } from "react";
import logo from "./images/logo.svg";
import usericon from "./images/usericon.svg";
import whiteusericon from "./images/whiteusericon.svg";
import camera from "./images/camera.svg";
import loginicon from "./images/loginicon.png";
import "./UpdateCompanyName.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { BorderRadius } from "tabler-icons-react";
import Group318 from "../dummy/images/Group 318.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import Group319 from "../dummy/images/Group 319.png";
import Group164 from "../dummy/images/Group 164.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group325 from "../dummy/images/Group 325 (1).png";
import { Pages } from "../../Pages";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const UpdateCompanyName = () => {
  const [isCollapseOneOpen, setIsCollapseOneOpen] = useState(true);
  const [isCollapseTwoOpen, setIsCollapseTwoOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  const toggleCollapseOne = () => {
    setIsCollapseOneOpen(!isCollapseOneOpen);
    setIsCollapseTwoOpen(false);
  };

  const toggleCollapseTwo = () => {
    setIsCollapseTwoOpen(!isCollapseTwoOpen);
    setIsCollapseOneOpen(false);
  };
  const updateCompanyInfo = () => {
    console.log("clicked");
    const companyNameMaxLength = 100;
    const companyInfoMaxLength = 500;
    if (companyName.length <= 0 && companyInfo.length <= 0) {
      swal({
        text: `Please add company name and company Info `,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else if (companyName.length <= 0) {
      swal({
        text: `Please enter company name.`,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else if (companyInfo.length <= 0) {
      swal({
        text: `Please enter company info.`,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else if (
      companyName.length > companyNameMaxLength &&
      companyInfo.length > companyInfoMaxLength
    ) {
      swal({
        text: `Company name cannot exceed ${companyNameMaxLength} characters and Company info cannot exceed ${companyInfoMaxLength} characters.`,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else if (companyName.length > companyNameMaxLength) {
      swal({
        text: `Company name cannot exceed ${companyNameMaxLength} characters.`,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else if (companyInfo.length > companyInfoMaxLength) {
      swal({
        text: `Company info cannot exceed ${companyInfoMaxLength} characters.`,
        buttons: {
          Done: {
            text: "Ok",
            className: "done-button",
          },
        },
      });
      return;
    } else {
      console.log("save data");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row"></div>
        <div className="col-md-12 p-0 mymainparthtml">
          <div className="mainbody">
            <div className="">
              <div className="contentpart">
                <div className="row">
                  <div className="col-md-12">
                    <div className="customaccordian">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={`accordion-button ${
                                isCollapseOneOpen ? "" : "collapsed"
                              }`}
                              type="button"
                              onClick={toggleCollapseOne}
                              aria-expanded={
                                isCollapseOneOpen ? "true" : "false"
                              }
                              aria-controls="collapseOne"
                            >
                              UPDATE COMPANYNAME
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className={`accordion-collapse collapse ${
                              isCollapseOneOpen ? "show" : ""
                            }`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="accordion-body">
                                <div className="row">
                                  <div>
                                    <div className="custompassword">
                                      <input
                                        className="form-control mt-0 companyName"
                                        type="text"
                                        placeholder="Company Name"
                                        value={companyName}
                                        onChange={(e) =>
                                          setCompanyName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={`accordion-button ${
                                isCollapseTwoOpen ? "" : "collapsed"
                              }`}
                              type="button"
                              onClick={toggleCollapseTwo}
                              aria-expanded={
                                isCollapseTwoOpen ? "true" : "false"
                              }
                              aria-controls="collapseTwo"
                            >
                              UPDATE COMPANY INFO
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className={`accordion-collapse collapse ${
                              isCollapseTwoOpen ? "show" : ""
                            }`}
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="accordion-body">
                                <div className="row">
                                  <div>
                                    <div className="custompassword">
                                      <textarea
                                        className="form-control mt-0 companyInfo"
                                        rows={7}
                                        placeholder="Company Info"
                                        value={companyInfo}
                                        onChange={(e) =>
                                          setCompanyInfo(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div
                            style={{ cursor: "pointer" }}
                            className="subumit-btn"
                            id="update_company_info"
                            onClick={updateCompanyInfo}
                          >
                            SUBMIT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-12 p-0">
              <footer>
                <div className="footertext">
                  <div>
                    <p>
                      © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                    </p>
                  </div>
                  <div className="quicklinks">
                    <a href="#">PRIVACY</a>
                    <a href="#">TERMS</a>
                  </div>
                </div>
              </footer>
            </div> */}
    </>
  );
};

export default UpdateCompanyName;
