//---------------------------------------------------------------------------------
//
// company-admin/types.ts
//
//---------------------------------------------------------------------------------

import { type } from "os";

export const AuthClient_Name = "auth-client";
export const AuthService_Name = "auth-service";

export const CourseClient_Name = "course-client";
export const CourseService_Name = "course-service";
export const CompanyAdminClient_Name = "company-admin-client";
export const CompanyAdminService_Name = "company-admin-service";

//---------------------------------------------------------------------------------

export type Course = {
  course: any;
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
};

export type Topic = {
  id: string;
  name: string;
  courseId: string;
  parentTopicId?: string;
  previousTopicId?: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  // NEW
  localOrder: number;
  sessionNumber: string;
  topic: any;
  status: string;
};

export type CoursesReturn = {
  courseList: Array<Course>;
};

export interface CourseClient_Data {
  courseList: Array<Course>;
  topicList: Array<Topic>;
  moduleList: Array<Topic>;
}

//---------------------------------------------------------------------------------

export enum AuthClient_States {
  Start = "start",
  // Not Authenticated States
  Validating = "validating",
  ValidateError = "validate-error",
  NotAuthenticated = "not-authenticated",
  LoggingIn = "logging-in",
  LoginError = "login-error",
  ValidateEmail = "validate-email",
  ValidatingEmail = "validating-email",
  ValidatingEmailError = "validating-email-error",
  ResettingPassword = "resetting-password",
  ResettingPasswordError = "resetting-password-error",
  RequestingPasswordReset = "requesting-password-reset",
  RequestingPasswordResetError = "requesting-password-reset-error",
  Registering = "registering",
  RegisterError = "register-error",
  CountryList = "get-country-list",
  CountryListError = "country-list-error",

  // Authenticated States
  Authenticated = "authenticated",
  LoggingOut = "logging-out",
  LogoutError = "logout-error",
  // TODO: Add change Profile.tsx accordingly
}

export enum CompanyAdminClient_States {
  Start = "start",
  // Not Authenticated States
  NotAuthenticated = "not-authenticated",
  // Authenticated States
  Authenticated = "authenticated",
  // Work in progress
  WIP = "wip",
  // Error state
  Err = "error",
}

//---------------------------------------------------------------------------------

export enum CompanyAdminClient_Messages {
  TestMessage = "test-message",
  AddUpdateCompanyInfo = "add-update-company-info",
  CertificateDownload = "certificate-download",
  CorporateUser = "corporate-user",
  ExcellFileUpload = "excell-file-upload",
  FetchData = "fetch-data",
  UserListDownloadExcell = "user-list-download-excell",
  CompanyInfoGet = "company-info-get",
  GetMyCompanyUsers = "get-my-company-users",
  DownloadSampleExcel = "download-sample-excel",
  SubscriptionStatus = "subscription-status",
}

//---------------------------------------------------------------------------------
