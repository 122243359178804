import { useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { ask } from "lib-js-c-modulo/index.mjs";

const myName = "Dashboard-gui";

const DummyTryAgain = () => {
  const navigate = useNavigate();
  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  return (
    <>
      <button type="button" onClick={doLogout}>
        Logout(OG)
      </button>
      {/* <div>
        <h1>OOPS! DO YOU WANT TO TRY AGAIN?</h1>
        <div>
          <button onClick={() => navigate(Pages.Assessment)}>YES</button>
        </div>
      </div> */}
      <div>
        <div className="box">
          <div className="course-pass-page-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <h1>SORRY!</h1>
              <br />
              <div>
                <h4 style={{ marginTop: "10px" }}>
                  You have not cleared the assessment
                </h4>
              </div>
              <div>
                <h4>Please reattempt the assessment</h4>
              </div>
              <div style={{ marginTop: "30px" }}>
                <button style={{ padding: "10px" }}>TRY AGAIN</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyTryAgain;
