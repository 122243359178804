import React from "react";
import "./NewForgotPassword.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoafmcart from "../../assets/logoafmcart.png";
import homeicon from "../../assets/homeicon.svg";
import imageOne from "../../assets/clarity_eye-show-solid.svg";

const NewForgotPassword = () => {
  return (
    <div className="NewForgotPassword">
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logoafmcart} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a>
                <img src={homeicon} alt="Home Icon" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="titleofform">
                <h3>Forgot Password</h3>
              </div>
              <div className="otpmain">
                <form className="createform">
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between otpcontent">
                    <p>
                      Enter the OTP sent to you on your register email address.
                    </p>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-center">
                    <a className="sign-btn">Send OTP</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewForgotPassword;
