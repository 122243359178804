export enum Pages {
  Others = "*",
  Home = "/home",
  Login = "/login",
  Register = "/register",
  ThankYou = "/thankYou",
  ThankYousetPass = "/setpass",
  Dashboard = "/dashboard",
  Player = "/player",
  QuizPlayer = "/quiz-player",
  TryAgain = "/try-again",
  Certificate = "/certificate",
  Assessment = "/assessment",
  ValidateEmail = "/validate-email",
  TermsAndConditions = "/terms&conditions",
  PrivacyPolicy = "/privacy-policy",
  RequestResetPassword = "/request-reset-password",
  ResetPassword = "/resetPassword",
  CourseLanding = "/course-landing",
  Landing = "/landing",
  Congratulations = "/congratulations",
  AssessmentTryAgain = "/try-again-page",
  Notes = "/notes",
  Bookmarks = "/bookmarks",
  CertificateList = "/certificate-list",
  ShoppingCart = "/shopping-cart",
  PaymentGateway = "/payment-gateway",
  PaymentFailed = "/payment-failed",
  PaymentSuccess = "/payment-success",
  Profile = "/profile",
  CompanyDashboard = "/company-dashboard",
  EditInfo = "/edit-info",
  AddCompanyUsers = "/add-company-users",
  CompanyUsersReport = "/company-users-report",
  PaymentSuccessStatic = "/payment-success-static",
  StripePaymentGateway = "/stripe-payment-gateway",
  CompanyInfo = "/companyinfo",
  Important = "/imp",
  DownloadSamplebox = "/downloadsamplebox",
  UpdateCompanyName = "/updatecompanyname",
  UserdasBoard = "/userdasboard",
  UserdasDetail = "/userdasdetail",
  // UserdetailsSubsstatus = "userstatus",
  NavbarCompanydasBoard = "testmee",
  CompanyAdminSidebar = "mango",
  Forms = "form",
  // OnboradSign = "newsignin",
  ScreenOtp = "screenotp", //used for testing purpose , already working in the prod
  NewForgotPassword = "newforgotpas",
  SetNewPassword = "setnewpass",
  // CoursePlayerSidebar = "abdul",
  // Hello = "hello",
  // CompanyCommon = "common",
}
