import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserdasDetail.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
} from "../../actors/company-admin/types";
const UserdasDetails = () => {
  const navigate = useNavigate();
  const myName = "company-subscription-details";
  const [userData, setUserData] = useState<any>([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [paymentDetails, setPaymentDetails] = useState<any>([]);
  useEffect(() => {
    const doIt = async () => {
      let result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log(">>>outside download report list coming ", result);
      setUserData(result?.payload?.returnValue?.allUser);
      setSubscriptionDetails(
        result?.payload?.returnValue?.existingSubscription
      );
      setPaymentDetails(
        result?.payload?.returnValue?.verifiedPaymentTransactions
      );
    };
    doIt();
  }, []);
  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const subscriptionNavigation = async (
    course: any,
    subscriptionType: SubscriptionType
  ) => {
    console.log(
      "Effect course is and subscription type",
      course,
      subscriptionType
    );
    navigate(`${Pages.ShoppingCart}/${subscriptionType}`, { state: course });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0 col-md-3  mymainparthtml">
            <div className="mainbody">
              <div className="">
                <div className="contentpart">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="offset-md-0">
                        <div className="containerhead">
                          <div className="row custom-row">
                            <div className="col">
                              <h4 className="heading custom-heading">STATUS</h4>
                              <div className="container-box">
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mixing">
                                        <h4>Total Registered Users:</h4>
                                        <h5>{userData?.length}</h5>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mixing">
                                        <h4>Certified Users:</h4>
                                        <h5>
                                          {
                                            userData.filter(
                                              (user: any) =>
                                                user.certificates.length > 0
                                            ).length
                                          }
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mt-5 py-5">
                              <div className="col-md-6 mb-4">
                                <div className="mixing">
                                  <h1>SUBSCRIPTION STATUS</h1>
                                </div>

                                <div>
                                  <div className="divRow">
                                    <div className="col">
                                      <div className="table-responsive hello">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th>Subsribed on</th>
                                              <th className="multi-line-header">
                                                Total Numbers
                                                <br />
                                                of Users
                                              </th>
                                              <th className="single-line-header">
                                                Expiry Date
                                              </th>
                                              <th className="multi-line-header">
                                                Subscription
                                                <br />
                                                Status
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                {(subscriptionDetails as any)
                                                  ?.createdAt
                                                  ? new Date(
                                                      (
                                                        subscriptionDetails as any
                                                      )?.createdAt
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                  : "Not Subscribed"}
                                              </td>
                                              <td>{userData?.length}</td>
                                              <td>
                                                {(subscriptionDetails as any)
                                                  ?.toDate
                                                  ? new Date(
                                                      (
                                                        subscriptionDetails as any
                                                      )?.toDate
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                  : "Not Subscribed"}
                                              </td>
                                              <td>
                                                {
                                                  (subscriptionDetails as any)
                                                    ?.state
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {paymentDetails?.length && (
                                <div className="col-md-6 mb-4">
                                  <div className="mixing">
                                    <h1>PAYMENT DETAILS</h1>
                                  </div>

                                  <div>
                                    <div className="divRow">
                                      <div className="col">
                                        <div className="table-responsive hello">
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Order ID</th>
                                                <th className="single-line-header">
                                                  Order Status
                                                </th>
                                                <th className="single-line-header">
                                                  Subscription ID
                                                </th>
                                                {/* <th className="single-line-header">
                                                Team Number
                                              </th> */}
                                                <th className="single-line-header">
                                                  Amount Paid
                                                </th>
                                                <th>Subscription Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {paymentDetails?.map(
                                                (detail: any) => (
                                                  <tr>
                                                    <td>
                                                      {detail?.id.split("-")[1]}
                                                    </td>
                                                    <td>
                                                      {
                                                        detail?.verificationStatus
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        detail?.subscriptionId.split(
                                                          "-"
                                                        )[1]
                                                      }
                                                    </td>
                                                    {/* <td>Chris</td> */}
                                                    <td>{detail?.amount}</td>
                                                    <td>
                                                      {
                                                        detail?.subscription
                                                          ?.state
                                                      }
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <button
                                onClick={() =>
                                  subscriptionNavigation(
                                    (subscriptionDetails as any)?.courseId,
                                    SubscriptionType.Company
                                  )
                                }
                                className="renewbtn"
                              >
                                RENEW SUBSCRIPTION
                              </button>
                            </div>
                            {/* END OF  BOX*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 mt-5">
                    <div
                      className="d-flex mobilebtn justify-content-between"
                    >
                      <button className="bluebtn whitetext cunsumflex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="11"
                          viewBox="0 0 5 11"
                          fill="none"
                        >
                          <path
                            d="M4.86648 0.151862C4.79117 0.0659041 4.69189 0.0128926 4.58592 0.00206323C4.47995 -0.00876615 4.37402 0.0232733 4.28658 0.0925993L4.22296 0.151863L0.132759 4.83056C0.0576132 4.91671 0.01127 5.03028 0.00180279 5.15149C-0.00766444 5.27271 0.0203442 5.39388 0.0809502 5.4939L0.13276 5.56668L4.22296 10.2454C4.30312 10.3375 4.41053 10.3919 4.52402 10.3979C4.63751 10.4039 4.74886 10.3611 4.83611 10.2778C4.92336 10.1946 4.9802 10.077 4.99541 9.94821C5.01062 9.81942 4.98311 9.68878 4.91829 9.58204L4.86649 9.50926L1.09714 5.19862L4.86648 0.887978C4.90881 0.839688 4.94239 0.782321 4.9653 0.719164C4.98821 0.656006 5 0.588299 5 0.51992C5 0.451541 4.98821 0.383834 4.9653 0.320677C4.94239 0.257519 4.90881 0.200153 4.86648 0.151862Z"
                            fill="white"
                          />
                        </svg>
                        <span>PREVIOUS</span>
                      </button>

                      <button className="bluebtn whitetext cunsumflex">
                        <span>NEXT</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="11"
                          viewBox="0 0 5 11"
                          fill="none"
                        >
                          <path
                            d="M0.133516 0.151862C0.208827 0.0659041 0.308114 0.0128926 0.414081 0.00206323C0.520048 -0.00876615 0.625977 0.0232733 0.713416 0.0925993L0.777041 0.151863L4.86724 4.83056C4.94239 4.91671 4.98873 5.03028 4.9982 5.15149C5.00766 5.27271 4.97966 5.39388 4.91905 5.4939L4.86724 5.56668L0.77704 10.2454C0.696882 10.3375 0.589473 10.3919 0.47598 10.3979C0.362487 10.4039 0.251142 10.3611 0.163889 10.2778C0.0766357 10.1946 0.0198031 10.077 0.00459121 9.94821C-0.0106207 9.81942 0.0168916 9.68878 0.0817059 9.58204L0.133515 9.50926L3.90286 5.19862L0.133516 0.887978C0.0911934 0.839688 0.0576147 0.782321 0.0347037 0.719164C0.0117927 0.656006 -9.89199e-08 0.588299 -8.74223e-08 0.51992C-7.59247e-08 0.451541 0.0117928 0.383834 0.0347038 0.320677C0.0576148 0.257519 0.0911935 0.200153 0.133516 0.151862Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserdasDetails;
