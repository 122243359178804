import React, { useEffect, useState } from "react";
import logo from "./images/logo.svg";
import usericon from "./images/usericon.svg";
import whiteusericon from "./images/whiteusericon.svg";
import camera from "./images/camera.svg";
import loginicon from "./images/loginicon.png";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./style.css";
import "./StripeStyle.css";
// import "./DownloadSamplebox.css";
import Group318 from "../dummy/images/Group 318.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import Group319 from "../dummy/images/Group 319.png";
import Group164 from "../dummy/images/Group 164.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group325 from "../dummy/images/Group 325 (1).png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeComponent";
import { config } from "../../config";
// import CheckoutForm from "../common/StripeComponent";
const StripeElements = ({ subscriptionType, course }: any) => {
  const navigate = useNavigate();

  const myName = "payment-gateway-page";

  //   console.log("Course and type", subscriptionType, course);

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState("");

  const publishableKey = config.actors.find(
    (a: any) => a.name === SubscriptionClient_Name
  )?.config["stripePublishableKey"];

  // const publishableKey =
  //   "pk_test_51Mf3eMSETxF1OUegrBU9waiJ9nVgW2Gr3wHqOanePVBYhv1aJnjCXpTKuJpbV7yszeMAvGZBjkEoJYziojgFmi5A00Ge802Zsh";

  useEffect(() => {
    const createPaymentIntent = async () => {
      setStripePromise(await loadStripe(publishableKey));
      let result = await ask({
        from: myName,
        to: SubscriptionClient_Name,
        message: SubscriptionClient_Messages.CreatePaymentIntent,
        payload: {
          course: course!.id ? course!.id : course,
          subscriptionType: subscriptionType,
        },
      });
      console.log("Result from payment gateway page", result);
      if (!result?.error) {
        setClientSecret(result?.payload?.returnValue?.stripeClientSecret);
      }
    };
    createPaymentIntent();
  }, []);

  return (
    <>
      <h1 style={{ display: "flex", justifyContent: "center" }}>
        Aviation Fuel Mastermind
      </h1>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default StripeElements;
