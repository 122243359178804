//---------------------------------------------------------------------------------
//
// modulo/types.ts
//
//---------------------------------------------------------------------------------

import {
  KeysAndValues,
  Logger,
  SaveActorState,
  StatefulActor,
} from "lib-js-c-modulo/index.mjs";

//---------------------------------------------------------------------------------

export const SYSTEM = "system";

//---------------------------------------------------------------------------------

export enum AppEnvironment {
  LOCAL = "local",
  DEV = "dev",
  QA = "qa",
  STAGING = "staging",
  PROD = "prod",
}

//---------------------------------------------------------------------------------

export enum ActorType {
  LOCAL = "local",
  STUB = "stub",
  REMOTE = "remote",
}

//---------------------------------------------------------------------------------

export type ActorConfig = {
  name: string;
  type: ActorType;
  config: KeysAndValues;
};

//---------------------------------------------------------------------------------

export type RemoteActorConfig = ActorConfig & {
  type: ActorType.REMOTE;
  url?: string;
};

//---------------------------------------------------------------------------------

export type StubActorConfig = ActorConfig & {
  type: ActorType.STUB;
  constructor: (config: StubActorConfig) => KeysAndValues;
};

//---------------------------------------------------------------------------------

export type LocalActorConfig = ActorConfig & {
  type: ActorType.LOCAL;
  constructor: (config: LocalActorConfig) => StatefulActor;
};

//---------------------------------------------------------------------------------

export type AppConfig = {
  env: AppEnvironment;
  appName: string;
  appVersion: string;
  moduleId: string;
  websiteLink: string;
  moduloApiEndPoint: string;
  allowStubsIn: Array<AppEnvironment>;
  actors: Array<LocalActorConfig | StubActorConfig | RemoteActorConfig>;
};

//---------------------------------------------------------------------------------

export interface InitModuloParameters {
  config: AppConfig;
  saveActorData?: SaveActorState;
  loadActorData?: (name: string) => string | null | undefined;
  logger?: Logger;
}

//---------------------------------------------------------------------------------
