import React, { useEffect } from "react";
// import logo from "../dummy/logo.svg";
import logo from "../../assets/Images/logo.svg";
import homeIcon from "../../assets/Images/Home-icon.png";
import tickMark from "../../assets/Images/circle.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PaymentSuccess.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { Pages } from "../../Pages";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";

const PaymentSuccessStatic = () => {
  const navigate = useNavigate();
  const { subscriptionType } = useParams();
  const location = useLocation();
  const myObject = location.state;

  const myName = "Payment-success-page";

  //   useEffect(() => {
  const verifyPayment = async () => {
    console.log("result verify payment called");
    let result = await ask({
      from: myName,
      to: SubscriptionClient_Name,
      message: SubscriptionClient_Messages.VerifyPayment,
      payload: {},
    });
    console.log(
      "result from verify payment",
      result?.payload?.returnValue?.message
    );
    if (result?.payload?.returnValue?.message === "success") {
      navigate(`${Pages.PaymentSuccess}/${subscriptionType}`, {
        state: myObject,
      });
    }
    if (result?.payload?.returnValue?.message !== "success") {
      navigate(`${Pages.PaymentFailed}/${subscriptionType}`, {
        state: myObject,
      });
    }
  };
  //     verifyPayment();
  //   }, []);

  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const navigateToShoppingCart = async (course: any, subscriptionType: any) => {
    navigate(`${Pages.ShoppingCart}/${subscriptionType}`, { state: course });
  };

  return (
    <>
      {/* <div className="container">
        <img src={logo} alt="Logo" className="logo" />
        <img src={homeIcon} alt="Home" className="home-icon" />
        <div className="content">
          <img src={tickMark} alt="TickMark" className="tick-mark" />
          <div className="mainpara">
            <p className="bold-para">THANK YOU !</p>
            <p className="bold-para">YOUR ORDER HAS BEEN SUCCESSFUL</p>
            <p style={{ color: "#062a3b" }}>Your order ID: 00000000</p>
          </div>
          <div
            className="flexingcard"
            style={{
              marginRight: "-7rem",
              pointerEvents: "none",
              width: "125%",
            }}
          >
            <div className="whitecard" style={{ borderColor: "#C7B277" }}>
              {subscriptionType === SubscriptionType.Individual ? (
                <h3 className="packtype">Premium</h3>
              ) : (
                <h3 className="packtype">Business</h3>
              )}
              {subscriptionType === SubscriptionType.Individual ? (
                <h2 className="packprice">
                  $250 <span>/ year</span>
                </h2>
              ) : (
                <h2 className="packprice">
                  $1000 <span>/ year</span>
                </h2>
              )}

              <ul className="packlists">
                <li>Yearly Access</li>
                <li>Ask Expert</li>
                <li>Unlimited access to all content(Quiz)</li>
                <li>Personalize your learning path</li>
                <li>Test Knowledge</li>
                <li>Certification</li>
              </ul>
            </div>
          </div>
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            <button
              className="goldenbtn"
              onClick={() => navigate(Pages.Dashboard)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div> */}
      <div>
        <div className="box">
          <div className="course-pass-page-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <h1>Your subscription is in progress.</h1>
              <br />
              <div>
                {/* <h4 style={{ marginTop: "10px" }}>
                  You have not cleared the assessment
                </h4> */}
              </div>
              <div>
                <h4>Please click CONTINUE to redirect back to AFM</h4>
              </div>
              <div style={{ marginTop: "30px" }}>
                <button
                  onClick={verifyPayment}
                  style={{ padding: "10px", backgroundColor: "#c7b277" }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessStatic;
