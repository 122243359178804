import { ask } from "lib-js-c-modulo/index.mjs";
import React from "react";
import { useEffect, useState } from "react";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";

// import React, { useEffect } from "react";
import "./style.css";
import "./BookMarks.css";
import logo from "../../assets/logo.svg";

import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";

const Bookmarks = () => {
  const navigate = useNavigate();
  const myName = "dashboard";
  const { courseId } = useParams();

  const [bookmarks, setBookmarks] = useState([]);
  useEffect(() => {
    const getBookmarks = async () => {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          //   payload: {},
          message: CourseClient_Messages.GetUserBookMarks,
        });
        console.log(
          "Effect Result from GetUserBookMarks",
          result?.payload?.returnValue?.userBookmarks
        );
        setBookmarks(result?.payload?.returnValue?.userBookmarks);
      } catch (error) {
        console.log("Error is", error);
      }
    };
    getBookmarks();
  }, []);

  const navigateToTopic = async (bm: any) => {
    const { courseId, parentTopicId, id } = bm?.topic;
    console.log(
      "Effect navigate to topic clicked",
      bm,
      courseId,
      parentTopicId,
      id
    );
    navigate(`${Pages.Player}/${courseId}/${parentTopicId}/${id}/${id}`, {
      state: bm,
    });
  };

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Effect to close the sidebar on smaller screens
  useEffect(() => {
    // const handleResize = () => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
    // };

    // Add event listener for window resize
    // window.addEventListener("resize", handleResize);

    // // Cleanup the event listener when component unmounts
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-12 p-0 mytogclass ${
              isSidebarOpen ? "opened" : ""
            }`}
          >
            <div className="toggleitem">
              <div className="custombar" onClick={toggleSidebar}>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0 mymainparthtml">
            {/* <!-- leftmenu Blue Part --> */}
            <CoursePlayerSidebar
              sessionId={null}
              courseId={courseId}
              isSidebarOpen={isSidebarOpen}
              setSidebarOpen={setSidebarOpen}
              page={Pages.Bookmarks}
            />

            <div className="mainbody">
              <div className="">
                <div className="contentpart">
                  <div className="titlehead">
                    <h2>Bookmarks</h2>
                  </div>
                  <div className="quediv">
                    {bookmarks && bookmarks.length !== 0 ? (
                      <div className="mycomantable bookmarks-table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Bookmarks
                              </th>

                              <th
                                scope="col"
                                style={{ background: "#062A3B" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookmarks.map((bm: any) => (
                              <tr>
                                <td>{bm?.bookmarkNote}</td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigateToTopic(bm)}
                                >
                                  View
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="mycomantable bookmarks-table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Bookmarks
                              </th>

                              <th
                                scope="col"
                                style={{ background: "#062A3B" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>You have no bookmarks.</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0">
            <footer>
              <div className="footertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bookmarks;
