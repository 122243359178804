import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Pages } from "../../Pages";
import { useNavigate } from "react-router";
const origin = window.location.origin;

export default function CheckoutForm() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<String | undefined>("");
  const [isProcessing, setIsProcessing] = useState(false);

  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("stripe not loaded");
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `${origin}/completion`,
        // return_url: "",
        return_url:
          `${window.location.origin}` +
          `${Pages.PaymentSuccessStatic}/${SubscriptionType.Individual}`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // todo add subscription api also here
      navigate(`${Pages.PaymentSuccessStatic}/${SubscriptionType.Individual}`);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      {/* <h1>Aviation Fuel Mastermind</h1> */}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button
          style={{
            backgroundColor: "orange",
            borderRadius: "3px",
            color: "white",
            border: "0",
            padding: "12px 16px",
            marginTop: "16px",
            fontWeight: "600",
            cursor: "pointer",
            transition: "all 0.2s ease",
            display: "block",
            justifyContent: "center",
          }}
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && (
          <div id="payment-message" style={{ color: "red" }}>
            {message}
          </div>
        )}
      </form>
    </div>
  );
}
