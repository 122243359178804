import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Pages } from "../../Pages";
import Home from "../../assets/Images/Home-icon.png";
import logo from "../../assets/Images/logo.png";
import bookmarkicn from "../../assets/Images/bookmarkicn.png";
import table from "../../assets/Images/Table.png";
import "../dummy/css/bootstrap.min.css";
import "./ShoppingCart.css";
import { loadStripe } from "@stripe/stripe-js";
import { Dashboard } from "tabler-icons-react";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";

const ShoppingCart = () => {
  const navigate = useNavigate();
  const { subscriptionType } = useParams();
  const location = useLocation();
  const myObject = location.state;

  const baseURL = window.location.origin;

  console.log(
    "Effect my object and subscription type",
    myObject,
    subscriptionType,
    typeof baseURL
  );

  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const myName = "Shopping-Cart";

  const makePayment = async () => {
    navigate(`${Pages.StripePaymentGateway}/${subscriptionType}`, {
      state: myObject,
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logo} alt="Cart Logo" />
          </div>
          <div className="col-6 homeright">
            <a href="/">
              <img src={Home} alt="Home Icon" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="cart-shop-content">
              <h2 className="carttitle">Shopping Cart</h2>
              <div className="row">
                <div className="col-md-8">
                  <div className="">
                    <table className="shocart-table">
                      <thead>
                        <tr className="titlemainrowtr">
                          <th>Course in cart</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="mainproductname">
                              <span className="bookiconcart">
                                <img src={bookmarkicn} alt="Book Icon" />
                              </span>
                              <span>
                                Business Plan <br /> Lorem ipsum
                              </span>
                            </span>
                          </td>
                          <td
                            className="align-right remove-cell"
                            onClick={() => navigate(Pages.Dashboard)}
                          >
                            Remove
                          </td>
                          {subscriptionType === SubscriptionType.Individual ? (
                            <td className="align-right price-cell">$ 250</td>
                          ) : (
                            <td className="align-right price-cell">$ 1000</td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="totalcart">
                    <h4 className="totaltitlecart">Total:</h4>
                    {subscriptionType === SubscriptionType.Individual ? (
                      <span className="price">$250</span>
                    ) : (
                      <h3 className="totalpricecart">$ 1000</h3>
                    )}

                    {/* <a className="cartpaymentbtn" href="/payment" onClick={makePayment}>
                    Proceed To Payment
                  </a> */}
                    <button className="cartpaymentbtn" onClick={makePayment}>
                      Proceed to Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer part */}
        <div className="row">
          <div className="col-md-12 p-0">
            <footer className="cartfooter">
              <div className="cartfootertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
