//---------------------------------------------------------------------------------
//
// course/CourseClient.ts
//
//---------------------------------------------------------------------------------

import {
  ask,
  Dictionary,
  getData,
  KeysAndValues,
  Message,
  MessageResponse,
  StatefulActor,
  StatefulActorErrorState,
  StatefulActorInstance,
  StatefulActorStateEntry,
  StatefulActorStateEvent,
  StatefulActorStateInstance,
} from "lib-js-c-modulo/index.mjs";
import { LocalActorConfig } from "../../modulo/types";
//   import {
//     //   AuthClient_Data,
//     //   AuthClient_Messages,
//     AuthClient_Name,
//     AuthClient_States,
//     //   AuthService_Name,
//     CourseClient_Data,
//     CourseClient_Messages,
//     CourseClient_Name,
//     CourseClient_States,
//     CourseService_Name,
//   } from "./types";
import {
  addSessionToken,
  AuthClient_State_Authenticated,
} from "../auth/AuthClient";
import {
  AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
} from "../auth/types";
import { CourseClient_States } from "../course/types";
import {
  SubscriptionClient_Data,
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
  SubscriptionClient_States,
  SubscriptionService_Name,
} from "./types";

//---------------------------------------------------------------------------------

const defaultData: () => SubscriptionClient_Data = () => ({
  // courseList: [],
  // topicList: [],
  // moduleList: [],
});

//---------------------------------------------------------------------------------

class SubscriptionClient_State_Start extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    this.actor.gotoState(SubscriptionClient_States.NotAuthenticated, input);
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [`${AuthClient_Name}/changed`]: async (input?: KeysAndValues) => {
      const authState = input?.event?.payload?.state;
      const runClientState = this.actor.state;
      switch (authState) {
        case AuthClient_States.NotAuthenticated:
          console.log("COURSE CLIENT: NOT AUTHENTICATED");
          this.actor.gotoState(
            SubscriptionClient_States.NotAuthenticated,
            defaultData()
          );
          return;
        case AuthClient_States.Authenticated:
          console.log("COURSE CLIENT: AUTHENTICATED");
          if (runClientState === SubscriptionClient_States.NotAuthenticated)
            this.actor.gotoState(
              SubscriptionClient_States.Authenticated,
              defaultData()
            );
          return;
        default:
          // Do nothing
          return;
      }
    },
  };
}

// --------------------------------------------------------------------------------

class SubscriptionClient_State_NotAuthenticated extends StatefulActorStateInstance {}
class SubscriptionClient_State_Authenticated extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    const init = async () => {
      try {
        this.actor.setState(SubscriptionClient_States.WIP);
        // const result = await ask({
        //   from: SubscriptionClient_Name,
        //   to: SubscriptionService_Name,
        //   message: SubscriptionClient_Messages.,
        //   payload: addSessionToken({}),
        //   expectResult: true,
        // });
        // if ((result as MessageResponse).error) throw new Error(result!.error);

        // console.log("list of courses", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          //   ...result!.payload,
        });
      } catch (error: any) {
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    };
    init();
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [SubscriptionClient_Messages.AddSubscription]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT", input);
        // return { payload: "Hello" };
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: SubscriptionClient_Name,
          to: SubscriptionService_Name,
          message: SubscriptionClient_Messages.AddSubscription,
          payload: addSessionToken({
            course: input!.course,
            subscriptionType: input!.subscriptionType,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("SUBSCRIPTION CLIENT TEST", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { result };
        // const topicResult =
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    },

    [SubscriptionClient_Messages.CreateCheckoutSession]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT", input);
        // return { payload: "Hello" };
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: SubscriptionClient_Name,
          to: SubscriptionService_Name,
          message: SubscriptionClient_Messages.CreateCheckoutSession,
          payload: addSessionToken({
            course: input!.course,
            subscriptionType: input!.subscriptionType,
            baseURL: input!.baseURL,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("payment gateway Results:", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          // ...result!.payload,
        });
        const returnValue = result?.payload;
        return { returnValue };
        // const topicResult =
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    },

    [SubscriptionClient_Messages.CreatePaymentIntent]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT", input);
        // return { payload: "Hello" };
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: SubscriptionClient_Name,
          to: SubscriptionService_Name,
          message: SubscriptionClient_Messages.CreatePaymentIntent,
          payload: addSessionToken({
            course: input!.course,
            subscriptionType: input!.subscriptionType,
            baseURL: input!.baseURL,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("payment gateway Results:", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          // ...result!.payload,
        });
        const returnValue = result?.payload;
        return { returnValue };
        // const topicResult =
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    },

    [SubscriptionClient_Messages.VerifyPayment]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT", input);
        // return { payload: "Hello" };
        this.actor.setState(CourseClient_States.WIP);
        // this.actor.setState(AuthClient_States.LoggingOut);
        // this.actor.setState(AuthClient_States.Validating, {});

        const result = await ask({
          from: SubscriptionClient_Name,
          to: SubscriptionService_Name,
          message: SubscriptionClient_Messages.VerifyPayment,
          payload: addSessionToken({}),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("payment verify Results:", result);

        const authLocalStorageResult = await ask({
          from: SubscriptionClient_Name,
          to: AuthClient_Name,
          message: AuthClient_Messages.SubscriptionStateChange,
          payload: addSessionToken({ result }),
          expectResult: true,
        });

        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        // const authClientState = new AuthClient_State_Authenticated
        // AuthClient_State_Authenticated.setState(AuthClient_States.Authenticated, {
        //   ...result!.payload,
        // });
        const returnValue = result?.payload;
        return { returnValue };
        // const topicResult =
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
        // this.actor.gotoState(AuthClient_States.ValidateError, {
        //   error: error.message,
        // });
        return { error: error.message };
      }
    },
  };
}

// --------------------------------------------------------------------

export function createSubscriptionClientActor(
  config: LocalActorConfig
): StatefulActor {
  const actor = new StatefulActorInstance(config.name, defaultData(), false);
  actor.setAllStates([
    new SubscriptionClient_State_Start(
      SubscriptionClient_States.Start,
      true,
      actor
    ),
    new StatefulActorStateInstance(SubscriptionClient_States.WIP, true, actor),
    new SubscriptionClient_State_NotAuthenticated(
      CourseClient_States.NotAuthenticated,
      false,
      actor
    ),
    new SubscriptionClient_State_Authenticated(
      CourseClient_States.Authenticated,
      false,
      actor
    ),
    new StatefulActorErrorState(
      SubscriptionClient_States.Err,
      actor,
      SubscriptionClient_States.Authenticated,
      {}
    ),
  ]);
  actor.listenToActors([AuthClient_Name], true);
  return actor;
}
