import "./dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import logo from "./images/logo.svg";
import loginicon from "./images/loginicon.png";
import videolanding from "./images/videolanding.png";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  ActorEmittedEvent,
  ask,
  getData,
  KeysAndValues,
  listenTo,
  stopListeningTo,
} from "lib-js-c-modulo/index.mjs";
import { useLocation, useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import {
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
} from "../../actors/course/types";
import { useCallback, useEffect, useState } from "react";
import Loading from "../common/Loading";
import {
  SubscriptionClient_Name,
  SubscriptionClient_States,
} from "../../actors/subscription/types";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { useSubscriptionNavigation } from "../../appUtils";
import { config } from "../../config";
import VideoPlayer from "./VideoPlayer";

const myName = "Dashboard-gui";

const DummyDashboard = ({
  isActiveCorporateAdmin,
}: {
  isActiveCorporateAdmin: boolean;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const existing = queryParams.get("existing");
  const [showIcon, setShowIcon] = useState(false);
  const courseData = getData(
    CourseClient_Name
  ) as KeysAndValues as CourseClient_Data;

  const userDetails = getData(
    AuthClient_Name
  ) as KeysAndValues as AuthClient_Data;

  const subscriptionData = getData(
    SubscriptionClient_Name
  ) as KeysAndValues as any;

  // console.log("is corporate", isActiveCorporateAdmin);
  console.log("COURSE DATA", courseData);
  console.log("USER DATA", userDetails);

  const courseList = courseData?.courseList;

  const [courses, setCourses] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setCourses(courseData?.courseList);
    setLoading(false);
  }, [courseList]);

  useEffect(() => {
    if (subscriptionData?.state === SubscriptionClient_States.Authenticated) {
      setLoading(false);
      console.log("is os", subscriptionData?.state);
    } else {
      setLoading(true);
    }
  }, [subscriptionData]);

  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const subscriptionNavigation = useSubscriptionNavigation();

  // useEffect(() => {
  //   const fetchTopics = async () => {
  //     try {
  //       let result = await ask({
  //         from: myName,
  //         to: CourseClient_Name,
  //         message: CourseClient_Messages.GetTopics,
  //       });
  //       console.log("RESULT FROM DUMMY DASHBOARD", result);

  //       // if (!result!.error) navigate(Pages.Home);
  //       if (!result!.error) {
  //         console.log("Error is", result?.error);
  //       }
  //     } catch (err) {
  //       console.log("Caught error", err);
  //       // navigate(Pages.Dashboard);
  //     }
  //   };
  //   fetchTopics();
  // }, [courseData]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="headerbanner">
                  <div className="">
                    <div className="customheader">
                      <nav className="navbar sticky-top navbar-expand-lg">
                        <div className="container-fluid">
                          <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo01"
                            aria-controls="navbarTogglerDemo01"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => setShowIcon(!showIcon)}
                          >
                            {showIcon ? (
                              <img
                                src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
                                alt=""
                                style={{
                                  width: "20px",
                                  marginTop: "20px",
                                  // marginLeft: "80px",
                                  position: "relative",
                                  left: "20px",
                                }}
                              />
                            ) : (
                              <span
                                className="navbar-toggler-icon"
                                style={{ color: "black" }}
                              ></span>
                            )}
                          </button>
                          <div
                            className="collapse navbar-collapse"
                            id="navbarTogglerDemo01"
                          >
                            <a className="navbar-brand" href="www.google.com">
                              <img src={logo} />
                            </a>
                            <ul
                              style={{ cursor: "pointer" }}
                              className="navbar-nav mr-auto mt-2 mt-lg-0"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  // onClick={() => navigate(Pages.Home)}
                                  href={config.websiteLink}
                                  style={{ cursor: "pointer" }}
                                >
                                  Home
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href={`${config.websiteLink}/#what`}
                                >
                                  What
                                </a>
                              </li>
                              {/* <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href={`${config.websiteLink}/#benifits`}
                                >
                                  Benefits
                                </a>
                              </li> */}
                              <li
                                className="nav-item"
                                // onClick={() => navigate(Pages.Home)}
                              >
                                <a
                                  className="nav-link"
                                  href={`${config.websiteLink}/#aboutus`}
                                >
                                  About Us
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href={`${config.websiteLink}/#contactus`}
                                >
                                  Contact Us
                                </a>
                              </li>
                              {/* <li className="nav-item">
                                <a className="nav-link" href="www.google.com">
                                  eLearning
                                </a>
                              </li> */}
                              <li className="nav-item" onClick={doLogout}>
                                <a className="nav-link" href="#">
                                  <img
                                    id="togleicon"
                                    src={loginicon}
                                    className="me-2"
                                  />
                                  Log Out
                                </a>
                              </li>
                              <li className="freetrial nav-item">
                                <a href="#">Free Trial</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-md-8">
                      <div className="headertextlanding">
                        <h4>Skill based</h4>
                        <h3>Training &</h3>
                        <h2>Certifications</h2>
                        <div className="homebtn">
                          {courses[0]?.course?.id ? (
                            <button
                              className="goldenbtn"
                              onClick={() => {
                                navigate(
                                  `${Pages.CourseLanding}/${courses[0]?.course?.id}`
                                );
                              }}
                            >
                              {existing ? "RESUME COURSE" : "START COURSE"}
                            </button>
                          ) : (
                            <button
                              className="goldenbtn"
                              // onClick={() =>
                              //   navigate(
                              //     `${Pages.CourseLanding}/${courses[0]?.course?.id}`
                              //   )
                              // }
                            >
                              <Loading />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="landingcontainer">
              <div className="row">
                <div className="col-md-12">
                  <div className="videodiv">
                    {/* <img className="img-fluid" src={videolanding} /> */}
                    <VideoPlayer
                      // videoSrc="/Landing-page-video/test-video.mp4"
                      videoSrc="/Landing-page-video/AFM_Infographic.mp4"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row forsec">
    <div className="col-md-12 text-center">
      <h2 className="omanhead"><span>Content</span></h2>
    </div>
    <div className="col-md-12">
    
      <div className="swiper customsldier">

        
        <div className="swiper-wrapper">
  
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide3} />
              <div className="centered"><img src={lockbig} /></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide2} />
              <div className="centered"><img src={lockbig} /></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide3} />
              <div className="centered"><img src={lockbig} /></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide2} />
              <div className="centered"><img src={lockbig} /></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide3} />
              <div className="centered"><img src={lockbig}  /></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="mainsliderlock">
              <img src={slide2} />
              <div className="centered"><img src={lockbig}/></div>
            </div>
          </div>
        </div>
    
        <div className="swiper-pagination"></div>

     
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </div>
  </div> */}
            </div>

            <div className="row">
              <div className="col-md-12 trialtitle">
                <h2> WELCOME TO</h2>
                <h3>AVIATION MASTER MIND</h3>
                <br />

                <div className="col-md-12 text-center">
                  <p className="omanheadp">
                    The Aviation fuel for business forms an essential part of
                    the portfolio of any oil company. The importance is out of
                    proportion to the small volumes of jet fuel sold by any oil
                    company compared to its entire business.
                    <br />
                    AFM training module is intented for the use and guidance of
                    the personnel engaged in Aviation Products Quality Control
                    at Terminals, airportdepots and into-plane services for
                    ensuring thatn clean, dry and on specification product is
                    finally deliver to aircraft. <br />
                    <br />
                    The jet Fuel business needs to be viewed separately due to
                    various reason, some of which are given below.
                    <div className="hello">
                      <ul>
                        <li>
                          Human lives depend on whatever happens to Jet Fuel
                          during its journey from the refinery to the aircraft.
                        </li>
                        <li>
                          As they say, "You can push a car that has stalled due
                          to choked Fuel Filters, but u cant do the same to
                          aircraft with 300+ passengers on board".
                        </li>
                        <li>
                          The aircraft systems have built-in redundancy for all
                          systems except the fuel handling system due to its
                          large size in the aircraft.
                        </li>
                        <li>
                          Jet fuel forms a very high-profile business with
                          internatioanl exposure for any company. A company's
                          image in the Aviation field can make or break its
                          reputation in the global fuel markets.
                        </li>
                        <li>
                          Due to the considerable risk attached to human lives,
                          it is necessary to maintain the highest standards of
                          operations and quality control.
                        </li>
                        <li>
                          Fuel suppliers must provide Aircraft Refueling
                          Liability insurance coverage of 1 Billion US dollars.
                        </li>
                      </ul>
                    </div>
                    <br />
                    With the above in mind, all personnel handle it carefully
                    and in line with laid-down procedures, giving it extra care
                    and not treating jet fuel like "just another fuel.. Aviation
                    Fuel Mastermind (AFM) aims to address all the basics of
                    aviation operations at the airfield in a simple language for
                    people across the aviation fuel supply chain to enhance
                    their knowledge.
                    <br />
                    Aviation Fuel Mastermind (AFM) aims to address all the basic
                    of Aviation operations at the airfield in a simple language
                    for people across the aviation fuel supply chain to enhance
                    their knowledge.
                    <br />
                    <br />
                    It should, however, be understood that AFM is not meant to
                    replace or overrule the Energy Institute (EI) or Joint
                    Inspection Group (JIG) standards which need to be
                    continuously referred to for in-depth knowledge of aviation
                    Operations.
                    <br />
                    <br />
                    The AFM training module has been prepared by experts with
                    over 100 years of combined on hands working experience
                    across the aviation fuel supply chain and is intended to
                    provide guidance and good practice on the siting,layout,
                    design, construction, testing, commissioning, and
                    maintenance of aircraft fueling facilities, including the
                    design and construction of fuellers, hydrant services, and
                    ancillary equipment used in fueling aircraft.
                    <br /> <br />
                    The AFM team had made every effort to assure the accuracy
                    and reliability of the data conatined in the AFM Training
                    module;however,AFM makes no representation , warranty, or
                    guarantee in connection with the AFM training module and
                    hereby expressly disclaim any liability or responsibility
                    for loss or damage resulting from its user or for the
                    violation of any local or regional laws or regulations with
                    which AFM Training module may conflict.
                  </p>
                </div>

                <div className="col-md-12 text-center">
                  {courses[0]?.course?.id ? (
                    <button
                      className="golden"
                      onClick={() =>
                        navigate(
                          `${Pages.CourseLanding}/${courses[0]?.course?.id}`
                        )
                      }
                    >
                      {existing ? "RESUME COURSE" : "START COURSE"}
                    </button>
                  ) : (
                    <button
                      className="golden"
                      // onClick={() =>
                      //   navigate(
                      //     `${Pages.CourseLanding}/${courses[0]?.course?.id}`
                      //   )
                      // }
                    >
                      <Loading />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row thirdsec">
            <div className="col-lg-12 text-center">
              <h2 className="omanhead">
                Choose <span>Your plan</span>
              </h2>
            </div>
            <div className="col-lg-12">
              <div className="flexcardsubscribe">
                {/* <div className="mainborder">
                  <div className="headeramount">
                    <h4>Free Trial</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$00</h6>
                    <ul>
                      <li>15 days Free access</li>
                      <li>Limited courses (2 Basic Modules)</li>
                      <li>Test Knowledge (Quiz)</li>
                    </ul>
                    <div className="text-center contibtn">
                      <button
                        className="goldenbtn whitetext"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Current Course
                      </button>
                    </div>
                  </div>
                </div> */}
                {/* {isActiveCorporateAdmin ? ( */}
                <div className="mainborder">
                  <div className="headeramount">
                    <h4>Premium</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$250</h6>
                    <ul>
                      <li>Yearly Access</li>
                      <li>Unlimited Access To All Content</li>
                      <li>Test Knowledge (Quiz)</li>
                      <li>Ask Expert</li>
                      <li>Personalize Your Learning Path</li>
                      <li>Certification</li>
                    </ul>
                    <div
                      className="coursebtn"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="goldenbtn whitetext"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal"
                        onClick={() =>
                          subscriptionNavigation(
                            courses[0]?.course,
                            SubscriptionType.Individual
                          )
                        }
                      >
                        Upgrade Course
                      </button>
                    </div>
                  </div>
                </div>
                {/* ) : ( */}
                {/* <div className="mainborder">
                  <div className="headeramount">
                    <h4>business</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$1000</h6>
                    <ul>
                      <li>Yearly Access</li>
                      <li>Unlimited Access to All Content</li>
                      <li>Test Knowledge (Quiz)</li>
                      <li>Ask Expert</li>
                      <li>Personalize Your Learning Path</li>
                      <li>Certification</li>
                    </ul>
                    <div
                      className="coursebtn"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="goldenbtn whitetext"
                        onClick={() =>
                          subscriptionNavigation(
                            courses[0]?.course,
                            SubscriptionType.Company
                          )
                        }
                      >
                        Upgrade Course
                      </button>
                    </div>
                  </div>
                </div> */}
                {/* )} */}
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-0">
                <footer>
                  <div className="footertext">
                    <div>
                      {/* <p>
                        © Copyright 2022 Aviation Mastermind. All Rights
                        Reserved.
                      </p> */}
                      <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                    </div>
                    <div className="quicklinks">
                      <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered my-modal">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <div className="mainborder">
                    <div className="headeramount">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <h4>
                        Your free trial pack has expired Please Upgrade
                        subscription.
                      </h4>
                    </div>
                    <div className="contentamountbody">
                      <h6>$250</h6>
                      <ul>
                        <li>15 days Free access</li>
                        <li>Limited courses (2 Basic Modules)</li>
                        <li>Test Knowledge (Quiz)</li>
                      </ul>
                      <div className="text-center contibtn">
                        <button className="goldenbtn whitetext">
                          continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  // return (
  //   <>
  //     Welcome {fullName} <br />
  //     {}
  //     <button type="button" onClick={doLogout}>
  //       Logout(OG)
  //     </button>
  //     <div>
  //       <h1>Aviation Fuel Mastermind</h1>
  //       <p>
  //         Lorem Ipsum is simply dummy text of the printing and typesetting
  //         industry. Lorem Ipsum has been the industry's standard dummy text ever
  //         since the 1500s, when an unknown printer took a galley of type and
  //         scrambled it to make a type specimen book. It has survived not only
  //         five centuries, but also the leap into electronic typesetting,
  //         remaining essentially unchanged. It was popularised in the 1960s with
  //         the release of Letraset sheets containing Lorem Ipsum passages, and
  //         more recently with desktop publishing software like Aldus PageMaker
  //         including versions of Lorem Ipsum.
  //       </p>
  //       {/* <div>
  //         <button onClick={() => navigate(Pages.Player)}>Start Course</button>
  //       </div> */}
  //       <div>
  //         <h2>Available Courses</h2>
  //         <ul>
  //           {courses.map((course) =>
  //             course.status === "in-progress" ? (
  //               <li style={{ cursor: "pointer" }}>
  //                 <span
  //                   onMouseEnter={(e) =>
  //                     (e.currentTarget.style.color = "yellow")
  //                   }
  //                   onMouseLeave={(e) =>
  //                     (e.currentTarget.style.color = "white")
  //                   }
  //                 >
  //                   {course?.course?.courseNumber} - {course?.course?.name}
  //                 </span>
  //                 <div>
  //                   <button
  //                     onClick={() =>
  //                       navigate(
  //                         `${Pages.CourseLanding}/${encodeURIComponent(
  //                           course?.course?.id
  //                         )}`
  //                       )
  //                     }
  //                   >
  //                     Start Course
  //                     {/* <button onClick={() => navigate(Pages.CourseLanding)}>
  //                     Start Course
  //                   </button> */}
  //                   </button>
  //                 </div>
  //               </li>
  //             ) : (
  //               <li style={{ textDecoration: "line-through", color: "red" }}>
  //                 {course?.course?.courseNumber} - {course?.course?.name}
  //               </li>
  //             )
  //           )}
  //         </ul>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default DummyDashboard;
