import { PasswordInput, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { ask } from "lib-js-c-modulo/index.mjs";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { Pages } from "../../Pages";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import styled from "styled-components";
import banner from "../../assets/banner.png";
import NavBar from "../common/NavBar";
import { json } from "stream/consumers";
import logoafmcart from "../../assets/logoafmcart.png";
import homeicon from "../../assets/homeicon.svg";

const myName = "reset-password";

interface ResetPasswordData {
  email: string;
  password: string;
  confirmPassword: string;
  otp: string;
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const resetPasswordElement = useFocusTrap();

  // const state = useLocation().state as {
  //   email: string;
  // };

  const resetPasswordSchema = z
    .object({
      email: z.string().email({ message: "Invalid email" }),
      password: z.string().min(4, { message: "password is Mandatory" }),
      confirmPassword: z
        .string()
        .min(4, { message: "confirmPassword is Mandatory" }),
      otp: z.string().min(4, { message: "otp is Mandatory" }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Confirm password should match the password",
      path: ["confirmPassword"],
    });

  const resetPasswordForm = useForm({
    schema: zodResolver(resetPasswordSchema),
    initialValues: {
      // email: state.email,
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
    },
  });

  const reset = useCallback(() => {
    resetPasswordForm.reset();
  }, [resetPasswordForm]);

  const doResetPassword = useCallback(
    async (values: ResetPasswordData) => {
      try {
        console.log("Reset password called");
        const result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.ResetPassword,
          payload: {
            email: values!.email,
            password: values!.password,
            otp: values!.otp,
          },
        });
        if (!result!.error) navigate(Pages.Login);
      } catch (err) {
        navigate(Pages.ResetPassword);
      }
    },
    [navigate]
  );

  return (
    <>
      {/* <Container>
        <NavBar />
        <form
          onSubmit={resetPasswordForm.onSubmit((values) =>
            doResetPassword(values)
          )}
          ref={resetPasswordElement}
        >
          <h2 className="heading-forgot-password">FORGOT PASSWORD</h2>
          <TextInput
            placeholder="Email Address"
            {...resetPasswordForm.getInputProps("email")}
            data-autofocus
            className="forget-password-input"
          />
          <TextInput
            placeholder="Password"
            type="password"
            {...resetPasswordForm.getInputProps("password")}
            className="forget-password-input"
          />
          <TextInput
            placeholder="Confirm Password"
            type="password"
            {...resetPasswordForm.getInputProps("confirmPassword")}
            className="forget-password-input"
          />
          <TextInput
            placeholder="Enter OTP"
            {...resetPasswordForm.getInputProps("otp")}
            className="forget-password-input"
            data-autofocus
          />
          <div className="button-div">
            <AuthButton type="submit" className="save-btn">
              Save Password
            </AuthButton>
            &nbsp; &nbsp;
            <AuthButton type="button" onClick={reset}>
              Reset
            </AuthButton>
          </div>
        </form>
      </Container> */}
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logoafmcart} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a>
                <img
                  src={homeicon}
                  alt="Home Icon"
                  onClick={() => navigate(Pages.Others)}
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <div className="titleofform">
              <h3>Sign In</h3>
            </div>
            <div className="d-flex justify-content-center otpcontent text-center mt-0">
              <p>
                Need an account?{" "}
                <a href="#" onClick={() => navigate(Pages.Register)}>
                  {" "}
                  Get started!
                </a>
              </p>
            </div> */}
              <div className="otpmain">
                <form
                  onSubmit={resetPasswordForm.onSubmit((values) =>
                    doResetPassword(values)
                  )}
                  ref={resetPasswordElement}
                >
                  {/* <h2 className="heading-forgot-password">FORGOT PASSWORD</h2> */}
                  <div className="titleofform">
                    <h3>SET NEW PASSWORD</h3>
                  </div>
                  <TextInput
                    placeholder="Email Address"
                    {...resetPasswordForm.getInputProps("email")}
                    data-autofocus
                    className="forget-password-input"
                    style={{ marginBottom: "15px", borderRadius: "50px" }}
                    radius={"sm"}
                    size={"md"}
                  />
                  <TextInput
                    placeholder="Password"
                    type="password"
                    {...resetPasswordForm.getInputProps("password")}
                    className="forget-password-input"
                    style={{ marginBottom: "15px" }}
                    radius={"sm"}
                    size={"md"}
                  />
                  <TextInput
                    placeholder="Confirm Password"
                    type="password"
                    {...resetPasswordForm.getInputProps("confirmPassword")}
                    className="forget-password-input"
                    style={{ marginBottom: "15px" }}
                    radius={"sm"}
                    size={"md"}
                  />
                  <TextInput
                    placeholder="Enter OTP"
                    {...resetPasswordForm.getInputProps("otp")}
                    className="forget-password-input"
                    data-autofocus
                    style={{ marginBottom: "35px" }}
                    radius={"sm"}
                    size={"md"}
                  />
                  {/* <div className="button-div">
                    <AuthButton type="submit" className="save-btn">
                      Save Password
                    </AuthButton>
                    &nbsp; &nbsp;
                    <AuthButton type="button" onClick={reset}>
                      Reset
                    </AuthButton>
                  </div> */}
                  <AuthButtonContainer>
                    <AuthButton type="submit">Save Password</AuthButton>

                    <AuthButton type="button" onClick={reset}>
                      Reset
                    </AuthButton>
                  </AuthButtonContainer>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Container = styled.div`
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  min-height: 768px;
  padding-bottom: 50px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65%;
  }
  .forget-password-input {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  input {
    background: #ffffff;
    border: 1px solid #c7b277;
    border-radius: 5px;
    width: 450px;
    height: 50px;
    font-family: "Roboto-Light";
    color: #050505;
    font-size: 18px;

    ::placeholder {
      color: #050505;
      padding-bottom: 50px;
    }
  }
  .heading-forgot-password {
    margin-bottom: 20px;
  }
  // .save-btn{
  //   padding-right:
  // }
  .button-div {
    display: flex;
    flex-direction: row;
  }
  .mantine-y0fwdk,
  .mantine-wqgzfp {
    width: 450px;
    height: 65px !important;
    margin-bottom: 15px;
  }
  .mantine-o3oqoy {
    bottom: 25px;
    padding-bottom: 50px;
  }

  .mantine-wqgzfp {
    border: none;
    background: none;
  }
  @media (max-width: 576px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 400px;
      height: 55px !important;
      margin-bottom: 15px;
    }
    .button-div {
      display: flex;
      flex-direction: column !important;
    }
    input {
      width: 400px;
      height: 55px !important;
    }
    form {
      margin-top: 50px;
    }
  }
  @media (max-width: 424px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 370px;
      height: 45px !important;
      margin-bottom: 15px;
    }

    input {
      width: 370px;
      height: 45px !important;
    }
  }
  @media (max-width: 376px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 290px;
      height: 40px !important;
      margin-bottom: 15px;
    }

    input {
      width: 290px;
      height: 40px !important;
    }
  }
`;

export default ResetPassword;
