//---------------------------------------------------------------------------------

import { createAuthClientActor } from "./actors/auth/AuthClient";
import { authServiceStub } from "./actors/auth/AuthServiceStub";
import { AuthClient_Name, AuthService_Name } from "./actors/auth/types";
import { createCompanyAdminClientActor } from "./actors/company-admin/CompanyAdminClient";
import {
  CompanyAdminClient_Name,
  CompanyAdminService_Name,
} from "./actors/company-admin/types";
import { createCourseClientActor } from "./actors/course/CourseClient";
import { courseServiceStub } from "./actors/course/CourseServiceStub";
import { CourseClient_Name, CourseService_Name } from "./actors/course/types";
import { createSubscriptionClientActor } from "./actors/subscription/SubscriptionClient";
import {
  SubscriptionClient_Name,
  SubscriptionService_Name,
} from "./actors/subscription/types";
import {
  ActorType,
  AppConfig,
  AppEnvironment,
  LocalActorConfig,
  RemoteActorConfig,
  StubActorConfig,
} from "./modulo/types";

//---------------------------------------------------------------------------------

export const config: AppConfig = {
  env: AppEnvironment.LOCAL,
  appName: "AFM Client",
  appVersion: "1.0.0",
  moduleId: "afm-client",
  websiteLink: "http://www.aviationfuelmastermind.com",
  // // START: LOCAL
  // moduloApiEndPoint: "http://localhost:8080/modulo",
  // // END: LOCAL
  // // START: PROD
  // moduloApiEndPoint: "https://app.aviationfuelmastermind.com/api/modulo",
  // // END: PROD
  // START: STAGING
  moduloApiEndPoint: "https://app.aviationfuelmastermind.com/api/modulo",
  // START: STAGING
  allowStubsIn: [AppEnvironment.LOCAL],
  actors: [
    // {
    //   name: AuthService_Name,
    //   type: ActorType.STUB,
    //   config: {},
    //   constructor: authServiceStub,
    // } as StubActorConfig,
    // {
    //   name: CourseService_Name,
    //   type: ActorType.STUB,
    //   config: {},
    //   constructor: courseServiceStub,
    // } as StubActorConfig,
    {
      name: AuthService_Name,
      type: ActorType.REMOTE,
      config: {},
    } as RemoteActorConfig,
    {
      name: CourseService_Name,
      type: ActorType.REMOTE,
      config: {},
    } as RemoteActorConfig,
    {
      name: SubscriptionService_Name,
      type: ActorType.REMOTE,
      config: {},
    } as RemoteActorConfig,
    {
      name: CompanyAdminService_Name,
      type: ActorType.REMOTE,
      config: {},
    } as RemoteActorConfig,
    {
      name: AuthClient_Name,
      type: ActorType.LOCAL,
      config: {},
      constructor: createAuthClientActor,
    } as LocalActorConfig,
    {
      name: CourseClient_Name,
      type: ActorType.LOCAL,
      config: {},
      constructor: createCourseClientActor,
    } as LocalActorConfig,
    {
      name: SubscriptionClient_Name,
      type: ActorType.LOCAL,
      config: {
        // stripePublishableKey:
        //   "pk_test_51Mf3eMSETxF1OUegrBU9waiJ9nVgW2Gr3wHqOanePVBYhv1aJnjCXpTKuJpbV7yszeMAvGZBjkEoJYziojgFmi5A00Ge802Zsh",
        stripePublishableKey:
          "pk_test_51NTP6RFUuroPTRljFdL3G0SvVWbP34IiDeTVHx2Yw0PwHyrDrTjxwv0GwirxARLdrjkdZffwQ7Uq5jmKfMqcE3op00ZAVnEr0Q",
        individualSubscriptionCharge: 250,
        corporateSubscriptionCharge: 1000,
      },
      constructor: createSubscriptionClientActor,
    } as LocalActorConfig,
    {
      name: CompanyAdminClient_Name,
      type: ActorType.LOCAL,
      config: {},
      constructor: createCompanyAdminClientActor,
    } as LocalActorConfig,
  ],
};

//---------------------------------------------------------------------------------
