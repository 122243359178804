import React, { useEffect, useState } from "react";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import Rectangle from "../dummy/images/Rectangle 66-img.png";
import Group318 from "../dummy/images/Group 318.png";
import Group319 from "../dummy/images/Group 319.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group164 from "../dummy/images/Group 164 (1).png";
import Group325 from "../dummy/images/Group 325 (1).png";
import Group212 from "../dummy/images/Group 212.png";
import { styled } from "styled-components";
import {
  faAngleDoubleDown,
  faAnglesLeft,
  faAnglesRight,
  faArrowAltCircleLeft,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faBangladeshiTakaSign,
  faLeftLong,
  faLongArrowAltRight,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
} from "../../actors/company-admin/types";
import { ask } from "lib-js-c-modulo/index.mjs";
const myName = "Dashboard-gui";
const CompanyUsersReport = () => {
  const [userData, setUserData] = useState<any>([]);
  interface MyObject {
    UserID: number;
    SubscriptionID: string;
    firstName: string;
    lastName: string;
    registeredon: string;
    courseStatus: string;
    assessment: string;
    assessmentResults: string;
    action: string;
  }

  const listObject = [
    {
      UserID: 2342342,
      SubscriptionID: "800	",
      firstName: "Shrinivasan",
      lastName: "Suryavanshi",
      registeredon: "Lorem ipsum",
      courseStatus: "Certified",
      assessment: "Pass",
      assessmentResults: "Pass 60%	",
      action: "Download Certificate",
    },
  ];
  const handlefetch = async () => {
    try {
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log("download report list coming ", result);
      setUserData(result?.payload?.returnValue?.allUser);
    } catch (error) {
      console.log(error);
      return; // Exit the function if there's an error
    }
  };
  useEffect(() => {
    handlefetch();
  }, []);

  // Create a new workbook and worksheet
  const handleDownload = async () => {
    const jsonData: any[] = listObject; // Your JSON data goes here
    try {
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log("download report list coming ", result);
      setUserData(result?.payload?.returnValue?.allUser);
    } catch (error) {
      console.log(error);
      return; // Exit the function if there's an error
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(userData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, "User_Report.xlsx");
  };
  console.log(userData, "this value jabbar ");
  return (
    <div>
      <Container>
        <div>
          <img src={Rectangle} alt="" id="Rectangle_img" />
          <img src={avilogo} alt="" id="avilogo_img" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: "200px",
              left: "10px",
            }}
          >
            <ol id="logo_ol">
              <li>
                <img src={Group318} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group319} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group320} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group324} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group321} alt="" className="sidebar_images" />
              </li>
            </ol>
            <ol id="list_ol">
              <li>
                <a href="" style={{ color: "#C7B277" }}>
                  COMPANY INFO
                </a>
              </li>
              <li>
                <a href="">MANAGER</a>
              </li>
              <li>
                <a href="">COURSE COMPLIANCE REPORT</a>
              </li>
              <li>
                <a href="">SUBSCRIPTION STATUS</a>
              </li>
              <li>
                <a href="">SUPPORT</a>
              </li>
            </ol>
            <button id="go_to_btn">GO TO COURSE</button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            left: "300px",
            backgroundColor: "#C7B277",
            width: "980px",
          }}
        >
          <p id="corp_heading">Corporate User Dashboard</p>

          <ol id="nav-ol">
            <li>
              <img
                src={vectorimg2}
                alt=""
                className="nav-img"
                style={{ width: "10px" }}
              />
            </li>
            <li>
              <a href="">Back To Dashboard</a>
            </li>
            <li>
              <img src={Group164} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Arron Lee</a>
            </li>
            <li>
              <img src={Group325} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Log Out</a>
            </li>
          </ol>
        </div>
        <div style={{ position: "absolute", top: "150px", left: "350px" }}>
          <h6 id="status_heading">STATUS</h6>
          <ol id="content_ol">
            <li>
              Total Registered User: <a href="#">40</a>
            </li>
            {/* <li id="certified_user_li">
              Certified Users: <a href="">20</a>
            </li> */}
          </ol>
        </div>
        <div style={{ position: "absolute", top: "350px", left: "350px" }}>
          <form>
            <label>Current Subscription ID:</label>
            <select id="drop_down_value">
              <option value="1800">1800</option>
              {/* <option value="saab">Saab</option>
              <option value="opel">Opel</option>
              <option value="audi">Audi</option> */}
            </select>
          </form>
        </div>
        <div>
          <table>
            <tr>
              <th>User ID</th>
              <th>Subscription ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Registered on</th>
              <th>Course Status</th>
              <th>Assessment</th>
              <th>Assessment Results</th>
              <th>Action</th>
            </tr>
            {userData?.map((obj: any) => (
              <>
                <tr>
                  <td>{obj?.id}</td>
                  <td>{obj?.subscriptionId ? obj?.subscriptionId : "gf231"}</td>
                  <td>{obj?.firstName}</td>
                  <td>{obj?.lastName}</td>
                  <td>
                    {obj?.validateOn
                      ? new Date(obj?.validateOn).toISOString().split("T")[0]
                      : "Not Registered"}
                  </td>
                  <td>{obj?.registrationStatus ? "NA" : "NA"}</td>
                  <td>{obj?.inviteDate ? "NA" : "NA"}</td>
                  <td>{obj?.designation ? "NA" : "NA"}</td>
                  {/* <td>{obj.firstName}</td>
                  <td>{obj.firstName}</td> */}
                  <td>
                    <a href="">Download Certificate</a>
                  </td>
                </tr>
              </>
            ))}
          </table>
        </div>

        <div>
          <button id="edit_btn" onClick={handleDownload}>
            DOWNLOAD REPORT
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "800px",
            position: "absolute",
            top: "1150px",
            left: "400px",
          }}
        >
          <button className="pre_next_btn">
            <span id="left_side_icon">
              <FontAwesomeIcon icon={faLeftLong} id="font_icon" />
            </span>
            Previous{" "}
          </button>
          <button className="pre_next_btn">
            Next{" "}
            <span id="right_side_icon">
              <FontAwesomeIcon icon={faRightLong} id="font_icon" />
            </span>
          </button>
        </div>

        {/* footer */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#FBFAFA",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <p id="footer_p">
            © Copyright 2023 Aviation Mastermind. All Rights Reserved.
          </p>
          <ol id="footer_ol">
            <li>PRIVACY & TERMS</li>
            {/* <li>TERM</li> */}
          </ol>
        </div>
      </Container>
    </div>
  );
};

export default CompanyUsersReport;
const Container = styled.div`
    #Rectangle_img{
    height:208vh;
    width:300px;
    
    }
    
    #avilogo_img{
        position:absolute;
        top:30px;
        left:50px;
    }
    
    #logo_ol{
    line-height:30px;
    list-style:none;
    /
    }
    
    #list_ol{
        line-height:30px;
        list-style:none;
       font-size:13px;
     
    }
    li{
        padding-top:15px; 
    }
    
    #list_ol li a{
    text-decoration: none;
     color:#FFFFFF;   
    }
    
    .sidebar_images{
    width:18px;
    }
    
    #go_to_btn{
    position:absolute;
    top:280px;
    left:30px; 
    background-color:#C7B277; 
    padding:8px 15px 8px 15px;
    border:none;
    font-size:13px;
     
    }
    #corp_heading{
        margin-top:19px;
        margin-left:15px;
    }
    #nav-ol{
    display:flex;
    flex-direction:row;
    
    color:#FFFFFF;
    // position:absolute;
    // top:3px;
    // margin-left:250px;
    // left:30px; 
    // width:82%;
    margin-left:240px
    
    }
    #nav-ol li {
    list-style:none; 
    
    }
    #nav-ol li a{
        margin-left:5px;
        color:#FFFFFF;  
    }
    #nav-ol li img{
    margin-left:40px; 
    color:#FFFFFF; 
    }
    .nav-img{
    width:30px;
    }
    
#status_heading{
    border:1px solid #C7B277;
    width:400px;
    height:8vh;
    background-color:#062A3B;
    padding-top:18px;
    padding-left:20px;
    color:#FFFFFF;
    position:absolute;
    top:0px;
    // margin-left:40px;
}

#content_ol{
    display:flex;
    flex-direction:row;
    border:1px solid #C7B277;
    width:400px;
    height:17vh;
    // margin-left:40px;
    padding-top:18px;
    padding-left:20px;
    // color:#FFFFFF;
    position:absolute;
    top:47px;
    left:0px;
    // margin-bottom:100px;
}
#content_ol li{
    list-style:none;
    // margin-left:2px;
    // position:absolute;
    color:#050505;

}
#certified_user_li{
    margin-left:14px;
}

#content_ol li a{
    text-decoration:none;
    color:#C7B277;
}

#drop_down_value{
    padding:5px 10px 5px 10px;
    margin-left:10px;
    border-radius:5px;
}
table {
    // font-family: arial, sans-serif;
    border-collapse: collapse;
    width:70%;
    position:absolute;
    top:407px;
    left:340px;

  }
  
  th{
    background-color: #062A3B;
    color:#FFFFFF;
  }
  td, th {
    border: 1px solid black;
    text-align: left;
    padding: 18px;
    font-size:12px;
  }
  
.pre_next_btn{
  padding:8px 15px 8px 15px;
  background-color:#062A3B;
  color:#FFFFFF;
  border:none;
}

#left_side_icon{
  margin-right:15px;
}

#right_side_icon{
  margin-left:15px;
}






 #footer_ol{
        display:flex;
        flex-direction:row;
       
    
    }
    #footer_ol li{
        margin-left:50px;
        list-style:none; 
    }
    #footer_p{
        margin-top:15px;
    }

    #edit_btn{
        position:absolute;
        top:900px;
        left:340px;
       background-color:#C7B277; 
    padding:12px 32px 12px 32px;
    border:none;
    font-size:12px;
    }
    `;
