import { useForm, zodResolver } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { TextInput } from "@mantine/core";
import { Pages } from "../../Pages";
import { useCallback } from "react";
import { ask } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import styled from "styled-components";
import banner2 from "../dummy/images/banner-2.jpg";
import logo from "../dummy/images/logo.png";
import homeicon from "../dummy/images/homeicon.png";
import "./RequestResetPassword.css";

const myName = "request-reset-password";

interface RequestResetPasswordData {
  email: string;
}

const RequestResetPassword = () => {
  const navigate = useNavigate();

  const requestResetPasswordElement = useFocusTrap();

  const RequestResetPasswordSchema = z.object({
    email: z.string().email({ message: "Invalid email" }),
  });

  const RequestResetPasswordForm = useForm({
    schema: zodResolver(RequestResetPasswordSchema),
    initialValues: {
      email: "",
    },
  });

  const doRequestResetPassword = useCallback(
    async (values: RequestResetPasswordData) => {
      try {
        const result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.RequestPasswordReset,
          payload: {
            email: values.email,
          },
        });
        // alert(values.email);
        if (!result!.error)
          navigate(Pages.ResetPassword, { state: { email: values.email } });
      } catch (err) {
        navigate(Pages.RequestResetPassword);
      }
    },
    [navigate]
  );

  return (
    <>
      {/* <div className="banner-container">
        <Container>
          <img src={logo} alt="" id="logo" />
          <img src={homeicon} alt="" id="homeicon" />
          <form
            onSubmit={RequestResetPasswordForm.onSubmit((values) =>
              doRequestResetPassword(values)
            )}
            ref={requestResetPasswordElement}
          >
            <TextInput
              placeholder="Email Address"
              {...RequestResetPasswordForm.getInputProps("email")}
              data-autofocus
              id="email_input"
              classNames={{ input: "custom-input" }}
              styles={{
                input: {
                  fontFamily: "sans-serif !important",
                  // color: "red !important",
                },
              }}
            />
            <AuthButtonContainer>
              <AuthButton
                type="submit"
                style={{ marginTop: "28px" }}
                id="sign_btn"
              >
                Send OTP
              </AuthButton>
            </AuthButtonContainer>
          </form>
        </Container>
      </div> */}
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logo} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a>
                <img
                  src={homeicon}
                  alt="Home Icon"
                  onClick={() => navigate(Pages.Others)}
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="titleofform">
                <h3>Forgot Password</h3>
              </div>
              {/* <div className="d-flex justify-content-center otpcontent text-center mt-0">
                <p>
                  Need an account?{" "}
                  <a href="#" onClick={() => navigate(Pages.Register)}>
                    {" "}
                    Get started!
                  </a>
                </p>
              </div> */}
              <div className="otpmain">
                <form
                  onSubmit={RequestResetPasswordForm.onSubmit((values) =>
                    doRequestResetPassword(values)
                  )}
                  ref={requestResetPasswordElement}
                >
                  <TextInput
                    placeholder="Email Address"
                    {...RequestResetPasswordForm.getInputProps("email")}
                    data-autofocus
                    id="email_input"
                    classNames={{ input: "custom-input" }}
                    styles={{
                      input: {
                        fontFamily: "sans-serif !important",
                        // color: "red !important",
                      },
                    }}
                    radius={"sm"}
                    size={"md"}
                  />
                  {/* <div className="d-flex justify-content-between otpcontent">
                    <p>
                      Enter the OTP sent to you on your register email address.
                    </p>
                    <p>
                      <a href="#">Resend OTP</a>
                    </p>
                  </div> */}
                  {/* <AuthButtonContainer>
                    <AuthButton
                      type="submit"
                      style={{ marginTop: "28px" }}
                      id="sign_btn"
                    >
                      Send OTP
                    </AuthButton>
                  </AuthButtonContainer> */}
                  <div className="d-flex mbrow gap-20 justify-content-center">
                    <button type="submit" className="sign-btn">
                      Send OTP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Container = styled.div`
  background-image: url(${banner2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  min-height: 768px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #050505;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65%;
  }

  input {
    background: #ffffff;
    border: 1px solid #c7b277;
    border-radius: 5px;
    width: 450px;
    height: 50px;
    font-family: "Roboto-Light";
    color: #050505;
    font-size: 18px;

    ::placeholder {
      color: #050505;
    }
  }

  .mantine-y0fwdk,
  .mantine-wqgzfp {
    width: 450px;
    height: 65px !important;
    margin-bottom: 15px;
  }
  .mantine-o3oqoy {
    bottom: 25px;
  }

  .mantine-wqgzfp {
    border: none;
    background: none;
  }

  @media (max-width: 576px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 400px;
      height: 55px !important;
      margin-bottom: 15px;
    }

    input {
      width: 400px;
      height: 55px !important;
    }
  }
  @media (max-width: 424px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 370px;
      height: 45px !important;
      margin-bottom: 15px;
    }

    input {
      width: 370px;
      height: 45px !important;
    }
  }
  @media (max-width: 376px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 290px;
      height: 40px !important;
      margin-bottom: 15px;
    }

    input {
      width: 290px;
      height: 40px !important;
    }
  }
`;

export default RequestResetPassword;
