import { useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import styled from "styled-components";
import banner from "../../assets/banner.png";
import facebook from "../../assets/Facebook.png";
import google from "../../assets/google.png";
import NavBar from "../common/NavBar";
import { AuthButton } from "../common/styles";

const Home = () => {
  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate(Pages.Login);
  };

  const goToRegisterPage = () => {
    navigate(Pages.Register);
  };

  return (
    <>
      <Container>
        <NavBar />
        <AuthContainer>
          <AuthHeader>
            Excellent aviation <br /> training programmes.
          </AuthHeader>
          <AuthSubHeader>A comprehensive e-learning platform</AuthSubHeader>
          <AuthButton
            type="button"
            onClick={goToLoginPage}
            className="signInButton"
          >
            SIGN IN
          </AuthButton>
          <RegisterText>
            <span> Don't have an account.</span>&nbsp;
            <button
              type="button"
              onClick={goToRegisterPage}
              className="registerButton"
              style={{ textDecoration: "underline" }}
            >
              <b>Create Account</b>
            </button>
          </RegisterText>
          <IconContainer>
            <p>or sign in with </p>
            <LogoContainer>
              <img src={facebook} alt="facebook logo" />
              <img src={google} alt="google logo" />
            </LogoContainer>
          </IconContainer>
        </AuthContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  min-height: 768px;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 576px) {
    height: 70%;
    justify-content: center;
  }
`;

const LogoContainer = styled.div``;

const RegisterText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #062a3b;
  margin-top: 16px;
  margin-bottom: 50px;

  @media (max-width: 576px) {
    margin-bottom: 0;
  }

  button {
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
  }
`;
const AuthHeader = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  color: #062a3b;
  margin-bottom: 27px;

  @media (max-width: 576px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

const AuthSubHeader = styled.span`
  font-family: "Roboto-Light";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: #050505;
  margin-bottom: 50px;

  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 25px;
  }
`;

const IconContainer = styled.div`
  p {
    font-family: "Roboto-Light";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #050505;
  }
`;

export default Home;
