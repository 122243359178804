import { zodResolver, useForm } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import styled from "styled-components";
import banner from "../../assets/banner.png";
import NavBar from "../common/NavBar";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import { TextInput } from "@mantine/core";
import { ask } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { Pages } from "../../Pages";
import "./ScreenOtp"; // Adjust the path for your CSS file
import logoafmcart from "../../assets/logoafmcart.png"; // Adjust the path for your image
import homeIcon from "../../assets/homeicon.svg"; // Adjust the path for your image
import "bootstrap/dist/css/bootstrap.min.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const myName = "validate-email";

type ValidateEmailData = {
  otp: string;
};

const initialValues = {
  otp: "",
};

const DummyValidateMail = () => {
  const { email } = useParams();
  console.log("Email", email);

  const navigate = useNavigate();
  const validateEmailElement = useFocusTrap();

  // const validateEmailSchema = z.object({
  //   otp: z.string().min(4, { message: "OTP is Mandatory" }),
  // });

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const initialValues = {
    otp: "",
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    // Handle form submission here
    await emailVerification(values, email);
    console.log(values);
    resetForm();
  };

  const emailVerification = useCallback(
    async (values: ValidateEmailData, email: string | undefined) => {
      console.log("Email verification VALUES", values);
      if (email === undefined) navigate(Pages.PrivacyPolicy);

      const payloadObj = { email, otp: values?.otp };
      console.log("PAYLOAD OBJECT", payloadObj);

      try {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.ValidateEmail,
          payload: payloadObj,
        });
        // validationSchema.resetForm();
        console.log("RESULT AFTER SUBMITTING OTP", result);
        const userRole = result?.payload?.result.payload.user.role;
        if (userRole === "corporate-admin") {
          navigate(Pages.CompanyInfo);
        }
        console.log("this value id from result ok", userRole);
        // if (payload?.result.payload.user.role)
        if (result!.error) {
          console.log("Error from validate", result!.error);
        } else {
          console.log("No error", result!.error);
        }
        if (result!.error) {
          navigate(`${Pages.ValidateEmail}/${encodeURIComponent(email!)}`);
        } else {
          if (userRole === "corporate-admin") {
            navigate(Pages.CompanyInfo);
          } else {
            // navigate(Pages.Dashboard);
            navigate(Pages.ThankYou);
          }
        }
      } catch (err) {
        navigate(Pages.ValidateEmail);
      }
    },
    [navigate, validationSchema]
  );

  const cancelEmailVerification = useCallback(async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.CancelValidateEmail,
      });
      if (!result!.error) navigate(Pages.Login);
    } catch (err) {
      navigate(Pages.ValidateEmail);
    }
  }, [navigate]);

  const resendOtp = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.ResendOtp,
        payload: {
          email,
          requestType: myName,
        },
      });
      console.log(result);
      // if (!result!.error) navigate(Pages.Login);
    } catch (err) {
      navigate(Pages.ValidateEmail);
    }
  };

  return (
    // <Container>

    //   <NavBar />
    //   <form
    //     onSubmit={validateEmailForm.onSubmit((values: any) =>
    //       emailVerification(values, email)
    //     )}
    //     ref={validateEmailElement}
    //   >
    //     <FormHeader>
    //       Please Enter the otp to complete the registration
    //     </FormHeader>
    //     <TextInput
    //       placeholder="Enter OTP"
    //       {...validateEmailForm.getInputProps("otp")}
    //       data-autofocus
    //     />
    //     {/* <AuthButtonContainer>
    //       <AuthButton
    //         onClick={() => navigate(Pages.Login)}
    //         style={{ marginTop: "28px" }}
    //       >
    //         VERIFY OTP
    //       </AuthButton>

    //       <AuthButton
    //         type="button"
    //         onClick={() => navigate(Pages.Home)}
    //         style={{ marginTop: "28px" }}
    //       >
    //         BACK
    //       </AuthButton>
    //     </AuthButtonContainer> */}
    //     <AuthButtonContainer>
    //       <AuthButton type="submit" style={{ marginTop: "28px" }}>
    //         VERIFY OTP
    //       </AuthButton>

    //       <AuthButton
    //         type="button"
    //         onClick={() => cancelEmailVerification()}
    //         style={{ marginTop: "28px" }}
    //       >
    //         BACK
    //       </AuthButton>
    //     </AuthButtonContainer>
    //   </form>
    // </Container>
    <>
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logoafmcart} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a href="#">
                <img src={homeIcon} alt="Home" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="titleofform">
                <h3>Enter the otp to complete the registration</h3>
              </div>
              <div className="otpmain">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form className="createform">
                    <div className="d-flex mbrow gap-20 justify-content-between">
                      <div className="formfield">
                        <Field
                          type="text"
                          name="otp"
                          className="form-control"
                          placeholder="Enter OTP"
                        />
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between otpcontent">
                      <p>
                        Enter the OTP sent to you on your register email
                        address.
                      </p>
                      <p style={{ cursor: "pointer" }} onClick={resendOtp}>
                        Resend OTP
                      </p>
                    </div>
                    <div className="d-flex mbrow gap-20 justify-content-between">
                      <button type="submit" className="sign-btn otpbtn">
                        Verify OTP
                      </button>
                      <button type="button" className="sign-btn otpbtn">
                        Back
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background-image: url(${banner});
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 768px;
//   overflow-y: scroll;

//   form {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 65%;
//   }

//   input {
//     background: #ffffff;
//     border: 1px solid #c7b277;
//     border-radius: 5px;
//     width: 450px;
//     height: 50px;
//     font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
//     color: #050505;
//     font-size: 18px;

//     ::placeholder {
//       color: #050505;
//     }
//   }

//   .mantine-y0fwdk,
//   .mantine-wqgzfp {
//     width: 450px;
//     height: 65px !important;
//     margin-bottom: 15px;
//   }
//   .mantine-o3oqoy {
//     bottom: 25px;
//   }

//   .mantine-wqgzfp {
//     border: none;
//     background: none;
//   }

//   @media (max-width: 576px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 400px;
//       height: 55px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 400px;
//       height: 55px !important;
//     }
//   }
//   @media (max-width: 424px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 370px;
//       height: 45px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 370px;
//       height: 45px !important;
//     }
//   }
//   @media (max-width: 376px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 290px;
//       height: 40px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 290px;
//       height: 40px !important;
//     }
//   }
// `;

// const FormHeader = styled.div`
//   font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
//   font-style: normal;
//   font-weight: 300;
//   font-size: 24px;
//   line-height: 26px;
//   text-align: center;
//   letter-spacing: 0.01em;
//   text-transform: uppercase;
//   color: #050505;
//   margin-bottom: 30px;

//   @media (max-width: 576px) {
//     font-size: 20px;
//     line-height: 32px;
//   }
// `;

export default DummyValidateMail;
