import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Congratulations.css";
import { Pages } from "../../Pages";
import { ask } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { useEffect, useState } from "react";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import wingimg from "../../assets/Images/wing-img.png";

import styled from "@emotion/styled";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
const myName = "Dashboard-gui";

const CertificateView = () => {
  const { courseId } = useParams();
  console.log("COURSE ID FROM ASSESSMENT", courseId);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [err, setErr] = useState("");
  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Congratulations);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const handleClick = () => {
    navigate(Pages.Congratulations);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0 mytogclass">
            <div className="toggleitem">
              <div className="custombar">
                <span className="custom_bar"></span>
                <span className="custom_bar"></span>
                <span className="custom_bar"></span>
              </div>
              <div className="closbar">
                <span className="close_bar1"></span>
                <span className="close_bar2"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0 mymainparthtml">
            <CoursePlayerSidebar />
            <div className="mainbody">
              <div>
                <img src={wingimg} alt="" className="wing-img" />
                <h1 className="cong">CONGRATULATION!</h1>
                <p className="para-p">you have cleared the course</p>
                <button
                  className="down-certificate-btn"
                  onClick={() => navigate(Pages.CertificateList)}
                >
                  DOWNLOAD CERTIFICATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 p-0">
        <footer>
          <div className="footertext">
            <div>
              {/* <p>© Copyright 2022 Aviation Mastermind. All Rights Reserved.</p> */}
              <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
            </div>
            <div className="quicklinks">
              <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default CertificateView;
