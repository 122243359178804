import React, { useRef, useState } from "react";
import "./VideoPlayer.css";

interface VideoPlayerProps {
  videoSrc: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoError = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    console.error("Video playback error:", event.nativeEvent);
  };
  const pauseButtonText = isPlaying ? (
    ""
  ) : (
    <img
      src="/Landing-page-video/video-play-button.png"
      className="play-pause-button-icon"
      alt=""
    />
  );
  return (
    <>
      <div className="video-container">
        <video
          ref={videoRef}
          width="600"
          src={videoSrc}
          //   poster={thumbnailSrc}
          //   controls={true}
          onClick={togglePlayPause}
          onError={handleVideoError} // Add error handler
        >
          Your browser does not support the video tag.
        </video>
        {/* <button className="play-pause-button" onClick={togglePlayPause}>
          {isPlaying ? "Pause" : "Play"}
        </button> */}
        {!isPlaying && (
          <button className="play-pause-button" onClick={togglePlayPause}>
            {pauseButtonText}
          </button>
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
