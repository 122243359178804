import { useLocation } from "react-router";

const TermsAndConditions = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  console.log("id from use location", id);
  return <>Terms&Conditions</>;
};

export default TermsAndConditions;
