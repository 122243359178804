//---------------------------------------------------------------------------------
//
// course/CourseClient.ts
//
//---------------------------------------------------------------------------------

import {
  ask,
  Dictionary,
  getData,
  KeysAndValues,
  Message,
  MessageResponse,
  StatefulActor,
  StatefulActorErrorState,
  StatefulActorInstance,
  StatefulActorStateEntry,
  StatefulActorStateEvent,
  StatefulActorStateInstance,
} from "lib-js-c-modulo/index.mjs";
import { LocalActorConfig } from "../../modulo/types";
import {
  //   AuthClient_Data,
  //   AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
  CompanyAdminClient_States,
  CompanyAdminService_Name,
  //   AuthService_Name,
  CourseClient_Data,
  //   CourseClient_Messages,
  CourseClient_Name,
  //   CourseClient_States,
  CourseService_Name,
} from "./types";
import { addSessionToken } from "../auth/AuthClient";

//---------------------------------------------------------------------------------

const defaultData: () => CourseClient_Data = () => ({
  courseList: [],
  topicList: [],
  moduleList: [],
});

//---------------------------------------------------------------------------------

class CompanyAdminClient_State_Start extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    this.actor.gotoState(CompanyAdminClient_States.NotAuthenticated, input);
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [`${AuthClient_Name}/changed`]: async (input?: KeysAndValues) => {
      const authState = input?.event?.payload?.state;
      const runClientState = this.actor.state;
      switch (authState) {
        case AuthClient_States.NotAuthenticated:
          console.log("COURSE CLIENT: NOT AUTHENTICATED");
          this.actor.gotoState(
            CompanyAdminClient_States.NotAuthenticated,
            defaultData()
          );
          return;
        case AuthClient_States.Authenticated:
          console.log("COURSE CLIENT: AUTHENTICATED");
          if (runClientState === CompanyAdminClient_States.NotAuthenticated)
            this.actor.gotoState(
              CompanyAdminClient_States.Authenticated,
              defaultData()
            );
          return;
        default:
          // Do nothing
          return;
      }
    },
  };
}

//---------------------------------------------------------------------------------

//---------------------------------------------------------------------------------

class CompanyAdminClient_State_NotAuthenticated extends StatefulActorStateInstance {}
class CompanyAdminClient_State_Authenticated extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    const init = async () => {
      try {
        this.actor.setState(CompanyAdminClient_States.WIP);

        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          //   ...result!.payload,
        });
      } catch (error: any) {
        this.actor.gotoState(CompanyAdminClient_States.Err, {
          error: error.message,
        });
      }
    };
    init();
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [CompanyAdminClient_Messages.TestMessage]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK ASSESSMENT RESULT", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          message: CompanyAdminClient_Messages.TestMessage,
          // payload: {
          //   abdulNameToBackendSecond: input!.abdulNameSecond,
          // },
          payload: addSessionToken({
            companyAdminClient: input!.test,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("CHECK ASSESSMENT RESULT", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },

    [CompanyAdminClient_Messages.AddUpdateCompanyInfo]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("input come from add update components", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: addSessionToken({
            // firstValue: input!.firstInputValue,
            // secondValue: input!.secondInputValue,
            addCompany: input!.addCompany,
            addCompanyInfo: input!.addCompanyInfo,
          }),
          message: CompanyAdminClient_Messages.AddUpdateCompanyInfo,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("add update components", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.CompanyInfoGet]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the values are coming from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.CompanyInfoGet,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.ExcellFileUpload]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("input come from excell", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: addSessionToken({
            excellValue: input!.excellValue,
          }),
          message: CompanyAdminClient_Messages.ExcellFileUpload,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("input value from excell", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.FetchData]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the values are coming from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.FetchData,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.UserListDownloadExcell]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the user list downloaded from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.UserListDownloadExcell,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.DownloadSampleExcel]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the user list downloaded from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.DownloadSampleExcel,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.SubscriptionStatus]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the user list downloaded from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.SubscriptionStatus,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
    [CompanyAdminClient_Messages.GetMyCompanyUsers]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("the user list downloaded from database", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: addSessionToken({}),
          message: CompanyAdminClient_Messages.GetMyCompanyUsers,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("the values are coming from database", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },

    [CompanyAdminClient_Messages.CertificateDownload]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("input come from add update components", input);
        this.actor.setState(CompanyAdminClient_States.WIP);
        const result = await ask({
          from: CompanyAdminClient_Name,
          to: CompanyAdminService_Name,
          payload: {},
          message: CompanyAdminClient_Messages.CertificateDownload,

          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("add update components", result);
        const returnValue = result!.payload;
        this.actor.setState(CompanyAdminClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log(error);
      }
    },
  };
}

//---------------------------------------------------------------------------------

//---------------------------------------------------------------------------------

export function createCompanyAdminClientActor(
  config: LocalActorConfig
): StatefulActor {
  const actor = new StatefulActorInstance(config.name, defaultData(), false);
  actor.setAllStates([
    new CompanyAdminClient_State_Start(
      CompanyAdminClient_States.Start,
      true,
      actor
    ),
    new StatefulActorStateInstance(CompanyAdminClient_States.WIP, true, actor),
    new CompanyAdminClient_State_NotAuthenticated(
      CompanyAdminClient_States.NotAuthenticated,
      false,
      actor
    ),
    new CompanyAdminClient_State_Authenticated(
      CompanyAdminClient_States.Authenticated,
      false,
      actor
    ),
    new StatefulActorErrorState(
      CompanyAdminClient_States.Err,
      actor,
      CompanyAdminClient_States.Authenticated,
      {}
    ),
  ]);
  actor.listenToActors([AuthClient_Name], true);
  return actor;
}

// ---------------------------------------------------------------------------------
