//---------------------------------------------------------------------------------
//
// modulo/modulo.ts
//
//---------------------------------------------------------------------------------

import {
  KeysAndValues,
  StatefulActor,
  StatefulActorData,
  createRestProxy,
  createStubActor,
  registerActor,
  setLogger,
  setModuloId,
} from "lib-js-c-modulo/index.mjs";
import { ActorType, AppEnvironment, InitModuloParameters } from "./types";

//---------------------------------------------------------------------------------

export const initModulo = async (params: InitModuloParameters) => {
  let { config, saveActorData, loadActorData, logger } = params;

  // Initialise
  setModuloId(config.moduleId);

  if (!saveActorData)
    saveActorData = (name, data) => {
      localStorage.setItem(`${config.moduleId}:${name}`, JSON.stringify(data));
    };

  if (!loadActorData)
    loadActorData = (name: string) =>
      localStorage.getItem(`${config.moduleId}:${name}`);

  if (!logger)
    logger = (logMessage: KeysAndValues, category?: string) => {
      if ((logMessage as any)?.type === "state-change")
        console.log(category, logMessage);
    };
  setLogger(logger);

  // Create actors
  let actors: Array<StatefulActor> = config.actors.map((actorConfig) => {
    switch (actorConfig.type) {
      case ActorType.LOCAL:
        console.log("CREATING ACTOR:", actorConfig.name);
        return actorConfig.constructor(actorConfig);
      case ActorType.REMOTE:
        return createRestProxy(actorConfig.name, config.moduloApiEndPoint);
      case ActorType.STUB:
      default:
        if (
          config.env === AppEnvironment.PROD ||
          config.allowStubsIn.indexOf(config.env) < 0
        )
          throw new Error("Stub actors are not allowed in this environment");
        return createStubActor(
          actorConfig.name,
          actorConfig.constructor(actorConfig)
        );
    }
  });

  // Start actors
  actors.forEach((actor) => {
    registerActor(actor);
    // TODO: Check app version and environment and skip state loading if required
    actor.setSaveActorHandler(saveActorData!);
    if (!loadActorData) actor.start();
    else {
      const currentData = loadActorData(actor.name);
      if (currentData) {
        actor.start(JSON.parse(currentData) as StatefulActorData);
      } else actor.start();
    }
  });

  return true;
};

//---------------------------------------------------------------------------------
