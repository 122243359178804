import { Pages } from "./Pages";
import { SubscriptionType } from "./actors/subscription/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useSubscriptionNavigation = () => {
  const navigate = useNavigate();

  const subscriptionNavigation = (
    course: any,
    subscriptionType: SubscriptionType
  ) => {
    console.log(
      "Effect course is and subscription type",
      course,
      subscriptionType
    );
    navigate(`${Pages.ShoppingCart}/${subscriptionType}`, { state: course });
  };

  return subscriptionNavigation;
};

// const dateString = '2024-04-22T07:41:21.111Z';

// Function to format the date into a more readable form without time
export function formatDate(dateString: string) {
  // Array of month names to convert month number to month name
  const date = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extracting parts of the date
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth(); // getUTCMonth returns a zero-based index
  const year = date.getUTCFullYear();

  // Format the date string
  return `${monthNames[monthIndex]} ${day}, ${year}`;
}
