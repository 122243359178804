import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Pages } from "../../Pages";
import { KeysAndValues, ask, getData } from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import { useEffect, useState } from "react";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
import Loading from "../common/Loading";

const myName = "Dashboard-gui";

const DummyAssessment = () => {
  const { courseId } = useParams();
  console.log("COURSE ID FROM ASSESSMENT", courseId);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [err, setErr] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  useEffect(() => {
    const getAssessment = async () => {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { courseId },
          message: CourseClient_Messages.getAssessment,
        });
        console.log(
          "RESULT FROM ASSESSMENT PAGE",
          result?.payload?.resultObject
        );
        setQuestions(result?.payload?.resultObject);
      } catch (error) {
        console.log("ERROR IS", error);
      }
      setLoading(false);
    };
    getAssessment();
  }, []);

  const handleFormSubmit = async (event: any) => {
    setErr("");
    event.preventDefault();
    console.log("FORM SUBMISSION", event);
    const form = event.target;
    const formData = new FormData(form);

    console.log("FORM SUBMISSION");
    const entries = Array.from(formData.entries());
    let submittedAnswers = [];
    for (let [name, value] of entries) {
      console.log(name, value);
      submittedAnswers.push(value);
    }
    console.log("SUBMITTED ANSWERS AND COURSE", submittedAnswers, courseId);

    if (submittedAnswers.length === questions.length) {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { submittedAnswers, courseId },
          message: CourseClient_Messages.CheckAssessmentResult,
        });
        const assessmentResult = result?.payload?.returnValue;
        console.log("RESULT FROM CHECK ASSESSMENT RESULT", assessmentResult);
        if (
          assessmentResult?.pass === true &&
          assessmentResult?.certificate === true
        ) {
          console.log("NAVIGATE TO CONGRATULATIONS");
          navigate(Pages.Congratulations);
        }
        if (
          assessmentResult?.pass === false &&
          assessmentResult?.certificate === false
        ) {
          console.log("NAVIGATE TO TRY AGAIN");
          navigate(`${Pages.AssessmentTryAgain}/${courseId}`);
        }
      } catch (error) {
        console.log("ERROR IS", error);
      }
      setErr("All questions are answered");
    } else {
      setErr("Please answer all the questions");
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0 mytogclass">
              <div className="toggleitem">
                <div className="custombar">
                  <span className="custom_bar"></span>
                  <span className="custom_bar"></span>
                  <span className="custom_bar"></span>
                </div>
                <div className="closbar">
                  <span className="close_bar1"></span>
                  <span className="close_bar2"></span>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0 mymainparthtml">
              {/* <!-- leftmenu Blue Part --> */}
              <CoursePlayerSidebar
                sessionId={null}
                courseId={courseId}
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
                page={Pages.Assessment}
              />
              {/* <div className="sidebar">
            <div className="innersider">
              <img className="avilogo" src={avilogo} alt="Logo" />
              <div className="username">
                <div className="usericon">
                  <img src={userIcon} alt="User Icon" />
                </div>
                <div className="usertext">
                  <h4>{fullName}</h4>
                  <h6>
                    <span>Status:</span> <span className="Active">Active</span>{" "}
                    <span>/ Plan:</span> Free
                  </h6>
                </div>
              </div>
              <div className="viewprofile">
                <button
                  onClick={() => navigate(Pages.Profile)}
                  className="goldenbtn"
                >
                  View Profile
                </button>
              </div>
              <div className="processbar">
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: "10%", backgroundColor: "#C7B277" }}
                    role="progressbar"
                    aria-valuenow={10}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <span>1% Complete</span>
              </div>
              {/* <div className="buttonmargin">
                <button
                  className="goldenbtn"
                  // onClick={() => setMenuLinks(true)}
                >
                  COURSE OUTLINE
                </button>
              </div> */}
              {/* <div className="quickmenulinks">
                <h2>MENU</h2>
                <ul>
                  <li>
                    <a href="" onClick={() => navigate(Pages.Others)}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(Pages.Notes)}>
                      Notes
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(Pages.Bookmarks)}>
                      BOOKMARKS
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(Pages.CertificateList)}>
                      View Certificate
                    </a>
                  </li>
                  <li>
                    <a href="">Help</a>
                  </li>
                  <li onClick={doLogout}>
                    <a href="#">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
              {/* <!-- End leftmenu Blue Part --> */}
              {/* <!-- start White Part --> */}
              <div className="mainbody">
                <div className="">
                  {/* <!-- Start sticky icon --> */}
                  {/* <div className="stikeybutton">
                <div className="sitckyinner">
                  <div>
                    <button className="stikeybuttons addbtn">
                      <img src={addNote} />
                      Add notes
                    </button>
                  </div>
                  <div>
                    <button className="stikeybuttons bookbtn">
                      <img src={bookMark} />
                      BOOKMARK
                    </button>
                  </div>
                </div>
              </div> */}
                  {/* <!--End  sticky icon --> */}
                  <div className="contentpart">
                    <div className="titlehead">
                      <h2>ASSESSMENT</h2>
                      <h4>ALL QUESTIONS ARE MANDATORY.</h4>
                    </div>
                    {questions ? (
                      <form onSubmit={handleFormSubmit}>
                        <div className="quediv">
                          <div className="queanswer">
                            {questions?.map((question: any, index: any) => (
                              <>
                                <p className="mt-0">
                                  {parseInt(index) + 1}. {question.question}
                                </p>
                                {Object.entries(question.choices).map(
                                  ([choiceNumber, choiceText]) => (
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`question-${question.index}`}
                                        id="flexRadioDefault1"
                                        value={parseInt(choiceNumber) + 1}
                                        onClick={(
                                          e: React.MouseEvent<HTMLInputElement>
                                        ) =>
                                          console.log(e?.currentTarget?.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        key={choiceNumber}
                                      >
                                        {choiceText as string}
                                      </label>
                                    </div>
                                  )
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                        <div className="finishsection text-end mt-4">
                          <button type="submit" className="goldenbtn whitetext">
                            Submit Assessment
                          </button>
                        </div>
                      </form>
                    ) : (
                      <p>Loading Assessment data...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer part --> */}
          <div className="col-md-12 p-0">
            <footer>
              <div className="footertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>
  );

  // return (
  //   <>
  //     <button type="button" onClick={doLogout}>
  //       Logout(OG)
  //     </button>
  //     <div>
  //       <h1>Assessment</h1>
  //       {err && <h2 style={{ color: "red" }}>{err}</h2>}
  //       <div>
  //         <div>
  //           {questions ? (
  //             <form onSubmit={handleFormSubmit}>
  //               <div>
  //                 {questions.map((question: any, index: any) => (
  //                   <div key={index}>
  //                     <p>
  //                       {parseInt(index) + 1}. {question.question}
  //                     </p>
  //                     {Object.entries(question.choices).map(
  //                       ([choiceNumber, choiceText]) => (
  //                         <label style={{ padding: "10px" }} key={choiceNumber}>
  //                           <input
  //                             type="radio"
  //                             name={`question-${question.index}`}
  //                             value={parseInt(choiceNumber) + 1}
  //                             onClick={(
  //                               e: React.MouseEvent<HTMLInputElement>
  //                             ) => console.log(e?.currentTarget?.value)}
  //                           />
  //                           {choiceText as string}
  //                         </label>
  //                       )
  //                     )}
  //                   </div>
  //                 ))}
  //               </div>
  //               <button type="submit">Submit</button>
  //             </form>
  //           ) : (
  //             <p>Loading quiz data...</p>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default DummyAssessment;
