import React from "react";
import "./NavbarCompanydasBoard.css";
import userfaceicon from "../../assets/Images/userfaceicon.svg";
import logouticon from "../../assets/Images/logouticon.svg";
import leftarrowicon from "../../assets/Images/leftarrowicon.svg";
import logoafmcart from "../../assets/Images/logoafmcart.png";
import "../dummy/css/bootstrap.min.css";

const NavbarCompanydasBoard = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="bluepanel"></div>
            <div className="goldenmenu">
              <nav className="navbar navbar-expand-xl">
                <div className="container-fluid p-0">
                  <a className="user-dashboard">Corporate User Dashboard</a>
                  <div className="d-flex justify-content-between mymobieltop">
                    <div className="dnonelogo">
                      <span className="threedot">
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </div>
                    <div className="dnonelogo">
                      <img className="centerlgoo" src={logoafmcart} />
                    </div>
                    <div>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="collapse navbar-collapse goldennav"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto  mb-lg-0">
                      <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#">
                          <img className="me-2" src={leftarrowicon} />
                          Back to Dashboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <img className="me-2" src={userfaceicon} />
                          Arron Lee
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <img className="me-2" src={logouticon} /> Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarCompanydasBoard;
