import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Pages } from "./Pages";
import { addMinutes } from "lib-js-c-utils/index.mjs";
import { Route, Routes } from "react-router-dom";

import {
  ActorEmittedEvent,
  ask,
  listenTo,
  stopListeningTo,
} from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
  UserRole,
  UserState,
} from "./actors/auth/types";
import Home from "./views/auth/Home";
import Login from "./views/auth/Login";
// import Register from "./views/auth/Register";
import ErrorMessage from "./views/common/ErrorMessage";
import TermsAndConditions from "./views/auth/TermsAndConditions";
import PrivacyPolicy from "./views/auth/PrivacyPolicy";
import RequestResetPassword from "./views/auth/RequestResetPassword";
import ResetPassword from "./views/auth/ResetPassword";
// import ThankYou from "./views/auth/ThankYou";
import ThankYousetPass from "./views/auth/ThankYousetPass";
import Dashboard from "./views/Dashboard";
import Loading from "./views/common/Loading";
import DummyHome from "./views/dummy/Home";
import DummyRegister from "./views/dummy/Register";
import DummyLogin from "./views/dummy/Login";
import DummyValidateMail from "./views/dummy/ValidateEmail";
import DummyDashboard from "./views/dummy/DummyDashboard";
import DummyCoursePlayer from "./views/dummy/DummyCoursePlayer";
import DummyAssessment from "./views/dummy/DummyAssesment";
import DummyTryAgain from "./views/dummy/DummyTryAgain";
import DummyCertificate from "./views/dummy/DummyCertificate";
import DummyCourseLanding from "./views/dummy/DummyCourseLanding";
import DummyQuizPlayer from "./views/dummy/DummyQuizPlayer";
import { CourseClient_Name, CourseClient_States } from "./actors/course/types";
import LandingPage from "./views/dummy/WebsiteLanding";
import Congratulations from "./views/dummy/Congratulations";
import AssessmentTryAgain from "./views/dummy/AssessmentTryAgain";
import Notes from "./views/dummy/Notes";
import Bookmarks from "./views/dummy/BookMarks";
import CertificateList from "./views/dummy/CertificateList";
import ShoppingCart from "./views/dummy/ShoppingCart";
import PaymentGateway from "./views/dummy/PaymentGateway";
import PaymentFailure from "./views/dummy/PaymentFailure";
import PaymentSuccess from "./views/dummy/PaymenySuccess";
import UserProfile from "./views/dummy/UserProfile";
import CompanyDashboard from "./views/dummy/CompanyDashboard";
import EditCompanyInfo from "./views/dummy/EditCompanyInfo";

import AddCompanyUsers from "./views/dummy/AddCompanyUsers";

import CorporateAdminRoute from "./views/dummy/CorporateAdminRoute";
import PaymentSuccessStatic from "./views/dummy/PaymentSuccessStatic";
// import DownloadSamplebox from "./views/dummy/StripePaymentGateway";
import StripePaymentGateway from "./views/dummy/StripePaymentGateway";
import CompanyInfo from "./views/dummy/CompanyInfo";

import NavbarCompanydasBoard from "./views/common/NavbarCompanydasBoard";
import CompanyAdminSidebar from "./views/common/CompanyAdminSidebar";
import CompanyCommon from "./views/common/CompanyCommon";
import DownloadSamplebox from "./views/dummy/DownloadSamplebox";
// import SidebarComponent from "./views/dummy/SidebarComponent";
import UpdateCompanyName from "./views/dummy/UpdateCompanyName";
import UserdasBoard from "./views/dummy/UserdasBoard";
import UserdasDetail from "./views/dummy/UserdasDetail";
import UserdetailsSubsstatus from "./views/dummy/UserdetailsSubsstatus";
import CompanyUsersReport from "./views/dummy/CompanyUsersReport";
// import Forms from "./views/dummy/Forms";
import ScreenOtp from "./views/dummy/ScreenOtp";
import NewForgotPassword from "./views/dummy/NewForgotPassword";
import SetNewPassword from "./views/dummy/SetNewPassword";
import CoursePlayerSidebar from "./views/common/CoursePlayerSidebar";
import ThankYou from "./views/auth/ThankYou";
import LoginErrorMassage from "./views/dummy/LoginErrorMessage";
import { any } from "zod";
// import Hello from "./views/dummy/Hello";

function App(): JSX.Element {
  // AuthClient State Changes
  const [authClientState, setAuthClientState] = useState<AuthClient_States>(
    AuthClient_States.Start
  );
  const [authClientError, setAuthClientError] = useState("");
  const [isCorporateAdmin, setIsCorporateAdmin] = useState(false);
  const [isActiveCorporateAdmin, setIsActiveCorporateAdmin] = useState(false);
  const [isSubscriptionEnded, setIsSubscriptionEnded] = useState(false);

  const authClientStateChanged = useCallback((event: ActorEmittedEvent) => {
    console.log("Event from usecallback", event);

    const authClientLocalStorage = localStorage.getItem(
      "afm-client:auth-client"
    );
    if (authClientLocalStorage) {
      console.log("type, value", typeof authClientLocalStorage,JSON.parse(authClientLocalStorage));
      const jsonAuthClientLocalStorage = JSON.parse(authClientLocalStorage);
      // CHECK WHETHER USER STATE IS ON TRIAL OR NOT ALSO FOR ROUTE PROTECTION
      if (
        jsonAuthClientLocalStorage?.user?.role === UserRole.CorporateAdmin &&
        jsonAuthClientLocalStorage?.user?.state === UserState.Active
      ) {
        setIsCorporateAdmin(true);
        setIsActiveCorporateAdmin(true);
      } else if (
        jsonAuthClientLocalStorage?.user?.role === UserRole.CorporateAdmin &&
        jsonAuthClientLocalStorage?.user?.state !== UserState.Active
      ) {
        setIsActiveCorporateAdmin(false);
      }

      if (jsonAuthClientLocalStorage?.navigationObject?.subscriptionEnded) {
        setIsSubscriptionEnded(true);
      }
    }

    setAuthClientState(event.payload.state);
    if (event.payload.error) setAuthClientError(event.payload.error);
  }, []);

  useEffect(() => {
    listenTo(AuthClient_Name, authClientStateChanged);

    return () => {
      stopListeningTo(AuthClient_Name, authClientStateChanged);
    };
  }, [authClientStateChanged]);

  // CourseClient State Changes
  const [courseClientState, setCourseClientState] =
    useState<CourseClient_States>(CourseClient_States.Start);
  const [courseClientError, setCourseClientError] = useState("");

  const courseClientStateChanged = useCallback((event: ActorEmittedEvent) => {
    console.log("Event from usecallback", event);

    setCourseClientState(event.payload.state);
    if (event.payload.error) setCourseClientError(event.payload.error);
  }, []);

  useEffect(() => {
    listenTo(CourseClient_Name, courseClientStateChanged);
    return () => {
      stopListeningTo(CourseClient_Name, courseClientStateChanged);
    };
  }, [courseClientStateChanged]);

  // Corporate admin route protection

  const renderApp = () => {
    switch (authClientState) {
      case AuthClient_States.NotAuthenticated:
        return (
          <Routes>
            {/*-------------------- NEW ADDITIONS ---------------------- */}
            <Route
              path={Pages.NewForgotPassword}
              element={<NewForgotPassword />}
            ></Route>

            <Route
              path={Pages.SetNewPassword}
              element={<SetNewPassword />}
            ></Route>
            <Route path={Pages.ScreenOtp} element={<ScreenOtp />}></Route>
            <Route path={Pages.Others} element={<LandingPage />}></Route>
            <Route path={Pages.Landing} element={<LandingPage />}></Route>
            <Route path={Pages.Home} element={<DummyHome />}></Route>
            <Route path={Pages.Login} element={<DummyLogin />}></Route>
            <Route path={Pages.Register} element={<DummyRegister />}></Route>
            <Route
              path={`${Pages.ValidateEmail}/:email`}
              element={<DummyValidateMail />}
            ></Route>

            {/*-------------------- NEW ADDITIONS ---------------------- */}
            <Route
              path={Pages.Congratulations}
              element={<Congratulations />}
            ></Route>
            <Route
              path={Pages.TermsAndConditions}
              element={<TermsAndConditions />}
            ></Route>
            <Route
              path={Pages.PrivacyPolicy}
              element={<PrivacyPolicy />}
            ></Route>
            <Route
              path={Pages.RequestResetPassword}
              element={<RequestResetPassword />}
            ></Route>
            <Route
              path={Pages.ResetPassword}
              element={<ResetPassword />}
            ></Route>
            <Route
              path={Pages.ThankYousetPass}
              element={<ThankYousetPass />}
            ></Route>
            <Route
              path={Pages.AssessmentTryAgain}
              element={<AssessmentTryAgain />}
            ></Route>
            {/* DUMMY ROUTES */}
            <Route path="/dummy-home" element={<Home />}></Route>
            {/* <Route path="/dummy-register" element={<Register />}></Route> */}
            <Route path="/dummy-login" element={<Login />}></Route>
            <Route
              path="/dummy-dashboard"
              element={
                <DummyDashboard
                  isActiveCorporateAdmin={isActiveCorporateAdmin}
                />
              }
            ></Route>
            <Route path="/dummy-player" element={<DummyCoursePlayer />}></Route>
            <Route path="/dummy-try-again" element={<DummyTryAgain />}></Route>
            <Route
              path="/dummy-certificate"
              element={<DummyCertificate />}
            ></Route>
            <Route
              path="/dummy-assessment"
              element={<DummyAssessment />}
            ></Route>
            <Route
              path="/dummy-validate"
              element={<DummyValidateMail />}
            ></Route>
            {/* DUMMY ROUTES */}
          </Routes>
        );
      // {/* ------------------------------- Validate Mail State --------------------------------------- */}

      case AuthClient_States.ValidateEmail:
        return (
          <Routes>
            <Route
              path={`${Pages.ValidateEmail}/:email`}
              element={<DummyValidateMail />}
            ></Route>
          </Routes>
        );

      // {/* ------------------------------ Authenticated State ---------------------------------------- */}

      case AuthClient_States.Authenticated:
        return (
          <Routes>
            <Route
              path={`${Pages.ValidateEmail}/:email`}
              element={<DummyValidateMail />}
            ></Route>
            <Route
              path={Pages.Others}
              element={
                <DummyDashboard
                  isActiveCorporateAdmin={isActiveCorporateAdmin}
                />
              }
            ></Route>
            <Route path={Pages.ThankYou} element={<ThankYou />}></Route>
            <Route
              path={Pages.Dashboard}
              element={
                <DummyDashboard
                  isActiveCorporateAdmin={isActiveCorporateAdmin}
                />
              }
            ></Route>
            <Route
              path={Pages.Dashboard}
              element={
                <DummyDashboard
                  isActiveCorporateAdmin={isActiveCorporateAdmin}
                />
              }
            ></Route>
            <Route
              path={`${Pages.Player}/:courseId/:moduleId/:sessionId/:sessionName`}
              element={<DummyCoursePlayer />}
            ></Route>
            <Route
              path={`${Pages.QuizPlayer}/:courseId/:moduleId/:sessionId/:sessionName`}
              element={<DummyQuizPlayer />}
            ></Route>
            <Route
              path={`${Pages.CourseLanding}/:id`}
              element={<DummyCourseLanding />}
            ></Route>
            <Route
              path={Pages.Certificate}
              element={<DummyCertificate />}
            ></Route>
            <Route path={Pages.TryAgain} element={<DummyTryAgain />}></Route>
            <Route
              path={`${Pages.Assessment}/:courseId`}
              element={<DummyAssessment />}
            ></Route>
            <Route
              path={Pages.Congratulations}
              element={<Congratulations />}
            ></Route>
            <Route
              path={`${Pages.AssessmentTryAgain}/:courseId`}
              element={<AssessmentTryAgain />}
            ></Route>
            <Route
              path={`${Pages.Notes}/:courseId`}
              element={<Notes />}
            ></Route>
            <Route
              path={`${Pages.Bookmarks}/:courseId`}
              element={<Bookmarks />}
            ></Route>
            <Route
              path={`${Pages.CertificateList}/:courseId`}
              element={<CertificateList />}
            ></Route>
            <Route
              path={`${Pages.ShoppingCart}/:subscriptionType`}
              element={<ShoppingCart />}
            ></Route>
            <Route
              path={`${Pages.StripePaymentGateway}/:subscriptionType`}
              element={<StripePaymentGateway />}
            ></Route>
            <Route
              path={`${Pages.PaymentGateway}/:subscriptionType`}
              element={<PaymentGateway />}
            ></Route>
            <Route
              path={`${Pages.PaymentFailed}/:subscriptionType`}
              element={<PaymentFailure />}
            ></Route>
            <Route
              path={`${Pages.PaymentSuccess}/:subscriptionType`}
              element={<PaymentSuccess />}
            ></Route>
            <Route
              path={`${Pages.PaymentSuccessStatic}/:subscriptionType`}
              element={<PaymentSuccessStatic />}
            ></Route>
            <Route
              path={`${Pages.Profile}/:id`} // id is the courseId
              element={<UserProfile />}
            ></Route>
            <Route
              element={
                <CorporateAdminRoute isCorporateAdmin={isCorporateAdmin} />
              }
            >
              <Route
                path={`${Pages.CompanyDashboard}`}
                element={<CompanyDashboard />}
              ></Route>
              <Route
                path={`${Pages.EditInfo}`}
                element={<EditCompanyInfo />}
              ></Route>
            </Route>

            <Route
              element={
                <CorporateAdminRoute
                  isCorporateAdmin={isActiveCorporateAdmin}
                />
              }
            >
              <Route
                path={`${Pages.AddCompanyUsers}`}
                element={<AddCompanyUsers />}
              />
              <Route
                path={`${Pages.CompanyUsersReport}`}
                element={<CompanyUsersReport />}
              />
            </Route>
            {/* updated routes with common companyCommon */}
            <Route
              path={Pages.UpdateCompanyName}
              element={<CompanyCommon page={`${Pages.UpdateCompanyName}`} />}
            ></Route>
            <Route
              path={Pages.CompanyInfo}
              element={<CompanyCommon page={`${Pages.CompanyInfo}`} />}
            ></Route>
            <Route
              path={Pages.UserdasDetail}
              element={<CompanyCommon page={`${Pages.UserdasDetail}`} />}
            ></Route>
            <Route
              path={Pages.DownloadSamplebox}
              element={<CompanyCommon page={`${Pages.DownloadSamplebox}`} />}
            ></Route>
            <Route
              path={Pages.UserdasBoard}
              element={<CompanyCommon page={`${Pages.UserdasBoard}`} />}
            ></Route>
            <Route
              path={Pages.NavbarCompanydasBoard}
              element={<NavbarCompanydasBoard />}
            ></Route>
            <Route
              path={Pages.CompanyAdminSidebar}
              element={<CompanyAdminSidebar />}
            ></Route>
          </Routes>
        );

      // {/* ---------------------------------------------------------------------- */}

      case AuthClient_States.LoggingIn:
      case AuthClient_States.Registering:
      case AuthClient_States.LoggingOut:
      case AuthClient_States.Validating:
      case AuthClient_States.ValidatingEmail:
      case AuthClient_States.RequestingPasswordReset:
      case AuthClient_States.ResettingPassword:
      case AuthClient_States.CountryList:
        return <Loading />;
      case AuthClient_States.ValidateError:
      case AuthClient_States.LoginError:
      case AuthClient_States.RegisterError: case AuthClient_States.LogoutError: case AuthClient_States.ValidatingEmailError: case AuthClient_States.RequestingPasswordResetError:
      case AuthClient_States.ResettingPasswordError:
      case AuthClient_States.CountryListError:
        return <ErrorMessage error={authClientError} />;
    }
  };
  return <>{renderApp()}</>;
}

export default App;
