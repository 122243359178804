import {
  ActorEmittedEvent,
  ask,
  getData,
  KeysAndValues,
  listenTo,
  stopListeningTo,
} from "lib-js-c-modulo/index.mjs";
// import { Grid, makeStyles } from "@material-ui/core";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
// import "./DummyCourseLanding.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import {
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
  Topic,
} from "../../actors/course/types";
import { useCallback, useEffect, useState } from "react";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import Loading from "../common/Loading";

const myName = "Dashboard-gui";

const DummyCourseLanding = () => {
  const { id } = useParams();
  const location = useLocation();
  const myObject = location.state;
  console.log("COURSE ID FROM PARAMS", id);
  console.log("Result of my object from course landing", myObject);

  // const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentModuleId = queryParams.get("currentModuleId");

  console.log("currentModuleId:", currentModuleId);

  const navigate = useNavigate();

  const [topics, setTopics] = useState<any>([]);

  const courseData = getData(CourseClient_Name) as KeysAndValues as any;

  console.log("COURSE DATA", courseData);

  // const topics = courseData?.moduleList;

  console.log("topics", topics);

  // var currentModule: any;
  // var sessionsUnderModule: Topic[] = [];
  // var highestLocalOrder = 0;

  const [currentModule, setCurrentModule] = useState<any>({});
  const [sessionsUnderModule, setSessionsUnderModule] = useState<Topic[]>([]);
  const [highestLocalOrder, setHighestLocalOrder] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState("");

  const [loading, setLoading] = useState(false);
  const [currentModuleName, setCurrentModuleName] = useState("");

  // --------------------------------------------------------

  // ----------------------------------------------------------

  useEffect(() => {
    setLoading(true);
    console.log("test Use effect triggered");
    if (courseData?.state === CourseClient_States.Authenticated) {
      setTopics(courseData?.moduleList);
      setLoading(false);
    }
    if (myObject && !currentModuleId) {
      console.log("test Result If my object called");
      // highestLocalOrder = myObject.localOrder;
      setHighestLocalOrder(myObject?.localOrder);
      // currentModule = myObject;
      setCurrentModule(myObject);
      topics.map((topic: any) => {
        if (topic.topic.parentTopicId === myObject?.id) {
          // sessionsUnderModule.push(topic);
          // setSessionsUnderModule((prevSessions) => [...prevSessions, topic]);
          setSessionsUnderModule((prevSessions) => {
            // Check if there's a session with the same topic.name
            const index = prevSessions.findIndex(
              (session) => session.topic.name === topic.topic.name
            );

            if (index !== -1) {
              // If found, create a new array without the old session
              const updatedSessions = [...prevSessions];
              updatedSessions.splice(index, 1);

              // Add the new session
              return [...updatedSessions, topic];
            } else {
              // If not found, simply add the new session
              return [...prevSessions, topic];
            }
          });
        }
        console.log("test SESSIONS UNDER MODULE", sessionsUnderModule);
      });
    } else if (!currentModuleId) {
      console.log("test Result Else my object called");
      topics.map((topic: any) => {
        if (
          topic.topic.parentTopicId === null &&
          topic.status === "in-progress"
        ) {
          console.log("test MODULE NAME", topic.topic.name);
          if (topic.topic.localOrder > highestLocalOrder) {
            // highestLocalOrder = topic.topic.localOrder;
            setHighestLocalOrder(topic.topic.localOrder);
            // currentModule = topic.topic;
            setCurrentModule(topic?.topic);

            console.log("test HIGHEST LOCAL ORDER", highestLocalOrder);
          }
        }

        console.log("test CURRENT MODULE", currentModule);
      });
    } else if (currentModuleId) {
      topics.map((topic: any) => {
        if (topic.topic.id === currentModuleId) {
          setHighestLocalOrder(topic.topic.localOrder);
          setCurrentModule(topic.topic);
        }
      });
    }
    // setLoading(false);
  }, [courseData]);

  useEffect(() => {
    setLoading(true);
    setCurrentModuleName(currentModule?.name);
    topics.map((topic: any) => {
      if (topic.topic.parentTopicId === currentModule.id) {
        // sessionsUnderModule.push(topic);
        console.log("test Result topic map", topic);
        // setSessionsUnderModule((prevSessions) => [...prevSessions, topic]);
        setSessionsUnderModule((prevSessions) => {
          // Check if there's a session with the same topic.name
          const index = prevSessions.findIndex(
            (session) => session.topic.name === topic.topic.name
          );

          if (index !== -1) {
            // If found, create a new array without the old session
            const updatedSessions = [...prevSessions];
            updatedSessions.splice(index, 1);

            // Add the new session
            return [...updatedSessions, topic];
          } else {
            // If not found, simply add the new session
            return [...prevSessions, topic];
          }
        });
      }
      console.log("test SESSIONS UNDER MODULE", sessionsUnderModule);
    });

    const totalTopics = topics.length;
    const inProgressTopics = topics.filter(
      (topic: any) => topic.status === "in-progress"
    ).length;

    const percentageInProgress = (inProgressTopics / totalTopics) * 100;

    console.log(`Percentage of "in-progress" topics: ${percentageInProgress}%`);
    setProgressPercentage(percentageInProgress.toFixed(0).toString());
    setLoading(false);
  }, [currentModule]);

  //-----------------------------------------------------------
  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Home);
    }
  };

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { courseId: id },
          message: CourseClient_Messages.FetchModuleAndSession,
        });
        console.log("RESULT FROM DUMMY COURSE LANDING", result);

        // if (!result!.error) navigate(Pages.Home);
        if (!result!.error) {
          console.log("Error is", result?.error);
        }
      } catch (err) {
        console.log("Caught error", err);
        // navigate(Pages.Dashboard);
      }
    };
    fetchTopics();
  }, []);

  // const fullName = (
  //   getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  // ).fullName;

  // const navigate = useNavigate();

  const [menuLinks, setMenuLinks] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<Topic[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [leftMenuVisible, setLeftMenuVisible] = useState(true);
  const [leftSidebarVisible, setLeftSidebarVisible] = useState(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (menuLinks) {
      timeout = setTimeout(() => {
        const element = document.getElementById("mySidenav");
        if (element) {
          element.style.transform = "translateX(0)";
        }
      }, 100);
    } else {
      const element = document.getElementById("mySidenav");
      if (element) {
        element.style.transform = "translateX(-100%)";
      }
    }

    return () => clearTimeout(timeout);
  }, [menuLinks]);

  console.log("courseId", id);
  console.log("currentModule", currentModule);
  console.log("sessionsUnderModule", sessionsUnderModule);

  topics.sort(
    (
      a: { topic: { localOrder: number } },
      b: { topic: { localOrder: number } }
    ) => a?.topic?.localOrder - b?.topic?.localOrder
  );

  console.log("SORTED TOPICS", topics);

  const myName = "Dashboard-gui";

  const activateSubmenu = (topicId: any) => {
    if (!showSubMenu) {
      console.log("TOPIC ACTIVATION VALUE", topicId);
      setActiveSubMenu(topicId);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      sessions && setSubMenuItems(sessions);
      setShowSubMenu(true);
    } else if (subMenuItems && showSubMenu) {
      setActiveSubMenu(null);
      setShowSubMenu(false);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      if (
        sessions.every((element: any) =>
          subMenuItems.includes(element as never)
        )
      ) {
        setShowSubMenu(false);
      } else {
        sessions && setSubMenuItems(sessions);
        setShowSubMenu(true);
        setActiveSubMenu(topicId);
      }
    } else {
      setShowSubMenu(false);
      setActiveSubMenu(null);
    }
  };

  const navigateToQuiz = async (
    course: string,
    module: string,
    session: string,
    item: any
  ) => {
    console.log("COURSE, MODULE, SESSION", course, module, session);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { course, module, session, item },
        message: CourseClient_Messages.NavigateToQuiz,
      });
      console.log("RESULT FROM NAVIGATE QUIZ", result);
      navigate(
        `${Pages.QuizPlayer}/${course}/${module}/${session}/${session}`,
        {
          state: item,
        }
      );
    } catch (error) {
      console.log("Caught error", error);
    }
  };

  console.log("Result current module", currentModule);
  console.log("Result sessions under module", sessionsUnderModule);

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // const handleResize = () => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
    // };

    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-md-12 p-0 mytogclass ${
                isSidebarOpen ? "opened" : ""
              }`}
            >
              <div className="toggleitem">
                <div className="custombar" onClick={toggleSidebar}>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                </div>
              </div>
            </div>

            <div className="col-md-12 p-0 mymainparthtml">
              <CoursePlayerSidebar
                sessionId={null}
                courseId={id}
                currentModule={currentModuleId}
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
              <div className="mainbody">
                <div className="modulebg">
                  <div className="stikeybutton"></div>
                  <div className="contentpart">
                    <div className="modulecontentinner">
                      <h2>
                        {/* {currentModule && currentModule?.name.split(":")[1]} */}
                        {currentModuleName?.split(":")[1]}
                      </h2>
                      <div className="mainwing">
                        <img src={leftWing} alt="Left Wing" />
                        {/* <h4>Module 3</h4> */}
                        <h4>{currentModuleName?.split(":")[0]}</h4>
                        <img src={rightWing} alt="Right Wing" />
                      </div>
                      <div className="seccionsec">
                        {sessionsUnderModule &&
                          sessionsUnderModule.map((topic: any) => (
                            <div>
                              <div
                                style={{ cursor: "pointer" }}
                                className="round"
                              >
                                {topic.status === "in-progress" ? (
                                  topic?.topic?.topicType === "quiz" ? (
                                    <img
                                      onClick={() => {
                                        navigate(
                                          `${Pages.QuizPlayer}/${id}/${currentModule.id}/${topic?.topic?.id}/${topic?.topic?.name}`,
                                          { state: topic }
                                        );
                                        setSidebarOpen(false);
                                      }}
                                      src={rightIcon}
                                      alt="Right Icon"
                                    />
                                  ) : (
                                    <img
                                      onClick={() => {
                                        navigate(
                                          `${Pages.Player}/${id}/${currentModule.id}/${topic?.topic?.id}/${topic?.topic?.name}`,
                                          { state: topic }
                                        );
                                        setSidebarOpen(false);
                                      }}
                                      src={rightIcon}
                                      alt="Right Icon"
                                    />
                                  )
                                ) : (
                                  <img src={lockIcon} alt="Lock Icon" />
                                )}
                              </div>
                              <h6>{topic?.topic?.name?.split(":")[0]}</h6>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <footer>
                <div className="footertext">
                  <div>
                    {/* <p>© 2022 Aviation Mastermind. All Rights Reserved.</p> */}
                    <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                  </div>
                  <div className="quicklinks">
                    <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      )}

      {menuLinks && (
        <div id="mySidenav" className="sidenav mymenusuv">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => {
              setMenuLinks(false);
              setLeftSidebarVisible(true);
              // setSidebarOpen(true);
            }}
          >
            &times;
          </a>

          <div className="menulinks">
            {topics.map((item: any) =>
              item?.topic?.parentTopicId === null ? (
                item.status === "in-progress" ? (
                  <ul>
                    <li>
                      <a
                        onMouseEnter={() => {
                          activateSubmenu(item?.topic?.id);
                          setShowSubMenu(false);
                        }}
                        onClick={() => {
                          navigate(
                            `${Pages.CourseLanding}/${id}?currentModuleId=${item?.topic?.id}`
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          color: currentModuleId
                            ? currentModuleId === item?.topic?.id
                              ? "red"
                              : "green"
                            : "green",
                        }}
                        className={
                          activeSubMenu === item?.topic?.id ? "activelink" : ""
                        }
                      >
                        {item?.topic?.name}
                      </a>
                      {activeSubMenu === item?.topic?.id && // Check if the active submenu matches the current topic's ID
                        subMenuItems &&
                        subMenuItems.map((subMenuItem) =>
                          subMenuItem?.status === "in-progress" ? (
                            subMenuItem?.topic?.topicType === "html" ? (
                              <ul className="submenu">
                                <li
                                  onClick={() => {
                                    navigate(
                                      `${Pages.Player}/${id}/${item?.topic?.id}/${subMenuItem?.topic?.id}/${subMenuItem?.topic?.id}`,
                                      { state: item }
                                    );
                                    setSidebarOpen(false);
                                  }}
                                >
                                  <a href="">{subMenuItem?.topic?.name}</a>
                                </li>
                              </ul>
                            ) : (
                              id &&
                              item?.topic?.id &&
                              subMenuItem?.topic?.id &&
                              item && (
                                <ul className="submenu">
                                  <li
                                    onClick={() => {
                                      navigateToQuiz(
                                        id,
                                        item?.topic?.id,
                                        subMenuItem?.topic?.id,
                                        subMenuItem
                                      );
                                      setSidebarOpen(false);
                                    }}
                                  >
                                    <a href="">{subMenuItem?.topic?.name}</a>
                                  </li>
                                </ul>
                              )
                            )
                          ) : (
                            <ul className="submenu">
                              <li>
                                <a href="" className="disablelink">
                                  {subMenuItem?.topic?.name}
                                </a>
                              </li>
                            </ul>
                          )
                        )}
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a className="disablelink">{item?.topic?.name}</a>
                    </li>
                  </ul>
                )
              ) : (
                <ul></ul>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DummyCourseLanding;
