import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CoursePlayerSidebar.css";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import { KeysAndValues, ask, getData } from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import { useNavigate } from "react-router";
import { Pages } from "../../Pages";
import {
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
  Topic,
} from "../../actors/course/types";

const CoursePlayerSidebar = ({
  sessionId,
  courseId,
  currentModule,
  sessionsUnderModule,
  isSidebarOpen,
  setSidebarOpen,
}: // topics,
any) => {
  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  const navigate = useNavigate();

  const [hidePreviousButton, setHidePreviousButton] = useState(false);

  const [isAddNotesOpen, setAddNotesOpen] = useState(false);
  const [isBookmarksOpen, setBookmarksOpen] = useState(false);

  const [addNotesValue, setAddNotesValue] = useState("");
  const [addBookMarksValue, setAddBookMarksValue] = useState("");

  const [isNotesEdit, setIsNotesEdit] = useState(false);
  const [isBookmarkView, setIsBookmarkView] = useState(false);

  const [menuLinks, setMenuLinks] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<Topic[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const [topics, setTopics] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState("");

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (menuLinks) {
      timeout = setTimeout(() => {
        const element = document.getElementById("mySidenav");
        if (element) {
          element.style.transform = "translateX(0)";
        }
      }, 100);
    } else {
      const element = document.getElementById("mySidenav");
      if (element) {
        element.style.transform = "translateX(-100%)";
      }
    }

    return () => clearTimeout(timeout);
  }, [menuLinks]);

  const courseData = getData(CourseClient_Name) as any;

  useEffect(() => {
    // if (isInitialized) {
    //   window.location.reload();
    //   setIsInitialized(false);
    // }
    setHidePreviousButton(false);
    const allSessions = (getData(CourseClient_Name) as any).topicList;
    console.log("effect USER TOPICS", allSessions);
    if (sessionId) {
      const matchingTopic = allSessions.find(
        (obj: any) => obj.topicId === sessionId
      );
      console.log("effect Matching Topic name", matchingTopic?.topic?.name);
      setAddBookMarksValue(matchingTopic?.topic?.name);
    }
    if (courseData?.state === CourseClient_States.Authenticated) {
      setTopics(courseData?.moduleList);
      // setLoading(false);
    }
  }, []);

  useEffect(() => {
    const totalTopics = topics.length;
    const inProgressTopics = topics.filter(
      (topic: any) => topic.status === "in-progress"
    ).length;

    const percentageInProgress = (inProgressTopics / totalTopics) * 100;

    console.log(`Percentage of "in-progress" topics: ${percentageInProgress}%`);
    setProgressPercentage(percentageInProgress.toFixed(0).toString());
  }, [topics]);

  console.log("courseId", courseId);
  console.log("currentModule", currentModule);
  console.log("sessionsUnderModule", sessionsUnderModule);

  topics.sort(
    (
      a: { topic: { localOrder: number } },
      b: { topic: { localOrder: number } }
    ) => a?.topic?.localOrder - b?.topic?.localOrder
  );

  console.log("SORTED TOPICS", topics);

  const myName = "Dashboard-gui";

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate("/");
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const activateSubmenu = (topicId: any) => {
    if (!showSubMenu) {
      console.log("TOPIC ACTIVATION VALUE", topicId);
      setActiveSubMenu(topicId);
      const sessions: Topic[] = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      const sortedSessions = sessions.slice().sort((a, b) => {
        const orderA = a?.topic?.localOrder || 0;
        const orderB = b?.topic?.localOrder || 0;
        return orderA - orderB; // For ascending order
      });
      sortedSessions && setSubMenuItems(sortedSessions);
      setShowSubMenu(true);
    } else if (subMenuItems && showSubMenu) {
      setActiveSubMenu(null);
      setShowSubMenu(false);
      const sessions: Topic[] = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      if (
        sessions.every((element: any) =>
          subMenuItems.includes(element as never)
        )
      ) {
        setShowSubMenu(false);
      } else {
        const sortedSessions = sessions.slice().sort((a, b) => {
          const orderA = a?.topic?.localOrder || 0;
          const orderB = b?.topic?.localOrder || 0;
          return orderA - orderB; // For ascending order
        });
        sortedSessions && setSubMenuItems(sortedSessions);
        setShowSubMenu(true);
        setActiveSubMenu(topicId);
      }
    } else {
      setShowSubMenu(false);
      setActiveSubMenu(null);
    }
  };

  const navigateToQuiz = async (
    course: string,
    module: string,
    session: string,
    item: any
  ) => {
    console.log("COURSE, MODULE, SESSION", course, module, session);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { course, module, session, item },
        message: CourseClient_Messages.NavigateToQuiz,
      });
      console.log("RESULT FROM NAVIGATE QUIZ", result);
      navigate(
        `${Pages.QuizPlayer}/${course}/${module}/${session}/${session}`,
        {
          state: item,
        }
      );
    } catch (error) {
      console.log("Caught error", error);
    }
  };

  // const [isSidebarOpen, setSidebarOpen] = useState(true);

  // // Function to toggle the sidebar
  const toggleSidebar = () => {
    setSidebarOpen(false);
  };

  // // Effect to close the sidebar on smaller screens
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 768) {
  //       setSidebarOpen(false);
  //     } else {
  //       setSidebarOpen(true);
  //     }
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Cleanup the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      {/* <div
        className={`col-md-12 p-0 mytogclass ${isSidebarOpen ? "opened" : ""}`}
      >
        <div className="toggleitem">
          <div className="custombar" onClick={toggleSidebar}>
            <span
              className={`custom_bar ${
                isSidebarOpen ? "custom_bar_close" : ""
              }`}
            ></span>
            <span
              className={`custom_bar ${
                isSidebarOpen ? "custom_bar_close" : ""
              }`}
            ></span>
            <span
              className={`custom_bar ${
                isSidebarOpen ? "custom_bar_close" : ""
              }`}
            ></span>
          </div>
        </div>
      </div> */}
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="innersider">
          <img className="avilogo" src={avilogo} alt="Logo" />
          <div className="username">
            <div className="usericon">
              <img src={userIcon} alt="User Icon" />
            </div>
            <div className="usertext">
              <h4>{fullName}</h4>
              <h6>
                <span>Status:</span> <span className="Active">Active</span>{" "}
                <span>/ Plan:</span> Free
              </h6>
            </div>
          </div>
          <div className="viewprofile">
            <button
              onClick={() => {
                console.log("Navigation link clicked");
                navigate(`${Pages.Profile}/${courseId}`);
                // toggleSidebar();
              }}
              className="goldenbtn"
            >
              View Profile
            </button>
          </div>
          <div className="processbar">
            <div className="progress">
              <div
                className="progress-bar"
                style={{
                  width: `${progressPercentage}%`,
                  backgroundColor: "#C7B277",
                }}
                role="progressbar"
                aria-valuenow={10}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <span>{`${progressPercentage}% Complete`}</span>
          </div>
          <div className="buttonmargin">
            <button
              className="goldenbtn"
              onClick={() => {
                setMenuLinks(true);
                toggleSidebar();
              }}
            >
              COURSE OUTLINE
            </button>
          </div>
          <div className="quickmenulinks">
            <h2>MENU</h2>
            <ul>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    navigate(Pages.Others);
                    // toggleSidebar();
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    navigate(`${Pages.Notes}/${courseId}`);
                    // toggleSidebar();
                  }}
                >
                  Notes
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    navigate(`${Pages.Bookmarks}/${courseId}`);
                    // toggleSidebar();
                  }}
                >
                  BOOKMARKS
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    navigate(`${Pages.CertificateList}/${courseId}`);
                    // toggleSidebar();
                  }}
                >
                  View Certificate
                </a>
              </li>
              {/* <li>
                <a href="#">Help</a>
              </li> */}
              <li onClick={doLogout}>
                <a href="#">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {menuLinks && (
        <div
          id="mySidenav"
          style={{
            width: "700px",
            transform: "translateX(0)", // Initially hidden
            transition: "transform 1s ease-in-out",
          }}
          className="sidenav mymenusuv"
        >
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => {
              setMenuLinks(false);
              setSidebarOpen(true);
            }}
          >
            &times;
          </a>
          <div className="menulinks">
            {topics.map((item: any) =>
              item?.topic?.parentTopicId === null ? (
                item.status === "in-progress" ? (
                  <ul>
                    <li>
                      <a
                        // onClick={() => {
                        onMouseEnter={() => {
                          activateSubmenu(item?.topic?.id);
                          setShowSubMenu(false);
                        }}
                        onClick={() => {
                          navigate(
                            `${Pages.CourseLanding}/${courseId}?currentModuleId=${item?.topic?.id}`
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          color: currentModule
                            ? currentModule === item?.topic?.id
                              ? "#C7B277"
                              : "#000"
                            : "#000",
                        }}
                        className={
                          activeSubMenu === item?.topic?.id ? "activelink" : ""
                        }
                      >
                        {item?.topic?.name}
                      </a>
                      {activeSubMenu === item?.topic?.id && // Check if the active submenu matches the current topic's ID
                        subMenuItems &&
                        subMenuItems.map((subMenuItem) =>
                          subMenuItem?.status === "in-progress" ? (
                            subMenuItem?.topic?.topicType === "html" ? (
                              <ul className="submenu">
                                <li
                                  onClick={() => {
                                    navigate(
                                      `${Pages.Player}/${courseId}/${item?.topic?.id}/${subMenuItem?.topic?.id}/${subMenuItem?.topic?.id}`,
                                      { state: item }
                                    );
                                  }}
                                >
                                  <a
                                    href="#"
                                    style={{
                                      color: sessionId
                                        ? sessionId === subMenuItem?.topic?.id
                                          ? "black"
                                          : "#C7B277"
                                        : "#C7B277",
                                    }}
                                  >
                                    {subMenuItem?.topic?.name}
                                  </a>
                                </li>
                              </ul>
                            ) : (
                              courseId &&
                              item?.topic?.id &&
                              subMenuItem?.topic?.id &&
                              item && (
                                <ul className="submenu">
                                  <li
                                    onClick={() => {
                                      navigateToQuiz(
                                        courseId,
                                        item?.topic?.id,
                                        subMenuItem?.topic?.id,
                                        subMenuItem
                                      );
                                    }}
                                  >
                                    <a
                                      href="#"
                                      style={{
                                        color: sessionId
                                          ? sessionId === subMenuItem?.topic?.id
                                            ? "black"
                                            : "#C7B277"
                                          : "#C7B277",
                                      }}
                                      onClick={() => setShowSubMenu(false)}
                                    >
                                      {subMenuItem?.topic?.name}
                                    </a>
                                  </li>
                                </ul>
                              )
                            )
                          ) : (
                            <ul className="submenu">
                              <li>
                                <a href="#" className="disablelink">
                                  {subMenuItem?.topic?.name}
                                </a>
                              </li>
                            </ul>
                          )
                        )}
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a className="disablelink">{item?.topic?.name}</a>
                    </li>
                  </ul>
                )
              ) : (
                <ul></ul>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CoursePlayerSidebar;
