//---------------------------------------------------------------------------------
//
// auth/AuthClient.ts
//
//---------------------------------------------------------------------------------

import {
  ask,
  Dictionary,
  getData,
  KeysAndValues,
  Message,
  MessageResponse,
  StatefulActor,
  StatefulActorErrorState,
  StatefulActorInstance,
  StatefulActorStateEntry,
  StatefulActorStateEvent,
  StatefulActorStateInstance,
} from "lib-js-c-modulo/index.mjs";
import { LocalActorConfig } from "../../modulo/types";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
  AuthService_Name,
} from "./types";

//---------------------------------------------------------------------------------

const defaultData: () => AuthClient_Data = () => ({
  token: "",
  fullName: "",
  roles: [],
  countryList: [],
  navigationObject: {},
  userTopics: [],
});

//---------------------------------------------------------------------------------

class AuthClient_State_Start extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    this.actor.gotoState(AuthClient_States.NotAuthenticated, input);
  };
}

//---------------------------------------------------------------------------------

class AuthClient_State_Validating extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    const init = async () => {
      try {
        this.actor.setState(AuthClient_States.Validating, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.Validate,
          payload: { token: this.actor.data.token },
          expectResult: true,
        });
        if ((result as MessageResponse).error) {
          throw new Error(result!.error);
        } else {
          const isValidSessionToken =
            (result as MessageResponse).payload?.isValidSessionToken === true;
          if (isValidSessionToken) {
            this.actor.gotoState(AuthClient_States.Authenticated, {
              ...result!.payload!,
            });
          } else {
            this.actor.gotoState(
              AuthClient_States.NotAuthenticated,
              defaultData()
            );
          }
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidateError, {
          error: err.message,
        });
      }
    };
    init();
  };
}

//---------------------------------------------------------------------------------

class AuthClient_State_NotAuthenticated extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    const init = async () => {
      try {
        this.actor.setState(AuthClient_States.CountryList);
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.Country,
          payload: {},
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("result of countries", result);

        this.actor.setState(AuthClient_States.NotAuthenticated, {
          ...result!.payload!,
        });
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.CountryList, {
          error: err.message,
        });
      }
    };
    init();
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    // [AuthClient_Messages.Echo]: async (
    //   input?: KeysAndValues,
    //   message?: Message | undefined
    // ) => {
    //   console.log("INPUT", input);
    //   return { payload: `echo ${input?.inputText}` };
    // },
    [AuthClient_Messages.Echo]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        this.actor.setState(AuthClient_States.LoggingIn);
        console.log("inputClient", input);

        const echoResult = await ask({
          from: "test",
          to: AuthService_Name,
          message: AuthClient_Messages.Echo,
          payload: {
            inputText: input?.inputText,
          },
          expectResult: true,
        });
        console.log("echoResult", echoResult);

        this.actor.gotoState(AuthClient_States.NotAuthenticated);

        return echoResult?.payload;
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.LoginError, {
          error: err.message,
        });
        console.log("error", err);
        return { error: err.message };
      }
    },
    [AuthClient_Messages.Login]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // this.actor.setState(AuthClient_States.LoggingIn, defaultData());
        const loginResult = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.Login,
          payload: message!.payload!,
          expectResult: true,
        });
        console.log("LOGIN RESULT", loginResult);

        if ((loginResult as MessageResponse).error)
          throw new Error(loginResult!.error);
        else {
          if ((loginResult!.payload as KeysAndValues).isOtpRequired) {
            // this.actor.gotoState(AuthClient_States.ValidateEmail, {
            this.actor.gotoState(AuthClient_States.NotAuthenticated, {
              ...loginResult!.payload!,
            });
          } else {
            this.actor.gotoState(AuthClient_States.Authenticated, {
              ...loginResult!.payload!,
            });
          }
          return loginResult!.payload;
        }
      } catch (err: any) {
        // this.actor.gotoState(AuthClient_States.LoginError, {
        //   error: err.message,
        // });
        return { error: err.message };
      }
    },
    [AuthClient_Messages.ExternalLogin]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        this.actor.setState(AuthClient_States.LoggingIn, defaultData());
        const loginResult = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ExternalLogin,
          payload: { socialNetworkId: input!.id },
          expectResult: true,
        });
        console.log("EXTERNAL LOGIN RESULT", loginResult);

        if ((loginResult as MessageResponse).error)
          throw new Error(loginResult!.error);
        else {
          if ((loginResult!.payload as KeysAndValues).isOtpRequired) {
            this.actor.gotoState(AuthClient_States.ValidateEmail, {
              ...loginResult!.payload!,
            });
          } else {
            this.actor.gotoState(AuthClient_States.Authenticated, {
              ...loginResult!.payload!,
            });
          }
          return loginResult!.payload;
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.LoginError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    //--------------------------------------------

    [AuthClient_Messages.RequestPasswordReset]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      console.log(JSON.stringify(message!.payload));
      try {
        this.actor.setState(
          AuthClient_States.RequestingPasswordReset,
          defaultData()
        );
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.RequestPasswordReset,
          payload: message!.payload,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        this.actor.gotoState(AuthClient_States.NotAuthenticated, {});
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.RequestingPasswordResetError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    //--------------------------------------------

    [AuthClient_Messages.ResetPassword]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("Auth client reset password");
        // console.log(JSON.stringify(input))
        this.actor.setState(AuthClient_States.ResettingPassword, defaultData());
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ResetPassword,
          payload: input,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        this.actor.gotoState(AuthClient_States.NotAuthenticated, {});
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ResettingPasswordError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    //--------------------------------------------

    [AuthClient_Messages.ResendOtp]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("Auth client reset password");
        // console.log(JSON.stringify(input))
        this.actor.setState(AuthClient_States.ValidatingEmail, defaultData());
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ResendOtp,
          payload: input,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        this.actor.gotoState(AuthClient_States.NotAuthenticated, {});
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidatingEmailError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    //--------------------------------------------

    [AuthClient_Messages.Register]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // this.actor.setState(AuthClient_States.Registering, defaultData());
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.Register,
          payload: message!.payload,
          expectResult: true,
        });
        console.log("RESULT FROM AUTH CLIENT", result);

        if ((result as MessageResponse).error) throw new Error(result!.error);
        else this.actor.gotoState(AuthClient_States.NotAuthenticated);
        return result!.payload!.user;
      } catch (err: any) {
        // this.actor.gotoState(AuthClient_States.RegisterError, {
        //   error: err.message,
        // });
        return { error: err.message };
      }
    },

    //--------------------------------------------

    [AuthClient_Messages.ValidateEmail]: async (input?: KeysAndValues) => {
      try {
        console.log("INPUT FROM EMAIL VERIFICATION", input);

        this.actor.setState(AuthClient_States.ValidatingEmail, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ValidateEmail,
          payload: input,
          expectResult: true,
        });
        console.log("RESULT FROM EMAIL VERIFICATION", result);

        if ((result as MessageResponse).error) throw new Error(result!.error);
        this.actor.gotoState(AuthClient_States.Authenticated, {
          ...result!.payload!,
        });
        return { result };
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidatingEmailError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },
  };
}

//---------------------------------------------------------------------------------

class AuthClient_State_ValidateEmail extends StatefulActorStateInstance {
  public on: Dictionary<StatefulActorStateEvent> = {
    [AuthClient_Messages.CancelValidateEmail]: async (
      input?: KeysAndValues
    ) => {
      this.actor.gotoState(AuthClient_States.NotAuthenticated, {});
    },

    //--------------------------------------------

    [AuthClient_Messages.ValidateEmail]: async (input?: KeysAndValues) => {
      try {
        console.log("INPUT FROM EMAIL VERIFICATION", input);

        this.actor.setState(AuthClient_States.ValidatingEmail, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ValidateEmail,
          payload: input,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        this.actor.gotoState(AuthClient_States.Authenticated, {
          ...result!.payload!,
        });
        return { result };
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidatingEmailError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },
  };
}

//---------------------------------------------------------------------------------

export class AuthClient_State_Authenticated extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    this.actor.setState(AuthClient_States.Authenticated, input);
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [AuthClient_Messages.Validate]: async (input?: KeysAndValues) => {
      this.actor.gotoState(AuthClient_States.Validating, {});
    },

    //--------------------------------------------

    [AuthClient_Messages.Logout]: async (input?: KeysAndValues) => {
      try {
        this.actor.setState(AuthClient_States.LoggingOut, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.Logout,
          payload: addSessionToken({}),
          expectResult: true,
        });
        console.log("RESULT FROM LOGOUT", result);

        if ((result as MessageResponse).error) {
          throw new Error(result!.error);
        } else {
          this.actor.gotoState(AuthClient_States.NotAuthenticated, {});
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.LogoutError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    [AuthClient_Messages.ChangeUserName]: async (input?: KeysAndValues) => {
      try {
        this.actor.setState(AuthClient_States.Validating, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.ChangeUserName,
          payload: addSessionToken({
            firstName: input!.firstName,
            lastName: input!.lastName,
          }),
          expectResult: true,
        });
        console.log("RESULT FROM CHANGE USER NAME", result);

        if ((result as MessageResponse).error) {
          throw new Error(result!.error);
        } else {
          this.actor.gotoState(AuthClient_States.Authenticated, {
            ...result!.payload!,
          });
          const returnValue = result?.payload;
          return { returnValue };
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidateError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },

    [AuthClient_Messages.UpdatePassword]: async (input?: KeysAndValues) => {
      try {
        this.actor.setState(AuthClient_States.Validating, {});
        const result = await ask({
          from: AuthClient_Name,
          to: AuthService_Name,
          message: AuthClient_Messages.UpdatePassword,
          payload: addSessionToken({
            password: input!.password,
          }),
          expectResult: true,
        });
        console.log("RESULT FROM UPDATE PASSWORD", result);

        if ((result as MessageResponse).error) {
          throw new Error(result!.error);
        } else {
          this.actor.gotoState(AuthClient_States.Authenticated, {
            // ...result!.payload!,
          });
          const returnValue = result?.payload;
          return { returnValue };
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidateError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },
    [AuthClient_Messages.SubscriptionStateChange]: async (
      input?: KeysAndValues
    ) => {
      try {
        this.actor.setState(AuthClient_States.Validating, {});
        // const result = await ask({
        //   from: AuthClient_Name,
        //   to: AuthService_Name,
        //   message: AuthClient_Messages.UpdatePassword,
        //   payload: addSessionToken({
        //     password: input!.password,
        //   }),
        //   expectResult: true,
        // });
        // console.log("RESULT FROM UPDATE PASSWORD", result);
        const result = input!.result;

        console.log("this is the result from auth sub combo", result);

        if ((result as MessageResponse).error) {
          throw new Error(result!.error);
        } else {
          this.actor.setState(AuthClient_States.Authenticated, {
            ...result!.payload!,
          });
          const returnValue = result?.payload;
          return { returnValue };
        }
      } catch (err: any) {
        this.actor.gotoState(AuthClient_States.ValidateError, {
          error: err.message,
        });
        return { error: err.message };
      }
    },
  };
}

//---------------------------------------------------------------------------------

export function addSessionToken(payload: KeysAndValues): KeysAndValues {
  const authData = getData(AuthClient_Name) as KeysAndValues as AuthClient_Data;
  console.log("ADD SESSION TOKEN", authData);

  return {
    ...payload,
    token: authData.token,
  };
}

//---------------------------------------------------------------------------------

export function isAuthenticated(): boolean {
  const authClientState = getData(AuthClient_Name).state;
  return [
    AuthClient_States.Authenticated,
    AuthClient_States.LoggingOut,
    AuthClient_States.LogoutError,
  ].includes(authClientState);
}

//---------------------------------------------------------------------------------

export function createAuthClientActor(config: LocalActorConfig): StatefulActor {
  const actor = new StatefulActorInstance(config.name, defaultData(), false);
  actor.setAllStates([
    new AuthClient_State_Start(AuthClient_States.Start, true, actor),
    new AuthClient_State_Validating(AuthClient_States.Validating, true, actor),
    new StatefulActorErrorState(
      AuthClient_States.ValidateError,
      actor,
      AuthClient_States.Validating,
      {}
    ),
    new AuthClient_State_NotAuthenticated(
      AuthClient_States.NotAuthenticated,
      false,
      actor
    ),
    new StatefulActorStateInstance(AuthClient_States.LoggingIn, true, actor),
    new StatefulActorErrorState(
      AuthClient_States.LoginError,
      actor,
      AuthClient_States.NotAuthenticated,
      {}
    ),
    new StatefulActorStateInstance(
      AuthClient_States.RequestingPasswordReset,
      true,
      actor
    ),
    new StatefulActorErrorState(
      AuthClient_States.RequestingPasswordResetError,
      actor,
      AuthClient_States.NotAuthenticated,
      {}
    ),
    new StatefulActorStateInstance(
      AuthClient_States.ResettingPassword,
      true,
      actor
    ),
    new StatefulActorErrorState(
      AuthClient_States.ResettingPasswordError,
      actor,
      AuthClient_States.NotAuthenticated,
      {}
    ),
    new AuthClient_State_Authenticated(
      AuthClient_States.Authenticated,
      false,
      actor
    ),
    new AuthClient_State_ValidateEmail(
      AuthClient_States.ValidateEmail,
      false,
      actor
    ),
    new StatefulActorStateInstance(
      AuthClient_States.ValidatingEmail,
      true,
      actor
    ),
    new StatefulActorErrorState(
      AuthClient_States.ValidatingEmailError,
      actor,
      AuthClient_States.ValidateEmail,
      {}
    ),
    new StatefulActorStateInstance(AuthClient_States.LoggingOut, true, actor),
    new StatefulActorErrorState(
      AuthClient_States.LogoutError,
      actor,
      AuthClient_States.Authenticated,
      {}
    ),
    new StatefulActorStateInstance(AuthClient_States.Registering, true, actor),
    new StatefulActorErrorState(
      AuthClient_States.RegisterError,
      actor,
      AuthClient_States.NotAuthenticated,
      {}
    ),
    new StatefulActorStateInstance(AuthClient_States.CountryList, true, actor),
    new StatefulActorErrorState(
      AuthClient_States.CountryListError,
      actor,
      AuthClient_States.NotAuthenticated,
      {}
    ),
  ]);
  return actor;
}

//---------------------------------------------------------------------------------
