import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./landing.css";
import logo from "./images/logo.svg";
import loginicon from "./images/loginicon.png";
import videolanding from "./images/videolanding.png";
import Info from "../../assets/l1.png";
import lockbig from "../../assets/lockbig.png";
import slide3 from "../../assets/slide3.png";
import slide2 from "../../assets/slide2.png";
import image12 from "../../assets/l2.png";
import module1 from "../../assets/all-modules/1.png";
import module2 from "../../assets/all-modules/2.png";
import module3 from "../../assets/all-modules/3.png";
import module4 from "../../assets/all-modules/4.png";
import module5 from "../../assets/all-modules/5.png";
import module6 from "../../assets/all-modules/6.png";
import module7 from "../../assets/all-modules/7.png";
import module8 from "../../assets/all-modules/8.png";
import module9 from "../../assets/all-modules/9.png";
import module10 from "../../assets/all-modules/10.png";
import module11 from "../../assets/all-modules/11.png";
import module12 from "../../assets/all-modules/12.png";
import module13 from "../../assets/all-modules/13.png";
import module14 from "../../assets/all-modules/14.png";
import module15 from "../../assets/all-modules/15.png";
import module16 from "../../assets/all-modules/16.png";
import module17 from "../../assets/all-modules/17.png";
import module18 from "../../assets/all-modules/18.png";
import Swiper from "swiper";
import "../dummy/css/swiper-bundle.min.css";
import righttick from "../../assets/righttick.png";
import watermarkwing from "../../assets/watermarkwing.png";
// import landingPage from  "../assets/landing-page-banner.png";
// import Swiper from "swiper";
// import pic1 from "../../assets/l1.png";
import pic1 from "./images/l1.png";
import pic2 from "./images/l2.png";
import pic3 from "./images/l3.png";
import pic4 from "./images/l4.png";
import pic5 from "./images/l5.png";
import pic6 from "./images/l6.png";
import pic7 from "./images/l7.png";
import pic8 from "./images/l8.png";
import pic9 from "./images/l9.png";
import { useLocation, useNavigate } from "react-router";
import { Pages } from "../../Pages";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import { KeysAndValues, ask, getData } from "lib-js-c-modulo/index.mjs";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";
import { config } from "../../config";
import VideoPlayer from "./VideoPlayer";

const LandingPage = () => {
  const navigate = useNavigate();
  const swiperRef = useRef<Swiper | null>(null);
  const moduleImages = [
    module1,
    module2,
    module3,
    module4,
    module5,
    module6,
    module7,
    module8,
    module9,
    module10,
    module11,
    module12,
    module13,
    module14,
    module15,
    module16,
    module17,
    module18,
  ];
  useEffect(() => {
    const swiper = new Swiper(".swiper", {
      direction: "horizontal",
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      centeredSlides: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
      },
    });

    // Assign the Swiper instance to the ref
    swiperRef.current = swiper;
  }, []);

  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const myName = "website-landing";

  useEffect(() => {
    if (id) {
      // Only make the API call if 'id' is not null
      console.log("Id from website landing", id);
      const externalLogin = async () => {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          payload: { id },
          message: AuthClient_Messages.ExternalLogin,
        });
        if (!result!.error) {
          if (result!.payload !== undefined) {
            if ((result!.payload as KeysAndValues).isOtpRequired) {
              console.log(
                "result payload",
                (result!.payload as KeysAndValues).isOtpRequired
              );

              navigate(Pages.ValidateEmail);
            } else {
              const navigationObject = (
                getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
              ).navigationObject;
              console.log(
                "EFFECT NAVIGATION OBJECT AFTER LOGIN",
                navigationObject
              );
              const navigationTopic = navigationObject?.currentTopic;
              const courseId = navigationTopic?.courseId;
              const moduleId = navigationTopic?.parentTopicId;
              const sessionId = navigationTopic?.id;

              if (navigationObject?.isCorporateAdmin) {
                console.log("EFFECT CORPORATE ADMIN ROUTE");
                navigate(Pages.CompanyInfo);
              } else if (navigationTopic?.topicType === "quiz") {
                if (navigationTopic?.localOrder > 1) {
                  console.log("EFFECT navigate to quiz");
                  const navigationToQuiz = async () => {
                    try {
                      let result = await ask({
                        from: "Dashboard-gui",
                        to: CourseClient_Name,
                        payload: {
                          course: courseId,
                          module: moduleId,
                          session: sessionId,
                          item: navigationTopic,
                        },
                        message: CourseClient_Messages.NavigateToQuiz,
                      });
                      console.log("RESULT FROM NAVIGATE QUIZ", result);
                      navigate(
                        `${Pages.QuizPlayer}/${courseId}/${moduleId}/${sessionId}/${sessionId}`,
                        { state: navigationObject?.quizTopic }
                      );
                    } catch (error) {
                      console.log("Caught error", error);
                    }
                  };
                  navigationToQuiz();
                } else {
                  console.log("effect stay on course landing");
                  navigate(Pages.Dashboard);
                }
              } else if (navigationTopic?.topicType === "html") {
                if (navigationTopic?.localOrder > 1) {
                  console.log("EFFECT navigate to html");
                  navigate(
                    `${Pages.Player}/${navigationTopic?.courseId}/${navigationTopic?.parentTopicId}/${navigationTopic?.id}/${navigationTopic?.id}`,
                    { state: navigationTopic }
                  );
                } else {
                  console.log("effect stay on course landing");
                  navigate(Pages.Dashboard);
                }
              }
            }
          }
        }
      };
      externalLogin();
    }
  }, [id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="headerbanner">
              <div className="">
                <div className="customheader">
                  <nav className="navbar sticky-top navbar-expand-lg">
                    <div className="container-fluid">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo01"
                        aria-controls="navbarTogglerDemo01"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navbarTogglerDemo01"
                      >
                        <a
                          className="navbar-brand"
                          onClick={() => navigate(Pages.Home)}
                        >
                          <img src={logo} />
                        </a>
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href={config.websiteLink}
                              style={{ cursor: "pointer" }}
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href={`${config.websiteLink}/#what`}
                            >
                              What
                            </a>
                          </li>
                          {/* <li className="nav-item">
                            <a
                              className="nav-link"
                              href={`${config.websiteLink}/#benefits`}
                            >
                              Benefits
                            </a>
                          </li> */}
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href={`${config.websiteLink}/#aboutus`}
                            >
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href={`${config.websiteLink}/#contactus`}
                            >
                              Contact us
                            </a>
                          </li>
                          {/* <li className="nav-item">
                            <a className="nav-link" href="www.google.com">
                              eLearning
                            </a>
                          </li> */}
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              onClick={() => navigate(Pages.Login)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                id="togleicon"
                                src={loginicon}
                                className="me-2"
                              />
                              Log in
                            </a>
                          </li>
                          <li className="freetrial nav-item">
                            <a href="www.google.com">Free Trial</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-md-8">
                  <div className="headertextlanding">
                    <h4>Skill based</h4>
                    <h3>Training &</h3>
                    <h2>Certifications</h2>
                    <div className="homebtn">
                      <button
                        className="goldenbtn"
                        onClick={() => navigate(Pages.Register)}
                      >
                        register
                      </button>
                      <button
                        className="goldenbtn"
                        onClick={() => navigate(Pages.Login)}
                      >
                        Login
                      </button>
                      <button
                        className="goldenbtn"
                        onClick={() => navigate(Pages.Home)}
                      >
                        Free trail
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landingcontainer">
          <div className="row">
            <div className="col-md-12">
              <div className="videodiv">
                {/* <img className="img-fluid" src={videolanding} /> */}
                <VideoPlayer
                  // videoSrc="/Landing-page-video/test-video.mp4"
                  videoSrc="/Landing-page-video/AFM_Infographic.mp4"
                />
              </div>
            </div>
          </div>
          <div className="row secondsec">
            <div className="col-xl-6 mypadingcust">
              <h2 className="omanhead">
                About <span>this course</span>
              </h2>
              <p className="omanheadp">
                {/* A modular learning platform enabling professionals to upskill at
                their convenience; anywhere and anytime. */}
                The Aviation Fuel Business is crucial to any Oil company's
                operations.
              </p>
              <p className="omanheadp">
                The AFM training module is particularly designed to guide and
                train personnel responsible for Aviation Products Quality
                Assurance at Terminals, airport depots, and into-plane services.
                The ultimate aim is to ensure that aircraft are provided with
                clean, dry fuel and meet all necessary specifications.
              </p>
              {/* <button className="goldenbtn whitetext">Start Course</button> */}
            </div>
            <div className="col-xl-6">
              <h2 className="omanhead">
                This Course <span>Includes</span>
              </h2>
              <div className="listinginfo">
                <ul>
                  <li>
                    <img src={pic1} />{" "}
                    <span className="landing-first-no-content">
                      Info based interactive course
                    </span>
                  </li>
                  <li>
                    <img src={pic2} />
                    100% online
                  </li>
                  <li>
                    <img src={pic3} />
                    Access on mobile and laptop
                  </li>
                  <li>
                    <img src={pic4} />
                    Certificate of completion
                  </li>
                  <li>
                    <img src={pic5} />
                    Quality content focused on relevant skills by aviation fuel
                    industry experts
                  </li>
                  <li>
                    <img src={pic6} />
                    <span className="landing-six-no-content">
                      Up-to-date content
                    </span>
                  </li>
                  <li>
                    <img src={pic7} />
                    Animations to explain the aviation fueling concepts,
                    Hands-on exercises
                  </li>
                  <li>
                    <img src={pic8} />
                    Ask Expert (Q&A)
                  </li>
                  <li>
                    <img src={pic9} />
                    Personalization (Learning Paths)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row thirdsec">
            <div className="col-lg-12 text-center">
              <h2 className="omanhead">
                Choose <span>Your plan</span>
              </h2>
            </div>
            <div className="col-lg-12">
              <div className="flexcardsubscribe">
                <div className="mainborder">
                  <div className="headeramount">
                    <h4>Free Trial</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$00</h6>
                    <ul>
                      <li>15 days Free access</li>
                      <li>Limited courses (2 Basic Modules)</li>
                      <li>Test Knowledge (Quiz)</li>
                    </ul>
                    <div
                      className="coursebtn"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="goldenbtn whitetext"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => navigate(Pages.Home)}
                      >
                        Current Course
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mainborder">
                  <div className="headeramount">
                    <h4>Premium</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$250</h6>
                    <ul>
                      <li>Yearly Access</li>
                      <li>Unlimited Access To All Content</li>
                      <li>Test Knowledge (Quiz)</li>
                      <li>Ask Expert</li>
                      <li>Personalize Your Learning Path</li>
                      <li>Certification</li>
                    </ul>
                    <div
                      className="coursebtn"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="goldenbtn whitetext"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => navigate(Pages.Home)}
                      >
                        Upgrade Course
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="mainborder">
                  <div className="headeramount">
                    <h4>business</h4>
                  </div>
                  <div className="contentamountbody">
                    <h6>$1000</h6>
                    <ul>
                      <li>Yearly Access</li>
                      <li>Unlimited Access To All Content</li>
                      <li>Test Knowledge (Quiz)</li>
                      <li>Ask Expert</li>
                      <li>Personalize Your Learning Path</li>
                      <li>Certification</li>
                    </ul>
                    <div
                      className="coursebtn"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="goldenbtn whitetext"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => navigate(Pages.Home)}
                      >
                        Upgrade Course
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row forsec">
            <div className="col-md-12 text-center">
              <h2 className="omanhead">
                <span>Content</span>
              </h2>
            </div>
            <div className="col-md-12">
              <div className="swiper customsldier">
                <div className="swiper-wrapper">
                  {/* <div className="swiper-slide">
                    <div className="mainsliderlock">
                      <img src={slide3} />
                      <div className="centered"> */}
                  {/* <img src={lockbig} /> */}
                  {/* </div>
                    </div>
                  </div> */}
                  {moduleImages.map((image, index) => (
                    <div className="swiper-slide" key={index}>
                      <div className="mainsliderlock">
                        <img src={image} alt={`Slide ${index + 1}`} />
                        <div className="centered">
                          {/* <img src={lockbig} /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="swiper-pagination"
                  style={{ position: "relative", marginBottom: "100px" }}
                ></div>

                <div
                  className="swiper-button-prev"
                  onClick={goToPrevSlide}
                ></div>
                <div
                  className="swiper-button-next"
                  onClick={goToNextSlide}
                ></div>
              </div>
            </div>
          </div>
          {/* end of swiper*/}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <footer>
              <div className="footertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href="www.google.com">PRIVACY</a>
                  <a href="www.google.com">TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered my-modal">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="mainborder">
                <div className="headeramount">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <h4>
                    Your free trial pack has expired Please Upgrade
                    subscription.
                  </h4>
                </div>
                <div className="contentamountbody">
                  <h6>$250</h6>
                  <ul>
                    <li>15 days Free access</li>
                    <li>Limited Courses (2 Basic Modules)</li>
                    <li>Test Knowledge (Quiz)</li>
                  </ul>
                  <div className="text-center contibtn">
                    <button className="goldenbtn whitetext goldenbtn-continue">
                      continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
