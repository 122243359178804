//---------------------------------------------------------------------------------
//
// auth/types.ts
//
//---------------------------------------------------------------------------------

export const AuthClient_Name = "auth-client";
export const AuthService_Name = "auth-service";

//---------------------------------------------------------------------------------

export enum UserRole {
  IndividualUser = "individual-user",
  CorporateUser = "corporate-user",
  CorporateAdmin = "corporate-admin",
  SystemAdmin = "system-admin",
}

export enum ExternalLoginType {
  Google = "google",
  Facebook = "facebook",
}

export enum UserState {
  Registering = "registering",
  Active = "active",
  Inactive = "inactive",
  OnTrial = "on-trial",
}

export type Country = {
  id: string;
  name: string;
  isdCode: string;
  code: string;
};

export interface AuthClient_Data {
  token: string;
  fullName: string;
  roles: Array<string>;
  countryList: Array<Country>;
  navigationObject: any;
  userTopics: any;
  user?: User;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  password?: string;
  phone?: string;
  googleDetails: string;
  facebookDetails: string;
  companyId: string;
  trialEndsOn: Date;
  lastVisitedCourseId: string;
  lastVisitedTopicId: string;
  state: UserState;
  //  userType: UserType;
  emailValidationOtp?: number;
  passwordResetOtp?: number;
  passwordResetOtpValidTill?: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  validateOn: Date;
  //  countryId: string | null;

  // Second phase additions
  role: UserRole;
  LastLoginOn: Date;
  // static userType: UserType;
  designation: string;
  mailOTP: string;
  // Only for company users
  registrationStatus: string;
}

//---------------------------------------------------------------------------------

export enum AuthClient_States {
  Start = "start",
  // Not Authenticated States
  Validating = "validating",
  ValidateError = "validate-error",
  NotAuthenticated = "not-authenticated",
  LoggingIn = "logging-in",
  LoginError = "login-error",
  ValidateEmail = "validate-email",
  ValidatingEmail = "validating-email",
  ValidatingEmailError = "validating-email-error",
  ResettingPassword = "resetting-password",
  ResettingPasswordError = "resetting-password-error",
  RequestingPasswordReset = "requesting-password-reset",
  RequestingPasswordResetError = "requesting-password-reset-error",
  Registering = "registering",
  RegisterError = "register-error",
  CountryList = "get-country-list",
  CountryListError = "country-list-error",

  // Authenticated States
  Authenticated = "authenticated",
  LoggingOut = "logging-out",
  LogoutError = "logout-error",
  // TODO: Add change Profile.tsx accordingly
}

//---------------------------------------------------------------------------------

export enum AuthClient_Messages {
  Login = "login",
  Logout = "logout",
  Validate = "validate",
  RequestPasswordReset = "request-password-reset",
  ResetPassword = "reset-password",
  Register = "register",
  ValidateEmail = "validate-email",
  CancelValidateEmail = "cancel-validate-email",
  Country = "country-list",
  Echo = "echo",
  //
  ChangeUserName = "change-user-name",
  UpdatePassword = "update-password",
  SubscriptionStateChange = "subscription-state-change",
  ExternalLogin = "external-login",
  ResendOtp = "resend-otp",
}

//---------------------------------------------------------------------------------
