import { useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import { ask } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";

const myName = "Dashboard-gui";

const DummyCertificate = () => {
  const navigate = useNavigate();
  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  return (
    <>
      <button type="button" onClick={doLogout}>
        Logout(OG)
      </button>
      <div>
        <h1>CERTIFICATE</h1>
        <div>
          <button onClick={() => navigate(Pages.Home)}>
            DOWNLOAD CERTIFICATE
          </button>
        </div>
      </div>
    </>
  );
};

export default DummyCertificate;
