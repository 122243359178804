import { ask, KeysAndValues } from "lib-js-c-modulo/index.mjs";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { Pages } from "../../Pages";
import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { PasswordInput, TextInput } from "@mantine/core";
import styled from "styled-components";
import banner from "../../assets/banner.png";
import NavBar from "../common/NavBar";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import eyeIcon from "../../assets/eyeIcon.svg";

const myName = "Login-gui";

interface loginData {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const loginElement = useFocusTrap();

  const loginSchema = z.object({
    email: z.string().email({ message: "Invalid email" }),
    password: z.string().min(4, { message: "password is Mandatory" }),
  });

  const loginForm = useForm({
    schema: zodResolver(loginSchema),
    initialValues: {
      email: "bifinbabu5@gmail.com",
      password: "12345678",
    },
  });

  const doLogin = useCallback(
    async (values: loginData) => {
      console.log("login data called");
      try {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.Login,
          payload: {
            email: values.email,
            password: values.password,
          },
        });
        if (!result!.error) {
          if (result!.payload !== undefined) {
            if ((result!.payload as KeysAndValues).isOtpRequired) {
              navigate(Pages.ValidateEmail);
            } else navigate(Pages.Dashboard);
          }
        }
      } catch (err) {
        navigate(Pages.Login);
      }
    },
    [navigate]
  );

  return (
    <Container>
      <NavBar />
      <form
        onSubmit={loginForm.onSubmit((values: any) => doLogin(values))}
        ref={loginElement}
      >
        <FormHeader>Let’s get started now!</FormHeader>
        <TextInput
          placeholder="Email Address"
          {...loginForm.getInputProps("email")}
          data-autofocus
        />
        <div className="passwordContainer">
          <PasswordInput
            placeholder="Password"
            {...loginForm.getInputProps("password")}
            data-autoFocus
            className="passwordContainer"
          />
          <Link to={Pages.RequestResetPassword}>Forgot Password ?</Link>
        </div>
        <AuthButtonContainer>
          <AuthButton type="submit" style={{ marginTop: "28px" }}>
            SIGN IN
          </AuthButton>

          <AuthButton
            type="button"
            style={{ marginTop: "28px" }}
            onClick={() => navigate(Pages.Home)}
          >
            BACK
          </AuthButton>
        </AuthButtonContainer>
      </form>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${banner});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 768px;
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65%;
  }

  input {
    background: #ffffff;
    border: 1px solid #c7b277;
    border-radius: 5px;
    width: 450px;
    height: 50px;
    font-family: "Roboto-Light";
    color: #050505;
    font-size: 18px;

    ::placeholder {
      color: #050505;
    }
  }

  .passwordContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: #050505;
    }
  }
  .mantine-y0fwdk,
  .mantine-wqgzfp {
    width: 450px;
    height: 65px !important;
    margin-bottom: 15px;
  }
  .mantine-o3oqoy {
    bottom: 25px;
  }
  .mantine-17m0bg2 {
    color: #ffffff;
    background-image: url(${eyeIcon});
    background-repeat: no-repeat;
    background-color: transparent;

    svg {
      display: none;
    }

    &:hover {
      color: #ffffff !important;
    }
  }
  .mantine-wqgzfp {
    border: none;
    background: none;
  }

  @media (max-width: 576px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 400px;
      height: 55px !important;
      margin-bottom: 15px;
    }

    input {
      width: 400px;
      height: 55px !important;
    }
  }
  @media (max-width: 424px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 370px;
      height: 45px !important;
      margin-bottom: 15px;
    }

    input {
      width: 370px;
      height: 45px !important;
    }
  }
  @media (max-width: 376px) {
    .mantine-y0fwdk,
    .mantine-wqgzfp {
      width: 290px;
      height: 40px !important;
      margin-bottom: 15px;
    }

    input {
      width: 290px;
      height: 40px !important;
    }
  }
`;

const FormHeader = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #050505;
  margin-bottom: 30px;
`;

export default Login;
