import { useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import styled from "styled-components";
import banner from "../dummy/images/banner-2.jpg";
import homeicon from "../dummy/images/homeicon.png";
import logo from "../dummy/images/logo.png";
import facebook from "../../assets/Facebook.png";
import google from "../../assets/google.png";
import NavBar from "../common/NavBar";
import { AuthButton } from "../common/styles";
import facebookLogo from "../dummy/images/fbLogo.png";
import googleLogo from "../dummy/images/googleLogo.png";
import logoafmcart from "../../assets/logoafmcart.png";
import "./Home.css";
import { config } from "../../config";
const DummyHome = () => {
  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate(Pages.Login);
  };

  const goToRegisterPage = () => {
    navigate(Pages.Register);
  };
  const doExternalRegister = async () => {
    let url = config.moduloApiEndPoint;
    let trimmedUrl = url.substring(url.length - 6, -1);
    try {
      // let response = await axios.get(`${trimmedUrl}login/google`);
      // let response = await axios.get(`${trimmedUrl}stripe-test`);
      window.open(
        `${trimmedUrl}login/google?=${window.location.origin}`,
        "_self"
      );
      // console.log("response is", response);
    } catch (error) {
      console.log("Error is", error);
    }
  };
  return (
    <>
      {/* <Container id="container">
        <img src={logo} alt="website logo" id="logo"></img>
        <img
          src={homeicon}
          alt=""
          id="homeicon"
          onClick={() => navigate(Pages.Others)}
          style={{ cursor: "pointer" }}
        />
        <AuthContainer>
          <AuthHeader id="content">
            Excellent aviation <br /> training programmes.
          </AuthHeader>
          <AuthSubHeader id="content_second">
            A comprehensive e-learning platform
          </AuthSubHeader>
          <AuthButton
            type="button"
            onClick={goToLoginPage}
            className="signInButton"
          >
            SIGN IN
          </AuthButton>
          <RegisterText>
            <span> Don't have an account.</span>&nbsp;
            <button
              type="button"
              onClick={goToRegisterPage}
              className="registerButton"
              style={{ textDecoration: "underline" }}
            >
              <b>Create Account</b>
            </button>
          </RegisterText>
          <IconContainer>
            <LogoContainer>
              <div className="googlebtn mt-4">
                <p>or sign in with</p>
                <div className="d-flex btnclassfbscoail">
                  <div
                    onClick={() => doExternalRegister()}
                    className="blackround"
                  >
                    <a href="#">Google</a>
                  </div>
                </div>
              </div>
            </LogoContainer>
          </IconContainer>
        </AuthContainer>
      </Container> */}
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logoafmcart} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a>
                <img
                  src={homeicon}
                  alt="Home Icon"
                  onClick={() => navigate(Pages.Others)}
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
          {/* <div className="row">
          <div className="col-md-12">
            <div className="titleofform">
              <h3>Sign In</h3>
            </div>
            <div className="d-flex justify-content-center otpcontent text-center mt-0">
              <p>
                Need an account?{" "}
                <a href="#" onClick={() => navigate(Pages.Register)}>
                  {" "}
                  Get started!
                </a>
              </p>
            </div>
            <div className="otpmain">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={OnboradSignSchema}
                onSubmit={(values, formikHelpers) => {
                  doLogin(values);
                }}
              >
                <Form className="createform">
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield mt-0">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <div className="positioneye">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="form-control"
                          placeholder="Password"
                        />
                        <a>
                          <img
                            src={showPassword ? imageTwo : imageOne}
                            alt="Show Password"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end otpcontent">
                    <p>
                      <a
                        href="#"
                        onClick={() => navigate(Pages.RequestResetPassword)}
                      >
                        Forgot password?
                      </a>
                    </p>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-center">
                    <button type="submit" className="sign-btn">
                      Sign In
                    </button>
                  </div>
                  <div className="googlebtn mt-4">
                    <p>or sign in with</p>
                    <div className="d-flex btnclassfbscoail">
                      <div
                        onClick={() => doExternalRegister()}
                        className="blackround"
                      >
                        <a href="#">Google</a>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div> */}
          <AuthContainer>
            <AuthHeader id="content">
              aviation <br /> training programme
            </AuthHeader>
            <AuthSubHeader id="content_second">
              A comprehensive e-learning platform for the Aviation fuel
              professionals
            </AuthSubHeader>
            <AuthButton
              type="button"
              onClick={goToLoginPage}
              className="signInButton"
            >
              SIGN IN
            </AuthButton>
            <RegisterText>
              <span> Don't have an account.</span>&nbsp;
              <button
                type="button"
                onClick={goToRegisterPage}
                className="registerButton"
                style={{ textDecoration: "underline" }}
              >
                <b>Create Account</b>
              </button>
            </RegisterText>
            <IconContainer>
              <LogoContainer>
                <div className="googlebtn mt-4">
                  <p>or sign in with</p>
                  <div className="d-flex btnclassfbscoail">
                    <div
                      onClick={() => doExternalRegister()}
                      className="blackround"
                    >
                      <a href="#">Google</a>
                    </div>
                  </div>
                </div>
              </LogoContainer>
            </IconContainer>
          </AuthContainer>
        </div>
      </section>
    </>
  );
};

const Container = styled.div`
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // width: 100vw;
  height: 100vh;
  min-height: 768px;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -75px;
`;

const LogoContainer = styled.div``;

const RegisterText = styled.div`
  font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #062a3b;
  margin-top: 16px;
  margin-bottom: 50px;
`;
const AuthHeader = styled.p`
  font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  color: #062a3b;
  margin-bottom: 27px;
`;

const AuthSubHeader = styled.span`
  font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: #050505;
  margin-bottom: 50px;
`;

const IconContainer = styled.div`
  p {
    font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #050505;
  }
  margin-top: -45px;
`;

export default DummyHome;
