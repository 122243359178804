//---------------------------------------------------------------------------------
//
// course/types.ts
//
//---------------------------------------------------------------------------------

import { type } from "os";

export const AuthClient_Name = "auth-client";
export const AuthService_Name = "auth-service";

export const CourseClient_Name = "course-client";
export const CourseService_Name = "course-service";

//---------------------------------------------------------------------------------

// export interface AuthClient_Data {
//   token: string;
//   fullName: string;
//   roles: Array<string>;
//   countryList: [];
// }

export type Course = {
  course: any;
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  courseId?: string;
};

export type Topic = {
  id: string;
  name: string;
  courseId: string;
  parentTopicId?: string;
  previousTopicId?: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  // NEW
  localOrder: number;
  sessionNumber: string;
  topic: any;
  status: string;
};

export type CoursesReturn = {
  courseList: Array<Course>;
};

export interface CourseClient_Data {
  courseList: Array<Course>;
  topicList: Array<Topic>;
  moduleList: Array<Topic>;
}

//---------------------------------------------------------------------------------

export enum AuthClient_States {
  Start = "start",
  // Not Authenticated States
  Validating = "validating",
  ValidateError = "validate-error",
  NotAuthenticated = "not-authenticated",
  LoggingIn = "logging-in",
  LoginError = "login-error",
  ValidateEmail = "validate-email",
  ValidatingEmail = "validating-email",
  ValidatingEmailError = "validating-email-error",
  ResettingPassword = "resetting-password",
  ResettingPasswordError = "resetting-password-error",
  RequestingPasswordReset = "requesting-password-reset",
  RequestingPasswordResetError = "requesting-password-reset-error",
  Registering = "registering",
  RegisterError = "register-error",
  CountryList = "get-country-list",
  CountryListError = "country-list-error",

  // Authenticated States
  Authenticated = "authenticated",
  LoggingOut = "logging-out",
  LogoutError = "logout-error",
  // TODO: Add change Profile.tsx accordingly
}

export enum CourseClient_States {
  Start = "start",
  // Not Authenticated States
  NotAuthenticated = "not-authenticated",
  // Authenticated States
  Authenticated = "authenticated",
  // Work in progress
  WIP = "wip",
  // Error state
  Err = "error",
}

//---------------------------------------------------------------------------------

// export enum AuthClient_Messages {
//   Login = "login",
//   Logout = "logout",
//   Validate = "validate",
//   RequestPasswordReset = "request-password-reset",
//   ResetPassword = "reset-password",
//   Register = "register",
//   ValidateEmail = "validate-email",
//   CancelValidateEmail = "cancel-validate-email",
//   Country = "country-list",
//   Echo = "echo",
// }

export enum CourseClient_Messages {
  GetCourses = "course-list",
  GetTopics = "topic-list",
  FetchModuleAndSession = "fetch-module-session",
  UpdateUserProgress = "update-user-progress",
  GetPreviousSession = "get-previous-session",
  CheckQuizResult = "check-quiz-result",
  getAssessment = "get-assessment",
  CheckAssessmentResult = "check-assessment-result",
  NavigateToQuiz = "navigate-to-quiz",
  AddNote = "add-note",
  EditNote = "edit-note",
  DeleteNote = "delete-note",
  DeleteBookmark = "delete-bookmark",
  AddBookmark = "add-bookmark",
  GetSingleUserNote = "get-single-user-note",
  GetSingleUserBookmark = "get-single-user-bookmark",
  GetUserNotes = "get-user-notes",
  GetUserBookMarks = "get-user-bookmarks",
  // NEW
  GetNextModule = "get-next-module",
  FetchUserCertificates = "fetch-user-certificate",
  FetchNotesBookmarks = "fetch-notes-bookmarks",
  CheckFreeTrialAndSubscription = "check-free-trial-subscription",
  GetProfileDetails = "get-profile-details",
}

//---------------------------------------------------------------------------------
