import React from "react";
import "./SetNewPassword.css";
import logoafmcart from "../../assets/logoafmcart.png";
import homeicon from "../../assets/homeicon.svg";
import imageOne from "../../assets/clarity_eye-show-solid.svg";
import "bootstrap/dist/css/bootstrap.min.css";

const SetNewPassword = () => {
  return (
    <section className="optlogin">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logoafmcart} alt="Logo" />
          </div>
          <div className="col-6 homeright">
            <a href="/">
              <img src={homeicon} alt="Home" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="titleofform">
              <h3>Set New Password</h3>
            </div>
            <div className="otpmain">
              <form className="createform">
                <div className="d-flex mbrow gap-20 justify-content-between">
                  <div className="formfield">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="d-flex mbrow gap-20 justify-content-between">
                  <div className="formfield">
                    <div className="positioneye">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                      />
                      <a>
                        <img src={imageOne} alt="Show Password" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex mbrow gap-20 justify-content-between">
                  <div className="formfield">
                    <div className="positioneye">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                      />
                      <a>
                        <img src={imageOne} alt="Hide Password" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex mbrow gap-20 justify-content-between">
                  <div className="formfield">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                </div>
                <div className="d-flex mbrow gap-20 justify-content-between">
                  <button className="sign-btn newpass">Save Password</button>
                  <button className="sign-btn newpass">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SetNewPassword;
