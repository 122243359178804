import { ask, getData, KeysAndValues } from "lib-js-c-modulo/index.mjs";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import { Pages } from "../../Pages";
import { any, z } from "zod";
import { useForm, zodResolver } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { PasswordInput, TextInput } from "@mantine/core";
import styled from "styled-components";
import banner2 from "../dummy/images/banner-2.jpg";
import NavBar from "../common/NavBar";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import eyeIcon from "../../assets/eyeIcon.svg";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";
import "./Login.css";
import banner from "../dummy/images/banner-2.jpg";
import logo from "../dummy/images/logo.png";
// import homeicon from "../dummy/images/homeicon.png";
import facebookgoogle from "../dummy/images/facebook&google.png";
import eyeiconSecond from "../dummy/images/eyeiconSecond.png";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import logoafmcart from "../../assets/logoafmcart.png";
import homeicon from "../../assets/homeicon.svg";
import imageOne from "../../assets/clarity_eye-show-solid.svg";
import imageTwo from "../../assets/close-eye.svg";
import "./NewLoginStyle.css";
import { config } from "../../config";
import { border, color } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../common/Loading";
const myName = "Login-gui";

interface loginData {
  email: string;
  password: string;
}

const DummyLogin = () => {
  const navigate = useNavigate();
  const loginElement = useFocusTrap();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // const loginSchema = z.object({
  //   email: z.string().email({ message: "Invalid email" }),
  //   password: z.string().min(4, { message: "password is Mandatory" }),
  // });

  const OnboradSignSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // const loginForm = useForm({
  //   schema: zodResolver(loginSchema),
  //   initialValues: {
  //     email: "",
  //     password: "",
  //   },
  // });

  const loginForm = useForm({
    initialValues: {
      // userType: userTypes.Individual,
      email: "",
      // firstName: "",
      // lastName: "",
      password: "",
      // confirmPassword: "",
      // phoneNumber: "",
      // termsAndConditions: false,
      // countryId: "",
    },
    // resolver: zodResolver(registerSchema),
  });

  // useEffect(() => {
  //   console.log(
  //     "USE EFFECT TRIGGER IN COURSE LANDING",
  //     navigationObject?.currentTopic
  //   );
  //   const navigationTopic = navigationObject?.currentTopic;
  //   if (navigationTopic?.topicType === "quiz") {
  //     if (navigationTopic?.localOrder > 1) {
  //       console.log("EFFECT navigate to quiz");
  //       navigate(
  //         `${Pages.QuizPlayer}/${navigationTopic?.courseId}/${navigationTopic?.parentTopicId}/${navigationTopic?.id}/${navigationTopic?.id}`,
  //         { state: navigationTopic }
  //       );
  //     } else {
  //       console.log("effect stay on course landing");
  //     }
  //   }
  //   if (navigationTopic?.topicType === "html") {
  //     if (navigationTopic?.localOrder > 1) {
  //       console.log("EFFECT navigate to html");
  //       navigate(
  //         `${Pages.Player}/${navigationTopic?.courseId}/${navigationTopic?.parentTopicId}/${navigationTopic?.id}/${navigationTopic?.id}`,
  //         { state: navigationTopic }
  //       );
  //     } else {
  //       console.log("effect stay on course landing");
  //     }
  //   }
  // }, []);

  const doLogin = useCallback(
    async (values: loginData) => {
      console.log("login data called", values);
      setLoading(true);
      try {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.Login,
          payload: {
            email: values.email,
            password: values.password,
          },
        });
        console.log("result!.error", result!.error);
        if (!result!.error) {
          if (result!.payload !== undefined) {
            if ((result!.payload as KeysAndValues).isOtpRequired) {
              console.log(
                "result payload",
                (result!.payload as KeysAndValues).isOtpRequired
              );

              navigate(
                `${Pages.ValidateEmail}/${encodeURIComponent(
                  result!.payload.user.email
                )}`
              );
            } else {
              const navigationObject = (
                getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
              ).navigationObject;
              console.log(
                "EFFECT NAVIGATION OBJECT AFTER LOGIN",
                navigationObject
              );
              const navigationTopic = navigationObject?.currentTopic;
              const courseId = navigationTopic?.courseId;
              const moduleId = navigationTopic?.parentTopicId;
              const sessionId = navigationTopic?.id;

              if (navigationObject?.isCorporateAdmin) {
                console.log("EFFECT CORPORATE ADMIN ROUTE");
                navigate(Pages.CompanyInfo);
              } else if (navigationTopic?.topicType === "quiz") {
                // if (navigationTopic?.localOrder > 1) {
                //   console.log("EFFECT navigate to quiz");
                //   const navigationToQuiz = async () => {
                //     try {
                //       let result = await ask({
                //         from: "Dashboard-gui",
                //         to: CourseClient_Name,
                //         payload: {
                //           course: courseId,
                //           module: moduleId,
                //           session: sessionId,
                //           item: navigationTopic,
                //         },
                //         message: CourseClient_Messages.NavigateToQuiz,
                //       });
                //       console.log("RESULT FROM NAVIGATE QUIZ", result);
                //       navigate(
                //         `${Pages.QuizPlayer}/${courseId}/${moduleId}/${sessionId}/${sessionId}`,
                //         { state: navigationObject?.quizTopic }
                //       );
                //     } catch (error) {
                //       console.log("Caught error", error);
                //     }
                //   };
                //   navigationToQuiz();
                // } else {
                console.log("effect stay on course landing");
                navigate(`${Pages.Dashboard}?existing=${true}`);
                // }
              } else if (navigationTopic?.topicType === "html") {
                // if (navigationTopic?.localOrder > 1) {
                //   console.log("EFFECT navigate to html");
                //   navigate(
                //     `${Pages.Player}/${navigationTopic?.courseId}/${navigationTopic?.parentTopicId}/${navigationTopic?.id}/${navigationTopic?.id}`,
                //     { state: navigationTopic }
                //   );
                // } else {
                console.log("effect stay on course landing");
                navigate(`${Pages.Dashboard}?existing=${true}`);
                // }
              }
            }
          }
        } else {
          setErrorMessage(true);
          console.log("errorMessage", result!.error);
        }
      } catch (err: any) {
        setErrorMessage(true);
        navigate(Pages.Login);
      }
      setLoading(false);
    },
    [navigate, setErrorMessage]
  );

  const doExternalRegister = async () => {
    let url = config.moduloApiEndPoint;
    let trimmedUrl = url.substring(url.length - 7, -1);
    // alert(trimmedUrl);
    try {
      // let response = await axios.get(`${trimmedUrl}login/google`);
      // let response = await axios.get(`${trimmedUrl}stripe-test`);
      window.open(`${trimmedUrl}/login/google`, "_self");
      // console.log("response is", response);
    } catch (error) {
      console.log("Error is", error);
    }
  };
  const handleClose = () => {
    setErrorMessage(false);
  };
  return (
    // <Container id="container">
    //   {/* <NavBar /> */}
    //   <img src={logo} alt="" id="logo" />
    //   <img src={homeicon} alt="" id="homeicon" />
    //   <form
    //     onSubmit={loginForm.onSubmit((values: any) => doLogin(values))}
    //     ref={loginElement}
    //   >
    //     <FormHeader id="sign_header">SIGN IN</FormHeader>
    //     <p>
    //       Need an account?
    //       <span
    //         onClick={() => navigate(Pages.Register)}
    //         style={{
    //           fontWeight: "bold",
    //           textDecoration: "underline",
    //           cursor: "pointer",
    //         }}
    //       >
    //         {" "}
    //         Get Started
    //       </span>
    //     </p>
    //     <TextInput
    //       placeholder="Email Address"
    //       {...loginForm.getInputProps("email")}
    //       data-autofocus
    //       style={{ marginBottom: "10px" }}
    //       id="email_input"
    //     />
    //     {/* <div className="inputContainer"> */}
    //     {/* <img src={eyeiconSecond} alt="" id="eyeiconSecond" /> */}
    //     <TextInput
    //       type="password"
    //       placeholder="Password"
    //       {...loginForm.getInputProps("password")}
    //       data-autoFocus
    //       style={{ marginBottom: "10px" }}
    //       id="password_input"
    //     />
    //     {/* <div className="mantine-17m0bg2"></div> */}
    //     <Link to={Pages.RequestResetPassword} id="forget">
    //       Forgot Password ?
    //     </Link>
    //     {/* </div> */}
    //     <AuthButtonContainer>
    //       <AuthButton type="submit" style={{ marginTop: "28px" }} id="sign_btn">
    //         SIGN IN
    //       </AuthButton>

    //       {/* <AuthButton
    //         type="button"
    //         style={{ marginTop: "28px" }}
    //         onClick={() => navigate(Pages.Home)}
    //       >
    //         BACK
    //       </AuthButton> */}
    //     </AuthButtonContainer>
    //     <div id="last_div">
    //       <h5 id="sign_text">Sign In</h5>
    //       <img src={facebookgoogle} alt="" id="fb_google_img" />
    //     </div>
    //   </form>
    // </Container>

    <section className="optlogin">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logoafmcart} alt="Logo" />
          </div>
          <div className="col-6 homeright">
            <a>
              <img
                src={homeicon}
                alt="Home Icon"
                onClick={() => navigate(Pages.Others)}
                style={{ cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="titleofform">
              <h3>Sign In</h3>
            </div>
            <div className="d-flex justify-content-center otpcontent text-center mt-0">
              <p>
                Need an account?{" "}
                <a href="#" onClick={() => navigate(Pages.Register)}>
                  {" "}
                  Get started!
                </a>
              </p>
            </div>
            <div className="otpmain">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={OnboradSignSchema}
                onSubmit={(values, formikHelpers) => {
                  doLogin(values);
                }}
              >
                <Form className="createform">
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div
                      className="formfield mt-0 errdiv"
                      style={{
                        display: errorMessage ? "block" : "none",
                      }}
                    >
                      <Field
                        type="text"
                        name="text"
                        readOnly
                        className="form-control errortext"
                        value="Incorrect email or password"
                        style={{
                          color: "#cb321e",
                          fontwsize: "14px",
                          border: "1px solid #cb321e",
                        }}
                      />
                      <CloseIcon
                        onClick={handleClose}
                        className="close-icon"
                        style={{ fontSize: 20, color: "black" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <div className="positioneye">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="form-control"
                          placeholder="Password"
                        />
                        <a>
                          <img
                            src={showPassword ? imageTwo : imageOne}
                            alt="Show Password"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end otpcontent">
                    <p>
                      <a
                        href="#"
                        onClick={() => navigate(Pages.RequestResetPassword)}
                      >
                        Forgot password?
                      </a>
                    </p>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-center">
                    <button
                      type={loading ? "button" : "submit"}
                      className="sign-btn"
                    >
                      {loading ? <Loading /> : "Sign In"}
                    </button>
                  </div>
                  <div className="googlebtn mt-4">
                    <p>or sign in with</p>
                    <div className="d-flex btnclassfbscoail">
                      <div
                        onClick={() => doExternalRegister()}
                        className="blackround"
                      >
                        <a href="#">Google</a>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background-image: url(${banner2});
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 768px;
//   overflow-y: scroll;

//   form {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 65%;
//   }

// input {
//   background: #ffffff;
//   border: 1px solid #c7b277;
//   border-radius: 5px;
//   width: 450px;
//   height: 50px;
//   font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
//   color: #050505;
//   font-size: 18px;

//   ::placeholder {
//     color: #050505;
//   }
// }

//   .inputContainer {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     margin-bottom: 15px;

//     a {
//       font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
//       font-style: normal;
//       font-weight: 400;
//       font-size: 15px;
//       line-height: 21px;
//       letter-spacing: 0.02em;
//       color: #050505;
//     }
//   }

//   .mantine-y0fwdk,
//   .mantine-wqgzfp {
//     width: 450px;
//     height: 65px !important;
//     margin-bottom: 15px;
//   }
//   .mantine-o3oqoy {
//     bottom: 25px;
//   }
//   .mantine-17m0bg2 {
//     color: #ffffff;
//     background-image: url(${eyeIcon});
//     background-repeat: no-repeat;
//     background-color: transparent;

//     svg {
//       display: none;
//     }

//     &:hover {
//       color: #ffffff !important;
//     }
//   }
//   .mantine-wqgzfp {
//     border: none;
//     background: none;
//   }

//   @media (max-width: 576px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 400px;
//       height: 55px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 400px;
//       height: 55px !important;
//     }
//   }
//   @media (max-width: 424px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 370px;
//       height: 45px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 370px;
//       height: 45px !important;
//     }
//   }
//   @media (max-width: 376px) {
//     .mantine-y0fwdk,
//     .mantine-wqgzfp {
//       width: 290px;
//       height: 40px !important;
//       margin-bottom: 15px;
//     }

//     input {
//       width: 290px;
//       height: 40px !important;
//     }
//   }
// `;

// const FormHeader = styled.div`
//   font-family: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
//   font-style: normal;
//   font-weight: 300;
//   font-size: 24px;
//   line-height: 21px;
//   text-align: center;
//   letter-spacing: 0.01em;
//   text-transform: uppercase;
//   color: #050505;
//   margin-bottom: 30px;
// `;

export default DummyLogin;
