export enum SubscriptionClient_States {
  Start = "start",
  // Not Authenticated States
  NotAuthenticated = "not-authenticated",
  // Authenticated States
  Authenticated = "authenticated",
  // Work in progress
  WIP = "wip",
  // Error state
  Err = "error",
}

export interface SubscriptionClient_Data {
  // courseList: Array<Course>;
  // topicList: Array<Topic>;
  // moduleList: Array<Topic>;
}

export const SubscriptionClient_Name = "subscription-client";
export const SubscriptionService_Name = "subscription-service";

export enum SubscriptionClient_Messages {
  AddSubscription = "add-subscription",
  CreateCheckoutSession = "create-checkout-session",
  VerifyPayment = "verify-payment",
  CreatePaymentIntent = "create-payment-intent",
}

export enum SubscriptionType {
  Individual = "individual",
  Company = "company",
}
