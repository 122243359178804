import logo from "../../assets/logo.png";
import styled from "styled-components";

const NavBar = () => {
  return (
    <NavBarContainer>
      <img src={logo} alt="website logo"></img>
      <button>Home</button>
    </NavBarContainer>
  );
};

const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 34px;
  height: auto;

  a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #062a3b;
  }

  button {
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    img {
      width: 80px;
    }
    align-items: center;
    padding-top: 30px;
  }
`;

export default NavBar;
