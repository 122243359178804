import { useLocation, useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { Pages } from "../../Pages";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";
import { ask } from "lib-js-c-modulo/index.mjs";

const PaymentGateway = () => {
  const navigate = useNavigate();
  const { subscriptionType } = useParams();
  const location = useLocation();
  const myObject = location.state;
  console.log(
    "Effect my object and subscription type in payment gateway",
    myObject,
    subscriptionType
  );

  const failedPayment = async (course: any, subscriptionType: any) => {
    navigate(`${Pages.PaymentFailed}/${subscriptionType}`, { state: course });
  };

  const myName = "payment-gateway";

  const addSubscription = async (course: any, subscriptionType: any) => {
    try {
      let result = await ask({
        from: myName,
        to: SubscriptionClient_Name,
        payload: { course, subscriptionType },
        message: SubscriptionClient_Messages.AddSubscription,
      });
      console.log(
        "effect RESULT IS",
        result?.payload?.result?.payload?.subscription
      );
      const isSubscribed = result?.payload?.result?.payload?.subscription;
      if (isSubscribed) {
        navigate(`${Pages.PaymentSuccess}/${subscriptionType}`, {
          state: course,
        });
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>Payment Gateway</p>
      </div>

      <PaymentGatewayContainer>
        {/* <div>PaymentGateway</div> */}
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            flexDirection: "row",
          }}
        >
          <SuccessButton
            onClick={() => addSubscription(myObject, subscriptionType)}
          >
            Success
          </SuccessButton>
          <FailureButton
            onClick={() => failedPayment(myObject, subscriptionType)}
          >
            Failure
          </FailureButton>
        </div>
      </PaymentGatewayContainer>
    </>
  );
};

// Define the styled components
const PaymentGatewayContainer = styled.div`
  /* Styles for the container */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessButton = styled.button`
  /* Styles for the success button */
  background-color: green;
  color: white;
`;

const FailureButton = styled.button`
  /* Styles for the failure button */
  background-color: red;
  color: white;
`;

export default PaymentGateway;
