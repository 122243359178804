import { useLocation, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import swal from "sweetalert";
import { Pages } from "../../Pages";
import { KeysAndValues, ask, getData } from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import {
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  Topic,
} from "../../actors/course/types";
import { useState, useEffect } from "react";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import Loading from "../common/Loading";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";

const myName = "Dashboard-gui";

const DummyQuizPlayer = () => {
  const [jsonQuizObject, setJsonQuizObject] = useState<any>({});
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [userSubmittedAnswers, setUserSubmittedAnswers] = useState<any>([]);

  interface NavigationModule {
    parentTopicId: string;
    id: string;
  }

  interface NavigationQuizResult {
    pass: boolean;
    nextModule?: NavigationModule | false;
    assessment?: boolean;
  }

  const [menuLinks, setMenuLinks] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<Topic[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [navigationQuizResult, setNavigationQuizResult] =
    useState<NavigationQuizResult>();
  const [loading, setLoading] = useState(false);

  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const { courseId, moduleId, sessionName, sessionId } = useParams();
  const location = useLocation();
  const myObject = location.state;
  console.log("MY OBJECT FROM NAVIGATE PARAMS INSIDE QUIZ PLAYER", myObject);
  console.log(
    "FROM QUIZ PLAYER SESSION NAME, CID, MID, SID",
    sessionName,
    courseId,
    moduleId,
    sessionId
  );

  //   console.log("MY OBJECT QUIZ STRING", myObject?.topic?.quiz);
  useEffect(() => {
    setIsQuizSubmitted(false);
    setLoading(false);
    let jsonQuiz: { questions: any[] };
    if (myObject) {
      let quizString = myObject?.topic?.quiz;
      let jsonQuiz = JSON.parse(quizString);
      setJsonQuizObject(jsonQuiz);
      console.log("JSON QUIZ", jsonQuiz?.questions);
    }
    if (myObject === null) {
      console.log("MY OBJECT NULL CALLED");
      const quizTopic = (getData(CourseClient_Name) as KeysAndValues as any)
        .item;
      const all = getData(CourseClient_Name) as KeysAndValues as any;
      console.log("MY OBJECT NULL CALLED 22", quizTopic, all);
      let quizString = quizTopic?.topic?.quiz;
      console.log("MY OBJECT NULL CALLED 33", quizString);
      let jsonQuiz = JSON.parse(quizString);
      setJsonQuizObject(jsonQuiz);
      console.log("MY OBJECT JSON QUIZ", jsonQuiz?.questions);
    }
  }, [myObject]);

  const doLogout = async () => {
    console.log("Do logout in quiz player called");
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Home);
    }
  };

  const handleFormSubmit = async (event: any) => {
    setLoading(true);
    setErr("");
    event.preventDefault();
    console.log("FORM SUBMISSION", event);
    const form = event.target;
    const formData = new FormData(form);

    console.log("FORM SUBMISSION");
    const entries = Array.from(formData.entries());
    let submittedAnswers: FormDataEntryValue[] = [];
    for (let [name, value] of entries) {
      console.log(name, value);
      submittedAnswers.push(value);
    }
    console.log("SUBMITTED ANSWERS AND SESSION", submittedAnswers, sessionId);

    setUserSubmittedAnswers(submittedAnswers);

    if (submittedAnswers?.length === jsonQuizObject?.questions?.length) {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: { submittedAnswers, sessionId, courseId, moduleId },
          message: CourseClient_Messages.CheckQuizResult,
        });
        const quizResult = result?.payload?.returnValue;
        console.log("RESULT FROM CHECK QUIZ RESULT", quizResult);
        setCorrectAnswers(quizResult?.correctAnswers);
        setNavigationQuizResult(quizResult);
      } catch (error) {
        console.log("ERROR IS", error);
      }
      setIsQuizSubmitted(true);
    } else {
      setErr("Please answer all the questions");
      console.log("please answer all questions");
      // swal("Hello world!");
      swal({
        title: "Please answer all the questions",
        // text: "Are you sure that you want to leave this page?",
        icon: "warning",
        dangerMode: true,
      });
    }
    setLoading(false);
  };

  const navigateFromQuiz = async () => {
    setLoading(true);
    // if (
    //   navigationQuizResult?.pass === false &&
    //   navigationQuizResult?.nextModule === false &&
    //   navigationQuizResult?.assessment === false
    // ) {
    //   navigate(Pages.TryAgain);
    // }
    console.log("Correct navigation called", navigationQuizResult);
    if (
      // navigationQuizResult?.pass === true &&
      navigationQuizResult?.nextModule &&
      navigationQuizResult?.assessment === false
    ) {
      console.log("Correct NAVIGATE TO COURSE LANDING");
      // navigate(
      //   `${Pages.Player}/${courseId}/${navigationQuizResult?.nextModule?.parentTopicId}/${navigationQuizResult?.nextModule?.id}/${navigationQuizResult?.nextModule?.id}`,
      //   { state: navigationQuizResult?.nextModule }
      // );
      navigate(
        `${Pages.CourseLanding}/${courseId}?currentModuleId=${navigationQuizResult?.nextModule?.parentTopicId}`
      );
    }
    if (
      // navigationQuizResult?.pass === true &&
      navigationQuizResult?.nextModule === false &&
      navigationQuizResult?.assessment === true
    ) {
      console.log("Correct NAVIGATE TO ASSESSMENT");
      navigate(`${Pages.Assessment}/${courseId}`);
    }
    setLoading(false);
  };

  const courseData = getData(
    CourseClient_Name
  ) as KeysAndValues as CourseClient_Data;

  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  console.log("COURSE DATA", courseData);

  const topics = courseData?.moduleList;

  const activateSubmenu = (topicId: any) => {
    if (!showSubMenu) {
      console.log("TOPIC ACTIVATION VALUE", topicId);
      setActiveSubMenu(topicId);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      sessions && setSubMenuItems(sessions);
      setShowSubMenu(true);
    } else if (subMenuItems && showSubMenu) {
      setActiveSubMenu(null);
      setShowSubMenu(false);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      if (
        sessions.every((element: any) =>
          subMenuItems.includes(element as never)
        )
      ) {
        setShowSubMenu(false);
      } else {
        sessions && setSubMenuItems(sessions);
        setShowSubMenu(true);
        setActiveSubMenu(topicId);
      }
    } else {
      setShowSubMenu(false);
      setActiveSubMenu(null);
    }
  };

  const navigateToQuiz = async (
    course: string,
    module: string,
    session: string,
    item: any
  ) => {
    console.log("COURSE, MODULE, SESSION", course, module, session);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { course, module, session, item },
        message: CourseClient_Messages.NavigateToQuiz,
      });
      console.log("RESULT FROM NAVIGATE QUIZ", result);
      navigate(
        `${Pages.QuizPlayer}/${course}/${module}/${session}/${session}`,
        {
          state: item,
        }
      );
    } catch (error) {
      console.log("Caught error", error);
    }
  };
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // const handleResize = () => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
    // };

    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-12 p-0 mytogclass ${
              isSidebarOpen ? "opened" : ""
            }`}
          >
            <div className="toggleitem">
              <div className="custombar" onClick={toggleSidebar}>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
                <span
                  className={`custom_bar ${
                    isSidebarOpen ? "custom_bar_close" : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0 mymainparthtml">
            <CoursePlayerSidebar
              sessionId={sessionId}
              courseId={courseId}
              isSidebarOpen={isSidebarOpen}
            />
            {/* <div className="sidebar">
              <div className="innersider">
                <img className="avilogo" src={avilogo} alt="Logo" />
                <div className="username">
                  <div className="usericon">
                    <img src={userIcon} alt="User Icon" />
                  </div>
                  <div className="usertext">
                    <h4>{fullName}</h4>
                    <h6>
                      <span>Status:</span>{" "}
                      <span className="Active">Active</span>{" "}
                      <span>/ Plan:</span> Free
                    </h6>
                  </div>
                </div>
                <div className="viewprofile">
                  <button
                    onClick={() => navigate(Pages.Profile)}
                    className="goldenbtn"
                  >
                    View Profile
                  </button>
                </div>
                <div className="processbar">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: "10%", backgroundColor: "#C7B277" }}
                      role="progressbar"
                      aria-valuenow={10}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <span>1% Complete</span>
                </div>
                <div className="buttonmargin">
                  <button
                    className="goldenbtn"
                    onClick={() => setMenuLinks(true)}
                  >
                    COURSE OUTLINE
                  </button>
                </div>
                <div className="quickmenulinks">
                  <h2>MENU</h2>
                  <ul>
                    <li>
                      <a href="" onClick={() => navigate(Pages.Others)}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="" onClick={() => navigate(Pages.Notes)}>
                        Notes
                      </a>
                    </li>
                    <li>
                      <a href="" onClick={() => navigate(Pages.Bookmarks)}>
                        BOOKMARKS
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        onClick={() => navigate(Pages.CertificateList)}
                      >
                        View Certificate
                      </a>
                    </li>
                    <li>
                      <a href="">Help</a>
                    </li>
                    <li onClick={doLogout}>
                      <a href="#">Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <!-- End leftmenu Blue Part --> */}
            {/* <!-- start White Part --> */}
            <div className="mainbody">
              <div className="">
                {/* <!-- Start sticky icon --> */}
                {/* <div className="stikeybutton">
                <div className="sitckyinner">
                  <div>
                    <button className="stikeybuttons addbtn">
                      <img src={addNote} />
                      Add notes
                    </button>
                  </div>
                  <div>
                    <button className="stikeybuttons bookbtn">
                      <img src={bookMark} />
                      BOOKMARK
                    </button>
                  </div>
                </div>
              </div> */}
                {/* <!--End  sticky icon --> */}
                <div className="contentpart">
                  <div className="titlehead">
                    <h2>QUIZ</h2>
                    <h4>ANSWER ALL OF THE QUESTIONS BELOW.</h4>
                    <h4>
                      Disclaimer: All correct answers will be marked green. All
                      wrong answers will be marked red.
                    </h4>
                  </div>
                  {jsonQuizObject ? (
                    <form onSubmit={handleFormSubmit}>
                      <div className="quediv">
                        <div className="queanswer">
                          {jsonQuizObject?.questions?.map(
                            (question: any, index: any) => (
                              <>
                                <p className="mt-0">
                                  {question?.index}. {question?.question}
                                </p>
                                {!isQuizSubmitted
                                  ? Object.entries(question?.choices).map(
                                      ([choiceNumber, choiceText]) => (
                                        <div className="form-check">
                                          {/* <div className="form-check-new"> */}
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`question-${question?.index}`}
                                            id="flexRadioDefault1"
                                            value={parseInt(choiceNumber) + 1}
                                            onClick={(
                                              e: React.MouseEvent<HTMLInputElement>
                                            ) =>
                                              console.log(
                                                e?.currentTarget?.value
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            key={choiceNumber}
                                            // style={{ color: "red" }}
                                          >
                                            {choiceText as string}
                                          </label>
                                        </div>
                                      )
                                    )
                                  : Object.entries(question?.choices).map(
                                      ([choiceNumber, choiceText]) => (
                                        <div
                                          // className={
                                          //   correctAnswers[parseInt(index)] ===
                                          //   userSubmittedAnswers[
                                          //     parseInt(index)
                                          //   ]
                                          //     ? "form-check"
                                          //     : "form-check-new"
                                          // }
                                          className="form-check"
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`question-${question?.index}`}
                                            id="flexRadioDefault1"
                                            value={parseInt(choiceNumber) + 1}
                                            onClick={(
                                              e: React.MouseEvent<HTMLInputElement>
                                            ) =>
                                              console.log(
                                                e?.currentTarget?.value
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            key={choiceNumber}
                                            style={{
                                              color:
                                                correctAnswers[
                                                  parseInt(index)
                                                ] ===
                                                (
                                                  parseInt(choiceNumber) + 1
                                                ).toString()
                                                  ? "green"
                                                  : "orangered",
                                              fontSize:
                                                correctAnswers[
                                                  parseInt(index)
                                                ] ===
                                                (
                                                  parseInt(choiceNumber) + 1
                                                ).toString()
                                                  ? "14px"
                                                  : "13px",
                                              fontWeight:
                                                correctAnswers[
                                                  parseInt(index)
                                                ] ===
                                                (
                                                  parseInt(choiceNumber) + 1
                                                ).toString()
                                                  ? "500"
                                                  : "400",
                                            }}
                                          >
                                            <>{choiceText as string}</>
                                          </label>
                                        </div>
                                      )
                                    )}
                                {/* {} */}
                              </>
                            )
                          )}
                        </div>
                      </div>
                      <div className="finishsection text-end mt-4">
                        {!isQuizSubmitted ? (
                          !loading ? (
                            <button
                              type="submit"
                              className="goldenbtn whitetext"
                            >
                              Finish Quiz
                            </button>
                          ) : (
                            <button
                              // onClick={() => navigateFromQuiz()}
                              className="goldenbtn whitetext"
                            >
                              <Loading />
                            </button>
                          )
                        ) : !loading ? (
                          <button
                            onClick={() => navigateFromQuiz()}
                            className="goldenbtn whitetext"
                          >
                            Continue
                          </button>
                        ) : (
                          <button
                            // onClick={() => navigateFromQuiz()}
                            className="goldenbtn whitetext"
                          >
                            <Loading />
                          </button>
                        )}
                      </div>
                    </form>
                  ) : (
                    <p>Loading quiz data...</p>
                  )}

                  {/* <div className="col-12 mt-5">
                  <div className="d-flex mobilebtn justify-content-between">
                    <button className="bluebtn whitetext cunsumflex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="11"
                        viewBox="0 0 5 11"
                        fill="none"
                      >
                        <path
                          d="M4.86648 0.151862C4.79117 0.0659041 4.69189 0.0128926 4.58592 0.00206323C4.47995 -0.00876615 4.37402 0.0232733 4.28658 0.0925993L4.22296 0.151863L0.132759 4.83056C0.0576132 4.91671 0.01127 5.03028 0.00180279 5.15149C-0.00766444 5.27271 0.0203442 5.39388 0.0809502 5.4939L0.13276 5.56668L4.22296 10.2454C4.30312 10.3375 4.41053 10.3919 4.52402 10.3979C4.63751 10.4039 4.74886 10.3611 4.83611 10.2778C4.92336 10.1946 4.9802 10.077 4.99541 9.94821C5.01062 9.81942 4.98311 9.68878 4.91829 9.58204L4.86649 9.50926L1.09714 5.19862L4.86648 0.887978C4.90881 0.839688 4.94239 0.782321 4.9653 0.719164C4.98821 0.656006 5 0.588299 5 0.51992C5 0.451541 4.98821 0.383834 4.9653 0.320677C4.94239 0.257519 4.90881 0.200153 4.86648 0.151862Z"
                          fill="white"
                        />
                      </svg>
                      <span>previous Session</span>
                    </button>
                    <button className="goldenbtn whitetext">
                      Mark complete
                    </button>
                    <button className="bluebtn whitetext cunsumflex">
                      <span>Next Session</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="11"
                        viewBox="0 0 5 11"
                        fill="none"
                      >
                        <path
                          d="M0.133516 0.151862C0.208827 0.0659041 0.308114 0.0128926 0.414081 0.00206323C0.520048 -0.00876615 0.625977 0.0232733 0.713416 0.0925993L0.777041 0.151863L4.86724 4.83056C4.94239 4.91671 4.98873 5.03028 4.9982 5.15149C5.00766 5.27271 4.97966 5.39388 4.91905 5.4939L4.86724 5.56668L0.77704 10.2454C0.696882 10.3375 0.589473 10.3919 0.47598 10.3979C0.362487 10.4039 0.251142 10.3611 0.163889 10.2778C0.0766357 10.1946 0.0198031 10.077 0.00459121 9.94821C-0.0106207 9.81942 0.0168916 9.68878 0.0817059 9.58204L0.133515 9.50926L3.90286 5.19862L0.133516 0.887978C0.0911934 0.839688 0.0576147 0.782321 0.0347037 0.719164C0.0117927 0.656006 -9.89199e-08 0.588299 -8.74223e-08 0.51992C-7.59247e-08 0.451541 0.0117928 0.383834 0.0347038 0.320677C0.0576148 0.257519 0.0911935 0.200153 0.133516 0.151862Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer part --> */}
        <div className="col-md-12 p-0">
          <footer>
            <div className="footertext">
              <div>
                {/* <p>
                  © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                </p> */}
                <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
              </div>
              <div className="quicklinks">
                <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* {menuLinks && (
        <div
          id="mySidenav"
          style={{
            width: "700px",
            transform: "translateX(0)", // Initially hidden
            transition: "transform 1s ease-in-out",
          }}
          className="sidenav mymenusuv"
        >
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => setMenuLinks(false)}
          >
            &times;
          </a>
          <div className="menulinks">
            {topics.map((item) =>
              item?.topic?.parentTopicId === null ? (
                item.status === "in-progress" ? (
                  <ul>
                    <li>
                      <a
                        onClick={() => activateSubmenu(item?.topic?.id)}
                        style={{ cursor: "pointer" }}
                        className={
                          activeSubMenu === item?.topic?.id ? "activelink" : ""
                        }
                      >
                        {item?.topic?.name}
                      </a>
                      {activeSubMenu === item?.topic?.id && // Check if the active submenu matches the current topic's ID
                        subMenuItems &&
                        subMenuItems.map((subMenuItem) =>
                          subMenuItem?.status === "in-progress" ? (
                            subMenuItem?.topic?.topicType === "html" ? (
                              <ul className="submenu">
                                <li
                                  onClick={() =>
                                    navigate(
                                      `${Pages.Player}/${courseId}/${item?.topic?.id}/${subMenuItem?.topic?.id}/${subMenuItem?.topic?.id}`,
                                      { state: item }
                                    )
                                  }
                                >
                                  <a href="#">{subMenuItem?.topic?.name}</a>
                                </li>
                              </ul>
                            ) : (
                              courseId &&
                              item?.topic?.id &&
                              subMenuItem?.topic?.id &&
                              item && (
                                <ul className="submenu">
                                  <li
                                    onClick={() =>
                                      navigateToQuiz(
                                        courseId,
                                        item?.topic?.id,
                                        subMenuItem?.topic?.id,
                                        subMenuItem
                                      )
                                    }
                                  >
                                    <a href="#">{subMenuItem?.topic?.name}</a>
                                  </li>
                                </ul>
                              )
                            )
                          ) : (
                            <ul className="submenu">
                              <li>
                                <a href="#" className="disablelink">
                                  {subMenuItem?.topic?.name}
                                </a>
                              </li>
                            </ul>
                          )
                        )}
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a className="disablelink">{item?.topic?.name}</a>
                    </li>
                  </ul>
                )
              ) : (
                <ul></ul>
              )
            )}
          </div>
        </div>
      )} */}
    </>
  );
};

export default DummyQuizPlayer;
