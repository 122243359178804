import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ask, getData, KeysAndValues } from "lib-js-c-modulo/index.mjs";
import { any, z } from "zod";
import "./style.css";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
  ExternalLoginType,
} from "../../actors/auth/types";
import { Pages } from "../../Pages";
import NavBar from "../common/NavBar";
import { AuthButton, AuthButtonContainer } from "../common/styles";
import banner from "../dummy/images/banner-2.jpg";
import eyeIcon from "../../assets/eyeIcon.svg";
import {
  CourseClient_Data,
  CourseClient_Name,
} from "../../actors/course/types";
import eyeiconFirst from "../dummy/images/eyeiconFirst.png";
import eyeiconSecond from "../dummy/images/eyeiconSecond.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Register.css";
import logo from "../../assets/loginbg.png";
import homeicon from "../../assets/homeicon.svg";
import facebookgoogle from "../dummy/images/facebook&google.png";
import imageone from "../../assets/clarity_eye-show-solid.svg";
import logoafmcart from "../../assets/logoafmcart.png";
import closeEye from "../../assets/close-eye.svg";
import CloseIcon from "@mui/icons-material/Close";
import { isWidthDown } from "@material-ui/core";
import axios from "axios";
import { config } from "../../config";
import Loading from "../common/Loading";
// import Register from "../auth/Register";

const myName = "Register-gui";

interface registerData {
  userType: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  termsAndConditions: boolean;
  countryId: string;
}

const Register = () => {
  const navigate = useNavigate();
  // const registerElement = useFocusTrap();

  const [isdCode, setIsdCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [registerClicked, setRegisterClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const authData = getData(AuthClient_Name) as KeysAndValues as AuthClient_Data;

  const countries = authData.countryList;
  console.log("Countries", countries);

  const userTypes = {
    Individual: "individual",
    Corporate: "corporate",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "You must accept the Terms & Conditions"
    ),
  });

  const initialValues = {
    userType: userTypes.Individual,
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "IND",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  const handleClose = () => {
    setErrorMessage("");
  };
  const doRegister = useCallback(
    async (values: registerData) => {
      setLoading(true);
      console.log("do register called", values);
      try {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          message: AuthClient_Messages.Register,
          payload: {
            userType: values.userType,
            lastName: values.lastName,
            firstName: values.firstName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            password: values.password,
            confirmPassword: values.confirmPassword,
            countryId: values.countryId,
          },
        });
        console.log("Result after registration", result);
        if (!result!.error) {
          if (result!.payload?.state === "registering") {
            navigate(
              `${Pages.ValidateEmail}/${encodeURIComponent(values.email)}`
            );
          }
        } else {
          setErrorMessage(result!.error);
          console.log("errorMessage", result!.error);
        }
      } catch (err: any) {
        setErrorMessage(err);
        navigate(Pages.Register);
      }
      setLoading(false);
    },
    [navigate]
  );

  const registerCSS = () => {
    setRegisterClicked(true);
  };

  const extraFunction = (values: registerData) => {
    console.log("values", values);
  };

  const handleSubmit = (values: any, { resetForm }: any) => {
    // Handle form submission logic here
    console.log(values, "handlesubmittttttt");
    doRegister(values);
    resetForm();
  };

  const scrollToSection = (sectionId: any) => {
    // const section = document.getElementById(sectionId);
    // if (section) {
    //   section.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "nearest",
    //   });
    // }
  };

  const doExternalRegister = async (mode: ExternalLoginType) => {
    let url = config.moduloApiEndPoint;
    let trimmedUrl = url.substring(url.length - 7, -1);
    console.log("Trimmed", trimmedUrl);
    // alert(trimmedUrl);
    try {
      // let response = await axios.get(`${trimmedUrl}login/google`);
      // let response = await axios.get(`${trimmedUrl}stripe-test`);
      if (mode === ExternalLoginType.Google) {
        window.open(`${trimmedUrl}/login/google`, "_self");
      }
      if (mode === ExternalLoginType.Facebook) {
        window.open(
          `${trimmedUrl}login/facebook?=${window.location.origin}`,
          "_self"
          // `https://af5a-125-99-240-64.ngrok-free.app/login/facebook?=${window.location.origin}`,
          // "_self"
        );
      }
      // console.log("response is", response);
    } catch (error) {
      console.log("Error is", error);
    }
  };

  return (
    // <div id="registrationSection" style={{ overflow: "auto" }}>
    //   <section className="desklogin">
    //     <div className="container-fluid">
    //       <div className="row row align-items-start">
    //         <div className="col-6 cartlogoleft">
    //           <img src={logoafmcart} alt="Logo" />
    //         </div>
    //         <div className="col-6 homeright">
    //           <a
    //             onClick={() => navigate(Pages.Dashboard)}
    //             style={{ cursor: "pointer" }}
    //           >
    //             <img src={homeicon} alt="Home" />
    //           </a>
    //         </div>
    //       </div>
    //       <div className="row" style={{ marginTop: "-60px" }}>
    //         <div className="col-md-12">
    //           <div className="titleofform">
    //             <h3>CREATE your ACCOUNT</h3>
    //           </div>
    //           <div className="formmain" style={{ zIndex: 5 }}>
    //             <Formik
    //               initialValues={initialValues}
    //               validationSchema={validationSchema}
    //               onSubmit={handleSubmit}
    //             >
    //               <Form className="createform">
    //                 <div className="refisterdivwhite">
    //                   <div className="d-flex justify-content-between">
    //                     <span>Register as a</span>
    //                     <span>
    //                       <div className="form-check form-check-inline">
    //                         <Field
    //                           type="radio"
    //                           name="userType"
    //                           value="individual"
    //                           id="individual"
    //                           className="form-check-input"
    //                         />
    //                         <label
    //                           className="form-check-label"
    //                           htmlFor="individual"
    //                         >
    //                           Individual User
    //                         </label>
    //                       </div>
    //                       <div className="form-check form-check-inline">
    //                         <Field
    //                           type="radio"
    //                           name="userType"
    //                           value="corporate"
    //                           id="corporate"
    //                           className="form-check-input"
    //                         />
    //                         <label
    //                           className="form-check-label"
    //                           htmlFor="corporate"
    //                         >
    //                           Corporate User
    //                         </label>
    //                       </div>
    //                     </span>
    //                   </div>
    //                 </div>
    //                 <div className="d-flex mbrow gap-20 justify-content-between">
    //                   <div className="formfield">
    //                     <Field
    //                       type="text"
    //                       name="firstName"
    //                       placeholder="First Name"
    //                       className="form-control"
    //                     />
    //                     <ErrorMessage
    //                       name="firstName"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                   <div className="formfield">
    //                     <Field
    //                       type="text"
    //                       name="lastName"
    //                       placeholder="Last Name"
    //                       className="form-control"
    //                     />
    //                     <ErrorMessage
    //                       name="lastName"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="d-flex mbrow gap-20 justify-content-between">
    //                   <div className="formfield">
    //                     <Field
    //                       type="email"
    //                       name="email"
    //                       placeholder="Email ID"
    //                       className="form-control"
    //                     />
    //                     <ErrorMessage
    //                       name="email"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                   <div className="formfield">
    //                     <div className="d-flex mbrow gap-20 justify-content-between">
    //                       <Field name="countryId">
    //                         {({ field }: any) => (
    //                           <select
    //                             {...field}
    //                             className="countryId"
    //                             id="countryId"
    //                             onChange={(e) => {
    //                               const newValue = e.currentTarget.value;
    //                               field.onChange(e);

    //                               if (newValue.length > 0) {
    //                                 const parts = newValue.split(":");
    //                                 setIsdCode(parts[1]);
    //                               } else {
    //                                 setIsdCode(newValue);
    //                               }
    //                             }}
    //                           >
    //                             <option value="">ISD</option>
    //                             {countries.map((country) => (
    //                               <option
    //                                 key={country.id}
    //                                 value={`${country.id}`}
    //                               >
    //                                 {country.code}
    //                               </option>
    //                             ))}
    //                           </select>
    //                         )}
    //                       </Field>

    //                       <Field
    //                         type="text"
    //                         name="phoneNumber"
    //                         placeholder="+91"
    //                         className="form-control"
    //                       />
    //                     </div>
    //                     <ErrorMessage
    //                       name="phoneNumber"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="d-flex mbrow gap-20 justify-content-between">
    //                   <div className="formfield">
    //                     <div className="positioneye">
    //                       <Field
    //                         type={showPassword ? "text" : "password"}
    //                         name="password"
    //                         placeholder="Create Password"
    //                         className="form-control"
    //                       />

    //                       <a>
    //                         <img
    //                           src={showPassword ? closeEye : imageone}
    //                           alt="Toggle Password Visibility"
    //                           onClick={() => setShowPassword(!showPassword)}
    //                         />
    //                       </a>
    //                     </div>
    //                     <ErrorMessage
    //                       name="password"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                   <div className="formfield">
    //                     <div className="positioneye">
    //                       <Field
    //                         type={showPassword ? "text" : "password"}
    //                         name="confirmPassword"
    //                         placeholder="Confirm Password"
    //                         className="form-control"
    //                       />
    //                       <a>
    //                         <img
    //                           src={showPassword ? closeEye : imageone}
    //                           alt="Toggle Password Visibility"
    //                           onClick={() => setShowPassword(!showPassword)}
    //                         />
    //                       </a>
    //                     </div>
    //                     <ErrorMessage
    //                       name="confirmPassword"
    //                       component="div"
    //                       className="error-message"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="terms">
    //                   <div className="form-check checkboxdesign">
    //                     <Field
    //                       type="checkbox"
    //                       name="acceptTerms"
    //                       id="acceptTerms"
    //                       className="form-check-input"
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="acceptTerms"
    //                     >
    //                       I accept the&nbsp;{""} <b> Terms & Conditions</b>{" "}
    //                       &nbsp;and <b>&nbsp;Privacy Policy</b>
    //                     </label>
    //                     <ErrorMessage
    //                       name="acceptTerms"
    //                       component="div"
    //                       className="error-message"
    //                       // style={{ color: "red" }}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="sign">
    //                   <button
    //                     type="submit"
    //                     className="sign-btn"
    //                     onClick={() => scrollToSection("registrationSection")}
    //                   >
    //                     Register{" "}
    //                   </button>
    //                   <p className="smalllimk mt-2">
    //                     Already have an account?{" "}
    //                     <a href="#" onClick={() => navigate(Pages.Login)}>
    //                       Login Here
    //                     </a>
    //                   </p>
    //                 </div>
    //                 <div className="googlebtn">
    //                   <p>or sign in with</p>
    //                   <div className="d-flex btnclassfbscoail">
    //                     {/* <div
    //                       onClick={() =>
    //                         doExternalRegister(ExternalLoginType.Facebook)
    //                       }
    //                       className="blackround"
    //                     >
    //                       <a href="#">Facebook</a>
    //                     </div> */}
    //                     <div
    //                       onClick={() =>
    //                         doExternalRegister(ExternalLoginType.Google)
    //                       }
    //                       className="blackround"
    //                     >
    //                       <a href="#">Google</a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </Form>
    //             </Formik>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>
    <section className="optlogin">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logoafmcart} alt="Logo" />
          </div>
          <div className="col-6 homeright">
            <a>
              <img
                src={homeicon}
                alt="Home Icon"
                onClick={() => navigate(Pages.Home)}
                style={{ cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="titleofform">
              <h3>Create Your Account</h3>
            </div>
            {/* <div className="d-flex justify-content-center otpcontent text-center mt-0">
              <p>
                Need an account?{" "}
                <a href="#" onClick={() => navigate(Pages.Register)}>
                  {" "}
                  Get started!
                </a>
              </p>
            </div> */}
            <div className="otpmain" style={{ maxWidth: "600px" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="createform">
                  <div className="d-flex mbrow gap-20 mb-18 justify-content-between">
                    <div
                      className="formfield mt-0 errdiv"
                      style={{
                        display: errorMessage ? "block" : "none",
                      }}
                    >
                      <Field
                        type="text"
                        name="text"
                        readOnly
                        className="form-control errortext"
                        value={errorMessage}
                        style={{
                          color: "#cb321e",
                          fontwsize: "14px",
                          border: "1px solid #cb321e",
                        }}
                      />
                      <CloseIcon
                        onClick={handleClose}
                        className="close-icon"
                        style={{ fontSize: 20, color: "black" }}
                      />
                    </div>
                  </div>
                  <div className="refisterdivwhite">
                    <div className="d-flex justify-content-between">
                      <span>Register as a</span>
                      <span>
                        <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="userType"
                            value="individual"
                            id="individual"
                            className="form-check-input"
                            style={{ marginTop: "2px" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="individual"
                            style={{ paddingLeft: "0px" }}
                          >
                            Individual User
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="userType"
                            value="corporate"
                            id="corporate"
                            className="form-check-input"
                            style={{ marginTop: "2px" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="corporate"
                            style={{ paddingLeft: "0px" }}
                          >
                            Corporate User
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="formfield">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email ID"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="formfield">
                      <div className="d-flex mbrow gap-20 justify-content-between">
                        <Field name="countryId">
                          {({ field }: any) => (
                            <select
                              {...field}
                              className="countryId"
                              id="countryId"
                              onChange={(e) => {
                                const newValue = e.currentTarget.value;
                                field.onChange(e);

                                if (newValue.length > 0) {
                                  const parts = newValue.split(":");
                                  setIsdCode(parts[1]);
                                } else {
                                  setIsdCode(newValue);
                                }
                              }}
                            >
                              <option value="">ISD</option>
                              {countries.map((country) => (
                                <option
                                  key={country.id}
                                  value={`${country.id}`}
                                >
                                  {country.code}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>

                        <Field
                          type="text"
                          name="phoneNumber"
                          placeholder="+91"
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <div className="positioneye">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Create Password"
                          className="form-control"
                        />

                        <a>
                          <img
                            src={showPassword ? closeEye : imageone}
                            alt="Toggle Password Visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </a>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="formfield">
                      <div className="positioneye">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          className="form-control"
                        />
                        <a>
                          <img
                            src={showPassword ? closeEye : imageone}
                            alt="Toggle Password Visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </a>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="terms">
                    <div className="form-check checkboxdesign">
                      <Field
                        type="checkbox"
                        name="acceptTerms"
                        id="acceptTerms"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="acceptTerms">
                        I accept the&nbsp;{""} <b> Terms & Conditions</b>{" "}
                        &nbsp;and <b>&nbsp;Privacy Policy</b>
                      </label>
                      <ErrorMessage
                        name="acceptTerms"
                        component="div"
                        className="error-message"
                        // style={{ color: "red" }}
                      />
                    </div>
                  </div>
                  <div className="sign">
                    <button
                      type={loading ? "button" : "submit"}
                      className="sign-btn"
                      onClick={() => scrollToSection("registrationSection")}
                    >
                      {loading ? <Loading /> : "Register"}
                    </button>
                    <p className="smalllimk mt-2">
                      Already have an account?{" "}
                      <a href="#" onClick={() => navigate(Pages.Login)}>
                        Login Here
                      </a>
                    </p>
                  </div>
                  <div className="googlebtn">
                    <p>or sign in with</p>
                    <div className="d-flex btnclassfbscoail">
                      {/* <div
                          onClick={() =>
                            doExternalRegister(ExternalLoginType.Facebook)
                          }
                          className="blackround"
                        >
                          <a href="#">Facebook</a>
                        </div> */}
                      <div
                        onClick={() =>
                          doExternalRegister(ExternalLoginType.Google)
                        }
                        className="blackround"
                      >
                        <a href="#">Google</a>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
