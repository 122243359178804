import React, { useEffect } from "react";

import logo from "../../assets/Images/logo.svg";
import "./PaymentSuccess.css";
import Home from "../../assets/Images/homeicon.svg";
import success from "../../assets/Images/sucess.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { Pages } from "../../Pages";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { subscriptionType } = useParams();
  const location = useLocation();
  const myObject = location.state;

  const myName = "Payment-success-page";

  // useEffect(() => {
  //   const verifyPayment = async () => {
  //     let result = await ask({
  //       from: myName,
  //       to: SubscriptionClient_Name,
  //       message: SubscriptionClient_Messages.VerifyPayment,
  //       payload: {},
  //     });
  //     console.log(
  //       "result from verify payment",
  //       result?.payload?.returnValue?.message
  //     );
  //     if (result?.payload?.returnValue?.message === "success") {
  //       navigate(`${Pages.PaymentSuccessStatic}/${subscriptionType}`, {
  //         state: myObject,
  //       });
  //     }
  //     if (result?.payload?.returnValue?.message !== "success") {
  //       navigate(`${Pages.PaymentFailed}/${subscriptionType}`, {
  //         state: myObject,
  //       });
  //     }
  //   };
  //   verifyPayment();
  // }, []);

  enum SubscriptionType {
    Individual = "individual",
    Company = "company",
  }

  const navigateToShoppingCart = async (course: any, subscriptionType: any) => {
    navigate(`${Pages.ShoppingCart}/${subscriptionType}`, { state: course });
  };

  return (
    <>
      {/* <div classNameName="container">
       
        <img src={logo} alt="Logo" classNameName="logo" />
        <img src={homeIcon} alt="Home" classNameName="home-icon" />
        <div classNameName="content">
          <img src={tickMark} alt="TickMark" classNameName="tick-mark" />
          <div classNameName="mainpara">
            <p classNameName="bold-para">THANK YOU !</p>
            <p classNameName="bold-para">YOUR ORDER HAS BEEN SUCCESSFUL</p>
      
            <p style={{ color: "#062a3b" }}>Your order ID: 00000000</p>
          </div>
          <div
            classNameName="flexingcard"
            style={{
              marginRight: "-7rem",
              pointerEvents: "none",
              width: "125%",
            }}
          >
            <div classNameName="whitecard" style={{ borderColor: "#C7B277" }}>
              {subscriptionType === SubscriptionType.Individual ? (
                <h3 classNameName="packtype">Premium</h3>
              ) : (
                <h3 classNameName="packtype">Business</h3>
              )}
              {subscriptionType === SubscriptionType.Individual ? (
                <h2 classNameName="packprice">
                  $250 <span>/ year</span>
                </h2>
              ) : (
                <h2 classNameName="packprice">
                  $1000 <span>/ year</span>
                </h2>
              )}

              <ul classNameName="packlists">
                <li>Yearly Access</li>
                <li>Ask Expert</li>
                <li>Unlimited access to all content(Quiz)</li>
                <li>Personalize your learning path</li>
                <li>Test Knowledge</li>
                <li>Certification</li>
              </ul>
            </div>
          </div>
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            <button
              classNameName="goldenbtn"
              onClick={() => navigate(Pages.Dashboard)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logo} />
          </div>
          <div className="col-6 homeright">
            <a>
              <img src={Home} />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="paymentinfocontent">
              <div className="payminfoinner">
                <img src={success} />
                <h2 className="paytitle">
                  Thank you! <br /> Your order has been successful
                </h2>
                <p className="payinfodesc">Your Order ID: 00000000</p>
                <div className="priceboxinfo">
                  <h4>Premium</h4>
                  <h3>
                    $250 <span>/year</span>
                  </h3>
                  <ul>
                    <li>Yearly Access</li>
                    <li>Ask Expert</li>
                    <li>Unlimited access to all content</li>
                    <li>Personalize your learning path</li>
                    <li>Test Knowledge (Quiz)</li>
                    <li>Certification</li>
                  </ul>
                </div>
                {/* <a href="#" className="conitinue-btn">Continue</a> */}
                <button
                  className="conitinue-btn"
                  onClick={() => navigate(Pages.Dashboard)}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer part --> */}

        <div className="row">
          <div className="col-md-12 p-0">
            <footer className="cartfooter">
              <div className="cartfootertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
