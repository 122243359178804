// import React from "react";
// import { Route, Navigate } from "react-router-dom";
// import { Pages } from "../../Pages";
// import DummyDashboard from "./DummyDashboard";

// interface CorporateAdminRouteProps {
//   element: React.ReactElement;
//   isCorporateAdmin: boolean;
//   path: string;
// }

// const CorporateAdminRoute: React.FC<CorporateAdminRouteProps> = ({
//   element,
//   isCorporateAdmin,
//   path,
//   ...rest
// }) => {
//   if (isCorporateAdmin) {
//     return <Route path={path} element={element} {...rest}></Route>;
//   } else {
//     return <Route path={Pages.Others} element={<DummyDashboard />}></Route>;
//   }
// };

// export default CorporateAdminRoute;

import React from "react";
import { Route } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";

const CorporateAdminRoute = ({
  isCorporateAdmin,
}: {
  isCorporateAdmin: any;
}) => {
  return isCorporateAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default CorporateAdminRoute;
