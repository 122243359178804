import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "./DownloadSamplebox.css";
import * as XLSX from "xlsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
} from "../../actors/company-admin/types";
import { ask } from "lib-js-c-modulo/index.mjs";
import { saveAs } from "file-saver";
import Loading from "../common/Loading";
const DownloadSamplebox = () => {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [items, setItems] = useState([]);
  const [Data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [excelupload, setExcelUpload] = useState([]);
  // ----------------------------------------------
  const [downloadUserData, setDownloadUserData] = useState<any>([]);
  // ----------------------------------------------
  const myName = "add-company-users-gui";

  const readExcel = async (file: any) => {
    setLoading(true);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    try {
      let data: any = await promise;
      setItems(data); // Update items state with the new data
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {
          excellValue: data, // Use the updated data directly here
        },
        message: CompanyAdminClient_Messages.ExcellFileUpload,
      });
      console.log("Add Company Update user result excell values", result);
      const result1 = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log("download report list coming ", result);
      setData(result1?.payload?.returnValue?.allUser);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const columns = [
      // "UserId",
      // "SubscriptionID",
      // "InviteSentDate",
      "First_Name",
      "Last_Name",
      "Designation",
      "Email",
      "Mobile_Number",
      // "RegistrationStatus",
      // "RegistrationDate",
    ];
    const worksheet = XLSX.utils.aoa_to_sheet([columns]);
    // const worksheet = XLSX.utils.json_to_sheet(userData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, "AFM_User_List.xlsx");
  };

  const handleShowingData = async () => {
    try {
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log("download report list coming ", result);
      setData(result?.payload?.returnValue?.allUser);
    } catch (error) {
      console.log(error);
      return; // Exit the function if there's an error
    }
  };

  const handleDownloadUserData = async () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(Data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, "User_Report.xlsx");
  };

  useEffect(() => {
    handleShowingData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="download-sample-box-container">
          <div className="row">
            <div className="col-md-12 p-0 col-md-3  mymainparthtml">
              <div className="mainbody">
                <div className="">
                  <div className="contentpart">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="offset-md-0">
                          <div className="containerhead">
                            <div className="row custom-row">
                              <div className="col">
                                <h4 className="heading custom-heading">
                                  STATUS
                                </h4>
                                {/* <div className="container-box">
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mixing">
                                        <h4>Total:</h4>
                                        <h5>50</h5>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mixing">
                                        <h4>Consumed:</h4>
                                        <h5>20</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                                <div className="button-container">
                                  {/* <button className="goldenbtn uploadbtn">
                                  <input
                                    id="upload_btn"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e: any) => {
                                      const file = e?.target?.files[0];
                                      readExcel(file);
                                    }}
                                  />
                                  Upload from
                                </button> */}
                                  <button style={{ cursor: "pointer" }}>
                                    <label
                                      style={{ cursor: "pointer" }}
                                      className="goldenbtn uploadbtn"
                                    >
                                      Upload From Excel
                                      <input
                                        id="upload_btn"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e: any) => {
                                          const file = e?.target?.files[0];
                                          readExcel(file);
                                        }}
                                      />
                                    </label>
                                  </button>

                                  <a
                                    className="download-link"
                                    onClick={handleDownload}
                                    href="#"
                                  >
                                    DOWNLOAD SAMPLE EXCEL
                                  </a>
                                </div>
                              </div>
                              <div className="mt-5 py-5">
                                <div className="col-md-6">
                                  <div className="divRow">
                                    {/* <div className="radio-group">
                                    <label>
                                      <input
                                        type="radio"
                                        name="radio-button"
                                        value="css"
                                        checked
                                      />
                                      <span>All</span>
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        name="radio-button"
                                        value="no"
                                      />
                                      <span>Registered</span>
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        name="radio-button"
                                        value="css"
                                      />
                                      <span>Awaiting</span>
                                    </label>
                                  </div> */}
                                    <div className="col">
                                      <div className="table-responsive downloadTab">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th>User ID</th>
                                              <th className="single-line-header">
                                                Subscription ID
                                              </th>
                                              <th className="multiline-header">
                                                Invitation sent
                                                <br />
                                                Date
                                              </th>
                                              <th>First Name</th>
                                              <th>Last Name</th>
                                              <th>Registered on</th>
                                              <th>Course Status</th>
                                              <th>Assessment</th>
                                              <th className="multi-line-header">
                                                Assessment
                                                <br />
                                                Results
                                              </th>

                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Data?.map((obj: any) => (
                                              <>
                                                <tr>
                                                  <td>{obj?.id}</td>
                                                  <td>
                                                    {obj?.subscriptionId
                                                      ? "1gh23"
                                                      : "s324dfb"}
                                                  </td>
                                                  <td>
                                                    {obj?.createdAt
                                                      ? new Date(obj?.createdAt)
                                                          .toISOString()
                                                          .split("T")[0]
                                                      : "NA"}
                                                  </td>
                                                  <td>{obj?.firstName}</td>
                                                  <td>{obj?.lastName}</td>
                                                  <td>{obj?.designation}</td>
                                                  <td>{obj?.email}</td>
                                                  <td>
                                                    {obj?.registrationStatus}
                                                  </td>
                                                  <td>
                                                    {obj?.validateOn
                                                      ? new Date(
                                                          obj?.validateOn
                                                        )
                                                          .toISOString()
                                                          .split("T")[0]
                                                      : "Not Registered"}
                                                  </td>
                                                  <td>
                                                    <a href="">
                                                      1. Copy Invite
                                                    </a>
                                                    <br />
                                                    <a href="">2. Delete</a>
                                                    <br />
                                                    <a href="">
                                                      3. Resend Invite
                                                    </a>
                                                  </td>
                                                </tr>
                                              </>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-12 mt-5">
                        <div
                          className="d-flex mobilebtn justify-content-between"
                          style={{ paddingRight: "10px", marginTop: "20px" }}
                        >
                          <button className="bluebtn whitetext cunsumflex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="11"
                              viewBox="0 0 5 11"
                              fill="none"
                            >
                              <path
                                d="M4.86648 0.151862C4.79117 0.0659041 4.69189 0.0128926 4.58592 0.00206323C4.47995 -0.00876615 4.37402 0.0232733 4.28658 0.0925993L4.22296 0.151863L0.132759 4.83056C0.0576132 4.91671 0.01127 5.03028 0.00180279 5.15149C-0.00766444 5.27271 0.0203442 5.39388 0.0809502 5.4939L0.13276 5.56668L4.22296 10.2454C4.30312 10.3375 4.41053 10.3919 4.52402 10.3979C4.63751 10.4039 4.74886 10.3611 4.83611 10.2778C4.92336 10.1946 4.9802 10.077 4.99541 9.94821C5.01062 9.81942 4.98311 9.68878 4.91829 9.58204L4.86649 9.50926L1.09714 5.19862L4.86648 0.887978C4.90881 0.839688 4.94239 0.782321 4.9653 0.719164C4.98821 0.656006 5 0.588299 5 0.51992C5 0.451541 4.98821 0.383834 4.9653 0.320677C4.94239 0.257519 4.90881 0.200153 4.86648 0.151862Z"
                                fill="white"
                              />
                            </svg>
                            <span>PREVIOUS</span>
                          </button>

                          <button className="bluebtn whitetext cunsumflex">
                            <span>NEXT</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="11"
                              viewBox="0 0 5 11"
                              fill="none"
                            >
                              <path
                                d="M0.133516 0.151862C0.208827 0.0659041 0.308114 0.0128926 0.414081 0.00206323C0.520048 -0.00876615 0.625977 0.0232733 0.713416 0.0925993L0.777041 0.151863L4.86724 4.83056C4.94239 4.91671 4.98873 5.03028 4.9982 5.15149C5.00766 5.27271 4.97966 5.39388 4.91905 5.4939L4.86724 5.56668L0.77704 10.2454C0.696882 10.3375 0.589473 10.3919 0.47598 10.3979C0.362487 10.4039 0.251142 10.3611 0.163889 10.2778C0.0766357 10.1946 0.0198031 10.077 0.00459121 9.94821C-0.0106207 9.81942 0.0168916 9.68878 0.0817059 9.58204L0.133515 9.50926L3.90286 5.19862L0.133516 0.887978C0.0911934 0.839688 0.0576147 0.782321 0.0347037 0.719164C0.0117927 0.656006 -9.89199e-08 0.588299 -8.74223e-08 0.51992C-7.59247e-08 0.451541 0.0117928 0.383834 0.0347038 0.320677C0.0576148 0.257519 0.0911935 0.200153 0.133516 0.151862Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- footer part --> */}
          {/* <div className="col-md-12 p-0">
            <footer>
              <div className="footertext">
                <div>
                  <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p>
                </div>
                <div className="quicklinks">
                  <a href="#">PRIVACY</a>
                  <a href="#">TERMS</a>
                </div>
              </div>
            </footer>
          </div> */}
          {/* <!-- end of the footer part --> */}
        </div>
      )}
    </>
  );
};

export default DownloadSamplebox;
