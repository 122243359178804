import React from "react";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import Rectangle from "../dummy/images/Rectangle 66-img.png";
import Group318 from "../dummy/images/Group 318.png";
import Group319 from "../dummy/images/Group 319.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group164 from "../dummy/images/Group 164 (1).png";
import Group325 from "../dummy/images/Group 325 (1).png";
import Group212 from "../dummy/images/Group 212.png";
import { styled } from "styled-components";
import { useNavigate } from "react-router";
import { Pages } from "../../Pages";
const CompanyDashboard = () => {
  const navigate = useNavigate();

  // const isCorporateAdmin = localStorage.getItem("afm-client:auth-client");
  // if (isCorporateAdmin)
  //   console.log(
  //     "type, value",
  //     typeof isCorporateAdmin,
  //     JSON.parse(isCorporateAdmin)
  //   );

  return (
    <div>
      <Container>
        <div>
          <img src={Rectangle} alt="" id="Rectangle_img" />
          <img src={avilogo} alt="" id="avilogo_img" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: "200px",
              left: "10px",
            }}
          >
            <ol id="logo_ol">
              <li>
                <img src={Group318} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group319} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group320} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group324} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group321} alt="" className="sidebar_images" />
              </li>
            </ol>
            <ol id="list_ol">
              <li>
                <a href="" style={{ color: "#C7B277" }}>
                  COMPANY INFO
                </a>
              </li>
              <li onClick={() => navigate(Pages.AddCompanyUsers)}>
                <a href="">MANAGE COMPANY USERS</a>
              </li>
              <li>
                <a href="">COURSE COMPLIANCE REPORT</a>
              </li>
              <li>
                <a href="">SUBSCRIPTION STATUS</a>
              </li>
              <li>
                <a href="">SUPPORT</a>
              </li>
            </ol>
            <button id="go_to_btn">GO TO COURSE</button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            left: "300px",
            backgroundColor: "#C7B277",
            width: "100%",
          }}
        >
          <p id="corp_heading">Corporate User Dashboard</p>

          <ol id="nav-ol">
            <li>
              <img
                src={vectorimg2}
                alt=""
                className="nav-img"
                style={{ width: "10px" }}
              />
            </li>
            <li>
              <a href="">Back To Dashboard</a>
            </li>
            <li>
              <img src={Group164} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Arron Lee</a>
            </li>
            <li>
              <img src={Group325} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Log Out</a>
            </li>
          </ol>
        </div>
        <div style={{ position: "absolute", top: "110px", left: "330px" }}>
          <img src={Group212} alt="" id="arron_crop_img" />
          <p className="p-content">
            The Aviation Fuel Business forms an essential part of the portfolio
            of any Oil company. The importance is out of proportion to the small
            volumes of Jet Fuel sold by any Oil company compared to its entire
            business. The Jet Fuel business needs to be viewed separately due to
            various reasons, some of which are given below:
          </p>
          <p className="p-content">
            AFM training module is intended for the use and guidance of the
            personnel engaged in Aviation Products Quality Control at Terminals,
            airport depots and into-plane services for ensuring that clean, dry
            and on-specification product is finally delivered to aircraft.
          </p>
          <p className="p-content">
            The Jet Fuel business needs to be viewed separately due to various
            reasons, some of which are given below:
          </p>
          <p className="p-content">
            Human lives depend on whatever happens to Jet Fuel during its
            journey from the refinery to the aircraft.
          </p>
          <p className="p-content">
            As they say, “You can push a car that has stalled due to choked Fuel
            Filters, but you can’t do the same to aircraft with 300+ passengers
            on board”.
          </p>
          <p className="p-content">
            The aircraft systems have built-in redundancy for all systems except
            the fuel handling system due to its large size in the aircraft.
          </p>
          <p className="p-content">
            Jet fuel forms a very high-profile business with international
            exposure for any company. A company’s image in the Aviation field
            can make or break its reputation in the global fuel markets.
          </p>
          <p className="p-content">
            {" "}
            Due to the considerable risk attached to human lives, it is
            necessary to maintain the highest standards of operations and
            quality control. Fuel suppliers must provide Aircraft Refueling
            Liability insurance coverage of 1 Billion US dollars.
          </p>
          <p className="p-content">
            With the above in mind, all personnel handling Jet Fuel must handle
            it carefully and in line with laid down procedures giving it extra
            care and not treating Jet Fuel like “Just another Fuel”.
          </p>
          <p className="p-content">
            Aviation Fuel Mastermind (AFM) aims to address all the basics of
            Aviation operations at the airfield in a simple language for people
            working across the aviation fuel supply chain to enhance their
            knowledge.
          </p>
          <p className="p-content">
            It should, however, be understood that AFM is not meant to replace
            or overrule the Energy Institute (EI) or Joint Inspection Group
            (JIG) standards which need to be continuously referred to for
            in-depth knowledge of Aviation Operations.
          </p>

          <p className="p-content">
            AFM training module has been prepared by experts with over 100 years
            of combined on hands working experience across aviation fuel supply
            chain and is intended to provide guidance/good practice on the
            siting, layout, design, construction, testing, commissioning and
            maintenance of aircraft fuelling facilities, including the design
            and construction of fuellers, hydrant servicers and ancillary
            equipment used in fuelling aircraft.
          </p>

          <p className="p-content">
            The AFM team has made every effort to assure the accuracy and
            reliability of the data contained in the AFM Training module;
            however, AFM makes no representation, warranty, or guarantee in
            connection with the AFM training module and hereby expressly
            disclaim any liability or responsibility for loss or damage
            resulting from its use or for the violation of any local or regional
            laws or regulations with which AFM Training module may conflict.
          </p>
        </div>
        <div>
          <button
            id="edit_btn"
            /*onClick={navigateToCompInfo}*/ onClick={() =>
              navigate(Pages.EditInfo)
            }
          >
            EDIT INFO
          </button>
        </div>
        {/* footer */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#FBFAFA",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <p id="footer_p">
            © Copyright 2023 Aviation Mastermind. All Rights Reserved.
          </p>
          <ol id="footer_ol">
            <li>PRIVACY & TERMS</li>
            {/* <li>TERM</li> */}
          </ol>
        </div>
      </Container>
    </div>
  );
};

export default CompanyDashboard;

const Container = styled.div`
    #Rectangle_img{
    height:170vh;
    width:300px;
    
    }
    
    #avilogo_img{
        position:absolute;
        top:30px;
        left:50px;
    }
    
    #logo_ol{
    line-height:30px;
    list-style:none;
    /
    }
    
    #list_ol{
        line-height:30px;
        list-style:none;
       font-size:13px;
     
    }
    li{
        padding-top:15px; 
    }
    
    #list_ol li a{
    text-decoration: none;
     color:#FFFFFF;   
    }
    
    .sidebar_images{
    width:18px;
    }
    
    #go_to_btn{
    position:absolute;
    top:280px;
    left:30px; 
    background-color:#C7B277; 
    padding:8px 15px 8px 15px;
    border:none;
    font-size:13px;
     
    }
    #corp_heading{
        margin-top:19px;
        margin-left:15px;
    }
    #nav-ol{
    display:flex;
    flex-direction:row;
    color:#FFFFFF;
    
    // position:absolute;
    // top:3px;
    // margin-left:250px;
    // left:30px; 
    // width:82%;
    margin-left:220px
    
    }
    #nav-ol li {
    list-style:none; 
    
    }
    #nav-ol li a{
        margin-left:5px;
        color:#FFFFFF; 
        text-decoration:none; 
    }
    #nav-ol li img{
    margin-left:40px; 
    color:#FFFFFF; 
    }
    .nav-img{
    width:30px;
    }
    
    #arron_crop_img{
        width:400px;
        margin-bottom:50px;
        // position:absolute;
        // top:110px;
        // left:370px;
    
    }
    
 .p-content{
    font-size:12px;
 }

    #footer_ol{
        display:flex;
        flex-direction:row;
       
    
    }
    #footer_ol li{
        margin-left:50px;
        list-style:none; 
    }
    #footer_p{
        margin-top:15px;
    }

    #edit_btn{
        position:absolute;
        top:1000px;
        left:350px;
       background-color:#C7B277; 
    padding:12px 32px 12px 32px;
    border:none;
    font-size:15px;
    }


    `;
