import styled from "styled-components";

export const AuthButton = styled.button`
  background: #c7b277;
  color: #ffffff;
  border: 1px solid #c7b277;
  width: 216px;
  height: 50px;
  cursor: pointer;
`;

export const AuthButtonContainer = styled.div`
  display: flex;
  grid-gap: 46px;

  @media (max-width: 576px) {
    grid-gap: 0px;
    flex-direction: column;

    button {
      height: 40px;
    }
  }
`;
