//---------------------------------------------------------------------------------
//
// course/CourseClient.ts
//
//---------------------------------------------------------------------------------

import {
  ask,
  Dictionary,
  getData,
  KeysAndValues,
  Message,
  MessageResponse,
  StatefulActor,
  StatefulActorErrorState,
  StatefulActorInstance,
  StatefulActorStateEntry,
  StatefulActorStateEvent,
  StatefulActorStateInstance,
} from "lib-js-c-modulo/index.mjs";
import { LocalActorConfig } from "../../modulo/types";
import {
  //   AuthClient_Data,
  //   AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
  //   AuthService_Name,
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
  CourseService_Name,
} from "./types";
import { addSessionToken } from "../auth/AuthClient";

//---------------------------------------------------------------------------------

const defaultData: () => CourseClient_Data = () => ({
  courseList: [],
  topicList: [],
  moduleList: [],
});

//---------------------------------------------------------------------------------

class CourseClient_State_Start extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    this.actor.gotoState(CourseClient_States.NotAuthenticated, input);
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [`${AuthClient_Name}/changed`]: async (input?: KeysAndValues) => {
      const authState = input?.event?.payload?.state;
      const runClientState = this.actor.state;
      switch (authState) {
        case AuthClient_States.NotAuthenticated:
          console.log("COURSE CLIENT: NOT AUTHENTICATED");
          this.actor.gotoState(
            CourseClient_States.NotAuthenticated,
            defaultData()
          );
          return;
        case AuthClient_States.Authenticated:
          console.log("COURSE CLIENT: AUTHENTICATED");
          if (runClientState === CourseClient_States.NotAuthenticated)
            this.actor.gotoState(
              CourseClient_States.Authenticated,
              defaultData()
            );
          return;
        default:
          // Do nothing
          return;
      }
    },
  };
}

//---------------------------------------------------------------------------------

// class AuthClient_State_Validating extends StatefulActorStateInstance {
//   public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
//     const init = async () => {
//       try {
//         this.actor.setState(AuthClient_States.Validating, {});
//         const result = await ask({
//           from: AuthClient_Name,
//           to: AuthService_Name,
//           message: AuthClient_Messages.Validate,
//           payload: { token: this.actor.data.token },
//           expectResult: true,
//         });
//         if ((result as MessageResponse).error) {
//           throw new Error(result!.error);
//         } else {
//           const isValidSessionToken =
//             (result as MessageResponse).payload?.isValidSessionToken === true;
//           if (isValidSessionToken) {
//             this.actor.gotoState(AuthClient_States.Authenticated, {
//               ...result!.payload!,
//             });
//           } else {
//             this.actor.gotoState(
//               AuthClient_States.NotAuthenticated,
//               defaultData
//             );
//           }
//         }
//       } catch (err: any) {
//         this.actor.gotoState(AuthClient_States.ValidateError, {
//           error: err.message,
//         });
//       }
//     };
//     init();
//   };
// }

//---------------------------------------------------------------------------------

class CourseClient_State_NotAuthenticated extends StatefulActorStateInstance {}
class CourseClient_State_Authenticated extends StatefulActorStateInstance {
  public entry: StatefulActorStateEntry = (input?: KeysAndValues): void => {
    const init = async () => {
      try {
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetCourses,
          payload: addSessionToken({}),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("list of courses", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
      } catch (error: any) {
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    };
    init();
  };
  public on: Dictionary<StatefulActorStateEvent> = {
    [CourseClient_Messages.FetchModuleAndSession]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT", input);
        // return { payload: "Hello" };
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.FetchModuleAndSession,
          payload: addSessionToken({ courseId: input!.courseId }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);

        console.log("LIST OF TOPICS AND MODULES", result);

        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { result };
        // const topicResult =
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    },

    [CourseClient_Messages.UpdateUserProgress]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("SESSION ID FROM COURSE CLIENT", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.UpdateUserProgress,
          payload: addSessionToken({
            courseId: input!.courseId,
            moduleId: input!.moduleId,
            sessionId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("UPDATE USER PROGRESS RESULT", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error: any) {
        console.log("Error is", error);
        this.actor.gotoState(CourseClient_States.Err, {
          error: error.message,
        });
      }
    },

    [CourseClient_Messages.CheckQuizResult]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        // console.log("INPUT FROM CHECK QUIZ RESPONSE", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.CheckQuizResult,
          payload: addSessionToken({
            sessionId: input!.sessionId,
            courseId: input!.courseId,
            moduleId: input!.moduleId,
            submittedAnswers: input!.submittedAnswers,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("CHECK QUIZ RESULT", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("error is", error);
      }
    },

    [CourseClient_Messages.getAssessment]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM GET ASSESSMENT", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.getAssessment,
          payload: addSessionToken({
            courseId: input!.courseId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("CHECK QUIZ RESULT", result);
        const resultObject = result?.payload?.modifiedQuestions;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { resultObject };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.CheckAssessmentResult]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK ASSESSMENT RESULT", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.CheckAssessmentResult,
          payload: addSessionToken({
            courseId: input!.courseId,
            submittedAnswers: input!.submittedAnswers,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("CHECK ASSESSMENT RESULT", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.NavigateToQuiz]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      console.log("INPUT FROM NAVIGATE TO QUIZ", input);
      try {
        this.actor.setState(CourseClient_States.WIP);
        this.actor.setState(CourseClient_States.Authenticated, {
          ...input,
        });
        return { input };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetPreviousSession]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK ASSESSMENT RESULT", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetPreviousSession,
          payload: addSessionToken({
            sessionId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("CHECK ASSESSMENT RESULT", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.AddNote]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK ADD NOTES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.AddNote,
          payload: addSessionToken({
            note: input!.addNotesValue,
            courseId: input!.courseId,
            topicId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("ADD NOTES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.EditNote]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK EDIT NOTES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.EditNote,
          payload: addSessionToken({
            note: input!.addNotesValue,
            topicId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("EDIT NOTES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.DeleteNote]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK DELETE NOTES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.DeleteNote,
          payload: addSessionToken({
            topicId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("DELETE NOTES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.DeleteBookmark]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK DELETE BOOKMARK", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.DeleteBookmark,
          payload: addSessionToken({
            topicId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("DELETE BOOKMARK", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.AddBookmark]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK ADD BOOKMARKS", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.AddBookmark,
          payload: addSessionToken({
            bookmarkNote: input!.addBookMarksValue,
            courseId: input!.courseId,
            topicId: input!.sessionId,
          }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("ADD BOOKMARKS", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetSingleUserNote]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK GET SINGLE USER NOTES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetSingleUserNote,
          payload: addSessionToken({ topicId: input!.topicId }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("GET SINGLE USER NOTES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetSingleUserBookmark]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK GET SINGLE USER BOOKMARKS", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetSingleUserBookmark,
          payload: addSessionToken({ topicId: input!.topicId }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("GET SINGLE USER BOOKMARKS", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.FetchNotesBookmarks]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("txt INPUT FROM FetchNotesBookmarks", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.FetchNotesBookmarks,
          payload: addSessionToken({ topicId: input!.topicId }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("txt GET FetchNotesBookmarks", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetUserNotes]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK GET NOTES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetUserNotes,
          payload: addSessionToken({}),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("GET NOTES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetUserBookMarks]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK GET BOOKMARKS", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetUserBookMarks,
          payload: addSessionToken({}),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("GET BOOKMARKS", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetNextModule]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK GET NEXT MODULE", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          message: CourseClient_Messages.GetNextModule,
          payload: addSessionToken({ topicId: input!.sessionId }),
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("GET NEXT MODULE", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.FetchUserCertificates]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CHECK FETCH USER CERTIFICATES", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          payload: addSessionToken({}),
          message: CourseClient_Messages.FetchUserCertificates,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("FETCH USER CERTIFICATES", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.CheckFreeTrialAndSubscription]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM CheckFreeTrialAndSubscription", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          payload: addSessionToken({ sessionId: input!.sessionId }),
          message: CourseClient_Messages.CheckFreeTrialAndSubscription,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("FETCH USER CheckFreeTrialAndSubscription", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    [CourseClient_Messages.GetProfileDetails]: async (
      input?: KeysAndValues,
      message?: Message | undefined
    ) => {
      try {
        console.log("INPUT FROM GetProfileDetails", input);
        this.actor.setState(CourseClient_States.WIP);
        const result = await ask({
          from: CourseClient_Name,
          to: CourseService_Name,
          payload: addSessionToken({}),
          message: CourseClient_Messages.GetProfileDetails,
          expectResult: true,
        });
        if ((result as MessageResponse).error) throw new Error(result!.error);
        console.log("FETCH USER CheckFreeTrialAndSubscription", result);
        const returnValue = result!.payload;
        this.actor.setState(CourseClient_States.Authenticated, {
          ...result!.payload,
        });
        return { returnValue };
      } catch (error) {
        console.log("Error is", error);
      }
    },

    // [CourseClient_Messages.FetchModuleAndSession]: async (
    //   input?: KeysAndValues,
    //   message?: Message | undefined
    // ) => {
    //   try {
    //     this.actor.setState(CourseClient_States.WIP);
    //     const result = await ask({
    //       from: CourseClient_Name,
    //       to: CourseService_Name,
    //       message: CourseClient_Messages.UpdateUserProgress,
    //       payload: addSessionToken({ currentTopicId: input!.currentTopicId }),
    //     });
    //   } catch (error) {}
    // },
    // [CourseClient_Messages.GetTopics]: async (
    //   input?: KeysAndValues,
    //   message?: Message | undefined
    // ) => {
    //   try {
    //     console.log("INPUT", input);
    //   } catch (err: any) {
    //     console.log("Error is", err);
    //     return {}
    //   }
    // },
  };
}

//---------------------------------------------------------------------------------

// export function addSessionToken(payload: KeysAndValues): KeysAndValues {
//   const authData = getData(AuthClient_Name) as KeysAndValues as AuthClient_Data;
//   return {
//     ...payload,
//     token: authData.token,
//   };
// }

//---------------------------------------------------------------------------------

// export function isAuthenticated(): boolean {
//   const authClientState = getData(AuthClient_Name).state;
//   return [
//     AuthClient_States.Authenticated,
//     AuthClient_States.LoggingOut,
//     AuthClient_States.LogoutError,
//   ].includes(authClientState);
// }

//---------------------------------------------------------------------------------

export function createCourseClientActor(
  config: LocalActorConfig
): StatefulActor {
  const actor = new StatefulActorInstance(config.name, defaultData(), false);
  actor.setAllStates([
    new CourseClient_State_Start(CourseClient_States.Start, true, actor),
    new StatefulActorStateInstance(CourseClient_States.WIP, true, actor),
    new CourseClient_State_NotAuthenticated(
      CourseClient_States.NotAuthenticated,
      false,
      actor
    ),
    new CourseClient_State_Authenticated(
      CourseClient_States.Authenticated,
      false,
      actor
    ),
    new StatefulActorErrorState(
      CourseClient_States.Err,
      actor,
      CourseClient_States.Authenticated,
      {}
    ),
  ]);
  actor.listenToActors([AuthClient_Name], true);
  return actor;
}

// export function createAuthClientActor(config: LocalActorConfig): StatefulActor {
//   const actor = new StatefulActorInstance(config.name, defaultData, false);
//   actor.setAllStates([
//     new AuthClient_State_Start(AuthClient_States.Start, true, actor),
//     new AuthClient_State_Validating(AuthClient_States.Validating, true, actor),
//     new StatefulActorErrorState(
//       AuthClient_States.ValidateError,
//       actor,
//       AuthClient_States.Validating,
//       {}
//     ),
//     new AuthClient_State_NotAuthenticated(
//       AuthClient_States.NotAuthenticated,
//       false,
//       actor
//     ),
//     new StatefulActorStateInstance(AuthClient_States.LoggingIn, true, actor),
//     new StatefulActorErrorState(
//       AuthClient_States.LoginError,
//       actor,
//       AuthClient_States.NotAuthenticated,
//       {}
//     ),
//     new StatefulActorStateInstance(
//       AuthClient_States.RequestingPasswordReset,
//       true,
//       actor
//     ),
//     new StatefulActorErrorState(
//       AuthClient_States.RequestingPasswordResetError,
//       actor,
//       AuthClient_States.NotAuthenticated,
//       {}
//     ),
//     new StatefulActorStateInstance(
//       AuthClient_States.ResettingPassword,
//       true,
//       actor
//     ),
//     new StatefulActorErrorState(
//       AuthClient_States.ResettingPasswordError,
//       actor,
//       AuthClient_States.NotAuthenticated,
//       {}
//     ),
//     new AuthClient_State_Authenticated(
//       AuthClient_States.Authenticated,
//       false,
//       actor
//     ),
//     new AuthClient_State_ValidateEmail(
//       AuthClient_States.ValidateEmail,
//       false,
//       actor
//     ),
//     new StatefulActorStateInstance(
//       AuthClient_States.ValidatingEmail,
//       true,
//       actor
//     ),
//     new StatefulActorErrorState(
//       AuthClient_States.ValidatingEmailError,
//       actor,
//       AuthClient_States.ValidateEmail,
//       {}
//     ),
//     new StatefulActorStateInstance(AuthClient_States.LoggingOut, true, actor),
//     new StatefulActorErrorState(
//       AuthClient_States.LogoutError,
//       actor,
//       AuthClient_States.Authenticated,
//       {}
//     ),
//     new StatefulActorStateInstance(AuthClient_States.Registering, true, actor),
//     new StatefulActorErrorState(
//       AuthClient_States.RegisterError,
//       actor,
//       AuthClient_States.NotAuthenticated,
//       {}
//     ),
//     new StatefulActorStateInstance(AuthClient_States.CountryList, true, actor),
//     new StatefulActorErrorState(
//       AuthClient_States.CountryListError,
//       actor,
//       AuthClient_States.NotAuthenticated,
//       {}
//     ),
//   ]);
//   return actor;
// }

// ---------------------------------------------------------------------------------
