import React from "react";
import logo from "./images/logo.svg";
import usericon from "./images/usericon.svg";
import whiteusericon from "./images/whiteusericon.svg";
import camera from "./images/camera.svg";
import loginicon from "./images/loginicon.png";
import "./CompanyInfo.css";
// import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import { KeysAndValues, getData } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Data, AuthClient_Name } from "../../actors/auth/types";
import logoafmcart from "../../assets/Images/logoafmcart.png";
// import "./style.css";
const CompanyInfo = () => {
  const navigate = useNavigate();
  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="contentpart">
            <div className="col-md-12">
              <div className="photoupload">
                <div className="">
                  <img className="centerlgo" src={logoafmcart} />
                </div>
                <div className="usersubscribe">
                  <img src={whiteusericon} />
                  {/* <div className="cemera">
                    <img src={camera} />
                  </div> */}
                  <input className="custominputfile" type="file" />
                </div>
                <div className="photousername">
                  <h4>{fullName}</h4>
                  {/* <h6>Free Trial User</h6> */}
                </div>
              </div>
              <div className="parent">
                <div className="paraContent">
                  <p>
                    The Aviation Fuel Business forms an essential part of the
                    portfolio of any Oil company. The importance is out of
                    proportion to the small volumes of Jet Fuel sold by any Oil
                    company compared to its entire business.
                  </p>

                  <p>
                    AFM training module is intented for the use and guidance of
                    the personnnel engaged in Aviation Product Quality Control
                    at Terminals, airport depots and into-plane services for
                    ensuring that clean, dry and on-specification product is
                    finally delivered to aircraft.
                  </p>

                  <p>
                    The Jet Fuel business needs to be viewed separately due to
                    various // reasons, some of which are given below:
                  </p>

                  <p></p>

                  <p></p>
                  <ul className="comanlist">
                    <li>
                      Human lives depend on whatever happens to Jet Fuel during
                      its journey from the refinery to the aircraft.
                    </li>
                    <li>
                      As they say, “You can push a car that has stalled due to
                      choked Fuel Filters, but you can’t do the same to aircraft
                      with 300+ passengers on board”.
                    </li>
                    <li>
                      The aircraft systems have built-in redundancy for all
                      systems except the fuel handling system due to its large
                      size in the aircraft.
                    </li>
                    <li>
                      Jet fuel forms a very high-profile business with
                      international exposure for any company. A company’s image
                      in the Aviation field can make or break its reputation in
                      the global fuel markets.
                    </li>
                    <li>
                      Due to the considerable risk attached to human lives, it
                      is necessary to maintain the highest standards of
                      operations and quality control.
                    </li>
                    <li>
                      Fuel suppliers must provide Aircraft Refueling Liability
                      insurance coverage of 1 Billion US dollars.
                    </li>
                  </ul>
                  <p>
                    With the above in mind, all personnel handling Jet Fuel must
                    handle it carefully and in line with laid down procedures
                    giving it extra care and not treating Jet Fuel like “Just
                    another Fuel”.
                  </p>

                  <p>
                    Aviation Fuel Mastermind (AFM) aims to address all the
                    basics of Aviation operations at the airfield in a simple
                    language for people working across the aviation fuel supply
                    chain to enhance their knowledge.
                  </p>

                  <p>
                    It should, however, be understood that AFM is not meant to
                    replace or overrule the Energy Institute (EI) or Joint
                    Inspection Group (JIG) standards which need to be
                    continuously referred to for in-depth knowledge of Aviation
                    Operations.
                  </p>

                  <p>
                    AFM training module has been prepared by experts with over
                    100 years of combined on hands working experience across
                    aviation fuel supply chain and is intended to provide
                    guidance/good practice on the siting, layout, design,
                    construction, testing, commissioning and maintenance of
                    aircraft fuelling facilities, including the design and
                    construction of fuellers, hydrant servicers and ancillary
                    equipment used in fuelling aircraft.
                  </p>

                  <p>
                    The AFM team has made every effort to assure the accuracy
                    and reliability of the data contained in the AFM Training
                    module; however, AFM makes no representation, warranty, or
                    guarantee in connection with the AFM training module and
                    hereby expressly disclaim any liability or responsibility
                    for loss or damage resulting from its use or for the
                    violation of any local or regional laws or regulations with
                    which AFM Training module may conflict.
                  </p>
                  <button
                    className="editInfo"
                    onClick={() => navigate(Pages.UpdateCompanyName)}
                  >
                    Edit Info
                  </button>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInfo;
