import React from "react";
import {
  ActorEmittedEvent,
  ask,
  getData,
  KeysAndValues,
  listenTo,
  stopListeningTo,
} from "lib-js-c-modulo/index.mjs";
import {
  CourseClient_Data,
  CourseClient_Messages,
  CourseClient_Name,
  CourseClient_States,
  Topic,
} from "../../actors/course/types";

import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "./UserProfile.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import { useCallback, useEffect, useState } from "react";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import Loading from "../common/Loading";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import whiteusericon from "./images/whiteusericon.svg";
import camera from "./images/camera.svg";
import eyeIcon from "./images/eyeicon.svg";
import closedEye from "../../assets/close-eye.svg";
import { formatDate, useSubscriptionNavigation } from "../../appUtils";
import { SubscriptionType } from "../../actors/subscription/types";
const UserProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const myObject = location.state;
  const myName = "User-Profile";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState<any>();

  const subscriptionNavigation = useSubscriptionNavigation();

  const handleAccordionClick = (tab: any) => {
    setActiveTab(tab === activeTab ? null : tab);
  };

  const isTabActive = (tab: any) => {
    return activeTab === tab;
  };

  useEffect(() => {
    const fullName = (
      getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
    ).fullName;
    const userInfo = getData(
      AuthClient_Name
    ) as KeysAndValues as AuthClient_Data;
    console.log(">>> User details", userInfo);
    setUserData(userInfo);
    setFirstName(fullName?.split(" ")[0]);
    setLastName(fullName?.split(" ")[1]);
    setFullName(fullName);
  }, []);

  const handleChangeName = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        payload: { firstName, lastName },
        message: AuthClient_Messages.ChangeUserName,
      });
      //   console.log("RESULT FROM CHANGE USER NAME", result);
      //   console.log("First name", result?.payload?.returnValue?.firstName);
      setFirstName(result?.payload?.returnValue?.firstName);
      setLastName(result?.payload?.returnValue?.lastName);
    } catch (error) {
      console.log("Caught error", error);
    }
  };

  const handleUpdatePassword = async () => {
    if (password === confirmPassword) {
      setError("Confirm Password match");
      try {
        let result = await ask({
          from: myName,
          to: AuthClient_Name,
          payload: { password },
          message: AuthClient_Messages.UpdatePassword,
        });
        console.log("RESULT FROM UPDATE PASSWORD", result);
      } catch (error) {
        console.log("Caught error", error);
      }
    } else {
      setError("Confirm Password does not match");
    }
  };

  const courseData = getData(
    CourseClient_Name
  ) as KeysAndValues as CourseClient_Data;

  const topics = courseData?.moduleList;

  console.log("topics", topics);
  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const [menuLinks, setMenuLinks] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<Topic[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [leftMenuVisible, setLeftMenuVisible] = useState(true);
  const [leftSidebarVisible, setLeftSidebarVisible] = useState(true);
  const [userDetails, setUserDetails] = useState<any>();
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (menuLinks) {
      timeout = setTimeout(() => {
        const element = document.getElementById("mySidenav");
        if (element) {
          element.style.transform = "translateX(0)";
        }
      }, 100);
    } else {
      const element = document.getElementById("mySidenav");
      if (element) {
        element.style.transform = "translateX(-100%)";
      }
    }

    return () => clearTimeout(timeout);
  }, [menuLinks]);

  const activateSubmenu = (topicId: any) => {
    if (!showSubMenu) {
      console.log("TOPIC ACTIVATION VALUE", topicId);
      setActiveSubMenu(topicId);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      sessions && setSubMenuItems(sessions);
      setShowSubMenu(true);
    } else if (subMenuItems && showSubMenu) {
      setActiveSubMenu(null);
      setShowSubMenu(false);
      const sessions = topics.filter(
        (item: any) => item?.topic?.parentTopicId === topicId
      );
      console.log("TOPIC SESSIONS ACTIVE", sessions);
      if (
        sessions.every((element: any) =>
          subMenuItems.includes(element as never)
        )
      ) {
        setShowSubMenu(false);
      } else {
        sessions && setSubMenuItems(sessions);
        setShowSubMenu(true);
        setActiveSubMenu(topicId);
      }
    } else {
      setShowSubMenu(false);
      setActiveSubMenu(null);
    }
  };

  const navigateToQuiz = async (
    course: string,
    module: string,
    session: string,
    item: any
  ) => {
    console.log("COURSE, MODULE, SESSION", course, module, session);
    try {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: { course, module, session, item },
        message: CourseClient_Messages.NavigateToQuiz,
      });
      console.log("RESULT FROM NAVIGATE QUIZ", result);
      navigate(
        `${Pages.QuizPlayer}/${course}/${module}/${session}/${session}`,
        {
          state: item,
        }
      );
    } catch (error) {
      console.log("Caught error", error);
    }
  };

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
    const getUserDetails = async () => {
      let result = await ask({
        from: myName,
        to: CourseClient_Name,
        payload: {},
        message: CourseClient_Messages.GetProfileDetails,
      });
      console.log(">>> user details result", result?.payload);
      setUserDetails(result?.payload?.returnValue?.userDetails);
    };
    getUserDetails();
  }, []);

  console.log(">>>Course Id", id, courseData?.courseList[0]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-md-12 p-0 mytogclass ${
                isSidebarOpen ? "opened" : ""
              }`}
            >
              <div className="toggleitem">
                <div className="custombar" onClick={toggleSidebar}>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                  <span
                    className={`custom_bar ${
                      isSidebarOpen ? "custom_bar_close" : ""
                    }`}
                  ></span>
                </div>
              </div>
            </div>

            <div className="col-md-12 p-0 mymainparthtml">
              <CoursePlayerSidebar
                sessionId={null}
                courseId={id}
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />

              <div className="mainbody">
                <div className="">
                  <div className="contentpart">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="photoupload">
                          <div className="usersubscribe">
                            <img src={whiteusericon} />
                            {/* <div className="cemera">
                              <img src={camera} />
                            </div> */}
                          </div>
                          <div className="photousername">
                            <h4>{fullName}</h4>
                            <h6>
                              {userDetails?.subscriptionPlan === "free"
                                ? "Free Trial User"
                                : "Paid User"}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="customaccordian">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingMinus"
                              >
                                <button
                                  className={`accordion-button ${
                                    isTabActive("userName") ? "" : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() =>
                                    handleAccordionClick("userName")
                                  }
                                  aria-expanded={isTabActive("userName")}
                                  aria-controls="collapseMinus"
                                >
                                  UPDATE USER NAME
                                </button>
                              </h2>
                              <div
                                id="collapseMinus"
                                className={`accordion-collapse collapse ${
                                  isTabActive("userName") ? "show" : ""
                                }`}
                                aria-labelledby="headingMinus"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="custompassword">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="First Name"
                                          value={firstName}
                                          onChange={(e) =>
                                            setFirstName(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="custompassword"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Last Name"
                                          value={lastName}
                                          onChange={(e) =>
                                            setLastName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 offset-md-4 text-center">
                                      <div className="subbtnmarigin">
                                        <button
                                          onClick={handleChangeName}
                                          className="subscribtgold"
                                        >
                                          CHANGE NAME
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className={`accordion-button ${
                                    isTabActive("password") ? "" : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() =>
                                    handleAccordionClick("password")
                                  }
                                  aria-expanded={isTabActive("password")}
                                  aria-controls="collapseOne"
                                >
                                  UPDATE PASSWORD
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className={`accordion-collapse collapse ${
                                  isTabActive("password") ? "show" : ""
                                }`}
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div id="input-container">
                                      <div className="col-md-12">
                                        <div className="custompassword">
                                          <input
                                            className="form-control"
                                            id="form-input"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            placeholder="New Password"
                                            value={password}
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                          />
                                          <img
                                            id="eyeicon-first"
                                            className="eyeico"
                                            src={
                                              showPassword ? closedEye : eyeIcon
                                            }
                                            onClick={() =>
                                              setShowPassword(!showPassword)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="custompassword">
                                          <input
                                            className="form-control"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            placeholder="Repeat Password"
                                            value={confirmPassword}
                                            onChange={(e) =>
                                              setConfirmPassword(e.target.value)
                                            }
                                          />
                                          <img
                                            className="eyeico"
                                            src={
                                              showPassword ? closedEye : eyeIcon
                                            }
                                            onClick={() =>
                                              setShowPassword(!showPassword)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {error && (
                                      <div style={{ color: "red" }}>
                                        {error}
                                      </div>
                                    )}

                                    <div className="col-md-12 text-center">
                                      <div className="subbtnmarigin">
                                        <button
                                          onClick={() => handleUpdatePassword()}
                                          className="subscribtgold"
                                        >
                                          CHANGE PASSWORD
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header subscription-accordion"
                                id="headingTwo"
                              >
                                <button
                                  className={`accordion-button ${
                                    isTabActive("subscription")
                                      ? ""
                                      : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() =>
                                    handleAccordionClick("subscription")
                                  }
                                  aria-expanded={isTabActive("subscription")}
                                  aria-controls="collapseTwo"
                                  // id="accordionHeadingTwo"
                                >
                                  SUBSCRIPTION
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className={`accordion-collapse collapse ${
                                  isTabActive("subscription") ? "show" : ""
                                }`}
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div>
                                      <div className="col-md-6">
                                        <div className="mixing">
                                          <h4
                                            className="subscription_text"
                                            id="subscription_text_id"
                                          >
                                            Subscription Status:
                                          </h4>
                                          <h5 className="subscription_text">
                                            {userDetails?.subscriptionStatus ===
                                            "expired"
                                              ? "Inactive"
                                              : "Active"}
                                          </h5>
                                        </div>
                                        <div className="mixing">
                                          <h4>Subscription Plan:</h4>
                                          <h5>
                                            {userDetails?.subscriptionPlan
                                              .charAt(0)
                                              .toUpperCase() +
                                              userDetails?.subscriptionPlan.slice(
                                                1
                                              )}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                      <div className="text-center">
                                        <button
                                          className="subscribtgold"
                                          id="subscription_btn"
                                          onClick={() =>
                                            subscriptionNavigation(
                                              courseData?.courseList[0],
                                              SubscriptionType.Individual
                                            )
                                          }
                                        >
                                          UPGRADE COURSE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingThree"
                              >
                                <button
                                  className={`accordion-button ${
                                    isTabActive("report") ? "" : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() => handleAccordionClick("report")}
                                  aria-expanded={isTabActive("report")}
                                  aria-controls="collapseThree"
                                >
                                  REPORT
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className={`accordion-collapse collapse ${
                                  isTabActive("report") ? "show" : ""
                                }`}
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mixing">
                                        <h4>Course Completion:</h4>
                                        <h5>
                                          {userDetails?.courseCompleted
                                            ? "Completed"
                                            : "In Progress"}
                                        </h5>
                                      </div>
                                      <div className="mixing">
                                        <h4>Last Subscription Plan Update:</h4>
                                        <h5>
                                          {userDetails?.lastSubscribedOn !== ""
                                            ? userDetails?.lastSubscribedOn
                                            : "NA"}
                                        </h5>
                                      </div>
                                    </div>
                                    <div id="accordion-report-date">
                                      <div className="col-md-6">
                                        <div className="mixing">
                                          <h4>Start Date:</h4>
                                          <h5>
                                            {userDetails?.subscriptionStartDate !==
                                            ""
                                              ? userDetails?.subscriptionStartDate
                                              : "NA"}
                                          </h5>
                                        </div>
                                        <div className="mixing">
                                          <h4>Result:</h4>
                                          <h5>
                                            {userDetails?.courseCompleted
                                              ? "Pass"
                                              : "In progress"}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                      {/* Set width for full-width centering */}
                                      {userDetails?.courseCompleted && (
                                        <div className="subbtnmarigin">
                                          <button
                                            onClick={() =>
                                              (window.location.href = `https://afmdevbucket.s3.ap-south-1.amazonaws.com/certificates/${userData?.user?.id}/${courseData?.courseList[0]?.courseId}/certificate.png`)
                                            }
                                            className="subscribtgold" // Use the same class here as for the other buttons
                                            style={{
                                              display: "block",
                                              margin: "0 auto",
                                            }} // Center the button and make it full-width
                                            id="accordion-download-certif-btn"
                                          >
                                            DOWNLOAD CERTIFICATE
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {userDetails?.paymentDetails && (
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className={`accordion-button ${
                                      isTabActive("payment") ? "" : "collapsed"
                                    }`}
                                    type="button"
                                    onClick={() =>
                                      handleAccordionClick("payment")
                                    }
                                    aria-expanded={isTabActive("payment")}
                                    aria-controls="collapseFour"
                                  >
                                    Payment Details
                                  </button>
                                </h2>
                                <div
                                  id="collapseFour"
                                  className={`accordion-collapse collapse ${
                                    isTabActive("payment") ? "show" : ""
                                  }`}
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div id="table-content">
                                    <div className="accordion-body">
                                      <div className="row">
                                        <div className="col-md-12 subscribetable">
                                          <div className="payment-table-responsive">
                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">
                                                      Invoice No.
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="payment-date"
                                                    >
                                                      Payment Date
                                                    </th>
                                                    {/* <th
                                                      scope="col"
                                                      className="subscription-duration"
                                                    >
                                                      Subscription Duration
                                                    </th> */}
                                                    <th scope="col">Amount</th>
                                                    <th
                                                      scope="col"
                                                      className="subscription-duration"
                                                    >
                                                      Payment Status
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {userDetails?.paymentDetails?.map(
                                                    (p: any, i: number) => (
                                                      <tr>
                                                        <td>{p?.pgDetails}</td>
                                                        <td>
                                                          {formatDate(
                                                            p?.createdAt
                                                          )}
                                                        </td>
                                                        {/* <td className="nowrap">
                                                          09/03/2023 to
                                                          08/03/2023
                                                        </td> */}
                                                        <td>{p?.amount}</td>
                                                        <td className="nowrap">
                                                          {p?.verificationStatus ===
                                                          "verified"
                                                            ? "Successful"
                                                            : "Failed"}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 p-0">
              <footer>
                <div className="footertext">
                  <div>
                    {/* <p>
                      © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                    </p> */}
                    <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                  </div>
                  <div className="quicklinks">
                    <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                    {/* <a href="#">TERMS</a> */}
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      )}

      {menuLinks && (
        <div id="mySidenav" className="sidenav mymenusuv">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => {
              setMenuLinks(false);
              setLeftSidebarVisible(true);
            }}
          >
            &times;
          </a>

          <div className="menulinks">
            {topics.map((item) =>
              item?.topic?.parentTopicId === null ? (
                item.status === "in-progress" ? (
                  <ul>
                    <li>
                      <a
                        onMouseEnter={() => {
                          activateSubmenu(item?.topic?.id);
                          setShowSubMenu(false);
                        }}
                        onClick={() => {
                          navigate(
                            `${Pages.CourseLanding}/${id}?currentModuleId=${item?.topic?.id}`
                          );
                        }}
                        style={{ cursor: "pointer" }}
                        className={
                          activeSubMenu === item?.topic?.id ? "activelink" : ""
                        }
                      >
                        {item?.topic?.name}
                      </a>
                      {activeSubMenu === item?.topic?.id && // Check if the active submenu matches the current topic's ID
                        subMenuItems &&
                        subMenuItems.map((subMenuItem) =>
                          subMenuItem?.status === "in-progress" ? (
                            subMenuItem?.topic?.topicType === "html" ? (
                              <ul className="submenu">
                                <li
                                  onClick={() =>
                                    navigate(
                                      `${Pages.Player}/${id}/${item?.topic?.id}/${subMenuItem?.topic?.id}/${subMenuItem?.topic?.id}`,
                                      { state: item }
                                    )
                                  }
                                >
                                  <a href="#">{subMenuItem?.topic?.name}</a>
                                </li>
                              </ul>
                            ) : (
                              id &&
                              item?.topic?.id &&
                              subMenuItem?.topic?.id &&
                              item && (
                                <ul className="submenu">
                                  <li
                                    onClick={() =>
                                      navigateToQuiz(
                                        id,
                                        item?.topic?.id,
                                        subMenuItem?.topic?.id,
                                        subMenuItem
                                      )
                                    }
                                  >
                                    <a href="#">{subMenuItem?.topic?.name}</a>
                                  </li>
                                </ul>
                              )
                            )
                          ) : (
                            <ul className="submenu">
                              <li>
                                <a href="#" className="disablelink">
                                  {subMenuItem?.topic?.name}
                                </a>
                              </li>
                            </ul>
                          )
                        )}
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a className="disablelink">{item?.topic?.name}</a>
                    </li>
                  </ul>
                )
              ) : (
                <ul></ul>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
