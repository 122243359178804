import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initModulo } from "./modulo/modulo";
import { config } from "./config";
import { BrowserRouter } from "react-router-dom";
import { getData, tell } from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Messages,
  AuthClient_Name,
  AuthClient_States,
} from "./actors/auth/types";
import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material";

//---------------------------------------------------------------------------------

const myName = "index";

initModulo({ config });

// Validate existing session
if (getData(AuthClient_Name).state === AuthClient_States.Authenticated)
  tell({
    from: myName,
    to: AuthClient_Name,
    message: AuthClient_Messages.Validate,
  });

//---------------------------------------------------------------------------------

const myTheme: MantineThemeOverride = {
  colorScheme: "light",
  primaryColor: "yellow",
  defaultRadius: 0,
  fontFamily: "'Open Sans', sans-serif",
};

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider theme={myTheme} withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        {/* <ThemeProvider theme={theme}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </ThemeProvider> */}
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
