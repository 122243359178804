import React, { useEffect, useState } from "react";
import "../dummy/css/landingStyle.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import userfaceicon from "../../assets/Images/userfaceicon.svg";
import logouticon from "../../assets/Images/logouticon.svg";
import leftarrowicon from "../../assets/Images/leftarrowicon.svg";
import logoafmcart from "../../assets/Images/logoafmcart.png";
import CompanyInfo from "../dummy/CompanyInfo";
import { Pages } from "../../Pages";
import CompanyAdminSidebar from "./CompanyAdminSidebar";
import UpdateCompanyName from "../dummy/UpdateCompanyName";
import UserdasDetails from "../dummy/UserdasDetail";
import DownloadSamplebox from "../dummy/DownloadSamplebox";
import CompanyUsersReport from "../dummy/CompanyUsersReport";
import UserdasBoard from "../dummy/UserdasBoard";
import { KeysAndValues, ask, getData } from "lib-js-c-modulo/index.mjs";
import {
  AuthClient_Data,
  AuthClient_Messages,
  AuthClient_Name,
} from "../../actors/auth/types";
import { useNavigate } from "react-router-dom";

const CompanyCommon = ({ page }: any) => {
  const navigate = useNavigate();
  const [companyPage, setCompanyPage] = useState(Pages.CompanyInfo);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  useEffect(() => {
    setCompanyPage(page);
  }, [page]);

  console.log("page is", page, companyPage);
  let content;
  switch (companyPage) {
    case Pages.CompanyInfo: //company info
      content = <CompanyInfo />;
      break;
    case Pages.UpdateCompanyName: //manage company
      // Render content for Page2
      content = <UpdateCompanyName />;
      break;
    case Pages.UserdasDetail: //subscription and payment details together page
      // Render content for Page2
      content = <UserdasDetails />;
      break;
    case Pages.DownloadSamplebox: //download
      // Render content for Page2
      content = <DownloadSamplebox />;
      break;
    case Pages.CompanyUsersReport:
      // Render content for Page2
      content = <CompanyUsersReport />;
      break;
    case Pages.UserdasBoard:
      // Render content for Page2
      content = <UserdasBoard />;
      break;
    default:
      content = <CompanyInfo />;
  }

  const navigateFunction = async (page: any) => {
    console.log("page", page);
  };

  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarTogglerOpen(!navbarTogglerOpen);
  };

  const doLogout = async () => {
    try {
      let result = await ask({
        from: "company-admin",
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate("/");
    } catch (err) {
      navigate(Pages.Dashboard);
    }
  };

  const fullName = (
    getData(AuthClient_Name) as KeysAndValues as AuthClient_Data
  ).fullName;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div>
              <div className="goldenmenu">
                <nav className="navbar navbar-expand-xl">
                  <div className="container-fluid p-0">
                    <a className="navbar-brand coamntitlepagebrand">
                      Corporate User Dashboard
                    </a>
                    <div className="d-flex justify-content-between mymobieltop">
                      <div
                        className="dnonelogo"
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                      >
                        <span className="threedot">
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </div>
                      <div className="dnonelogo">
                        <img className="centerlgoo" src={logoafmcart} />
                      </div>
                      <div>
                        <button
                          className={`navbar-toggler ${
                            navbarTogglerOpen ? "main" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarTogglerDemo01"
                          aria-controls="navbarTogglerDemo01"
                          aria-expanded={navbarTogglerOpen ? "true" : "false"}
                          aria-label="Toggle navigation"
                        >
                          <span
                            className={`navbar-toggler-icon${
                              navbarTogglerOpen ? " red-icon" : ""
                            }`}
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div
                      className={`collapse navbar-collapse ${
                        navbarTogglerOpen ? "show" : ""
                      }`}
                      id="navbarTogglerDemo01"
                    >
                      <ul className="navbar-nav ms-auto  mb-lg-0">
                        <li
                          className="nav-item"
                          onClick={() => navigate(Pages.Dashboard)}
                        >
                          <a className="nav-link" aria-current="page" href="#">
                            <img className="me-2" src={leftarrowicon} />
                            Back to Dashboard
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => navigate(Pages.Profile)}
                        >
                          <a className="nav-link" href="#">
                            <img className="me-2" src={userfaceicon} />
                            {fullName}
                          </a>
                        </li>
                        <li className="nav-item" onClick={doLogout}>
                          <a className="nav-link" href="#">
                            <img className="me-2" src={logouticon} /> Log out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

              <CompanyAdminSidebar
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
                setCompanyPage={setCompanyPage}
                navigateFunction={navigateFunction}
                companyPage={companyPage}
              />
            </div>
            <div className="col-md-12 p-0">
              {content}
              {/* <footer style={{ zIndex: 5 }}>
                <div className="footertext">
                  <div>
                    <p>
                      © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                    </p>
                  </div>
                  <div className="quicklinks">
                    <a href="#">PRIVACY</a>
                    <a href="#">TERMS</a>
                  </div>
                </div>
              </footer> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyCommon;
