import React, { useEffect, useState } from "react";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import Rectangle from "../dummy/images/Rectangle 66-img.png";
import Group318 from "../dummy/images/Group 318.png";
import Group319 from "../dummy/images/Group 319.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group164 from "../dummy/images/Group 164 (1).png";
import Group325 from "../dummy/images/Group 325 (1).png";
import Group212 from "../dummy/images/Group 212.png";
import { styled } from "styled-components";
import { TextInput } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faEye,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { useFocusTrap } from "@mantine/hooks";
import { AuthButton } from "../common/styles";
import {
  CourseClient_Name,
  CourseClient_Messages,
} from "../../actors/course/types";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
} from "../../actors/company-admin/types";
import { useNavigate } from "react-router";

const EditCompanyInfo = () => {
  const [inputValue, setInputValue] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [info, setInfo] = useState<any>("");
  const [userValue, setUserValue] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const loginElement = useFocusTrap();
  // const [dataValue, setdataValue] = useState<any>("");
  // setdataValue(data);
  const navigate = useNavigate();

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // setInputValue(event.target.value);
  //   const { name, value } = event.target;
  //   setInputValue((prevInputValue: any) => ({
  //     ...prevInputValue,
  //     [name]: value,
  //   }));
  // };

  interface loginData {
    addCompany: string;
    addCompanyInfo: string;
  }
  const myName = "Dashboard-gui";

  const handleClickFormSubmit = async () => {
    // setName([...name, name]);
    // setInfo([...info, info]);
    try {
      console.log("addCompanyInfo is coming", name, info);
      let result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {
          // firstInputValue: inputValue.first,
          // secondInputValue: inputValue.second,
          addCompany: name,
          addCompanyInfo: info,
        },
        message: CompanyAdminClient_Messages.AddUpdateCompanyInfo,
      });
      console.log("Add Company  Update user result", result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchData = async () => {
    try {
      // const companyInfo = payload?.returnValue?.companyInfo;

      let result: any = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.CompanyInfoGet,
      });
      console.log("Response companyinfo from API:", result);
      setData(result?.payload?.returnValue?.companyInfo);
      setName(result?.payload?.returnValue?.companyInfo[0]?.name);
      setInfo(result?.payload?.returnValue?.companyInfo[0]?.info);
    } catch (error) {
      console.log(error);
      // setData({ error: "Error fetching data" });
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  console.log("value checking", name, info);
  // const addCmpnayuser = async () => {
  //   let result = await ask({
  //     from: myName,
  //     to: CompanyAdminClient_Name,
  //     payload: { handleClickFormSubmit: handleClickFormSubmit() },
  //     message: CompanyAdminClient_Messages.AddUpdateCompanyInfo,
  //   });
  //   console.log("", result);
  // };

  // console.log(userValue, "handlesubmit working");

  // const addCompanyUser = async () => {
  //   let result = await ask({
  //     from: myName,
  //     to: CourseClient_Name,
  //     payload: { addcompany: "add company user" },
  //     message: CourseClient_Messages.handleClickFormSubmit,
  //   });
  //   console.log("", result);
  // };

  return (
    <div>
      <Container>
        <div>
          <img src={Rectangle} alt="" id="Rectangle_img" />
          <img src={avilogo} alt="" id="avilogo_img" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: "200px",
              left: "10px",
            }}
          >
            <ol id="logo_ol">
              <li>
                <img src={Group318} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group319} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group320} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group324} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group321} alt="" className="sidebar_images" />
              </li>
            </ol>
            <ol id="list_ol">
              <li>
                <a href="" style={{ color: "#C7B277" }}>
                  COMPANY INFO
                </a>
              </li>
              <li>
                <a href="">MANAGER</a>
              </li>
              <li>
                <a href="">COURSE COMPLIANCE REOPRT</a>
              </li>
              <li>
                <a href="">SUBSCRIPTION STATUS</a>
              </li>
              <li>
                <a href="">SUPPORT</a>
              </li>
            </ol>
            <button id="go_to_btn">GO TO COURSE</button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            left: "300px",
            backgroundColor: "#C7B277",
            width: "100%",
          }}
        >
          <p id="corp_heading">Corporate User Dashboard</p>

          <ol id="nav-ol">
            <li>
              <img
                src={vectorimg2}
                alt=""
                className="nav-img"
                style={{ width: "10px" }}
              />
            </li>
            <li>
              <a href="">Back To Dashboard</a>
            </li>
            <li>
              <img src={Group164} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Arron Lee</a>
            </li>
            <li>
              <img src={Group325} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Log Out</a>
            </li>
          </ol>
        </div>
        {/* <div style={{ position: "absolute", top: "110px", left: "330px" }}>
       
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "170px",
            left: "401px",
            backgroundColor: "#062A3B",
            color: "white",
            border: "1px solid black",
            padding: "10px 15px 10px 15px",
            width: "500px",
          }}
        >
          <h5>UPDATE COMPANY NAME</h5>
          <FontAwesomeIcon icon={faAngleDown} id="font_icon" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "378px",
            left: "400px",
            backgroundColor: "#062A3B",
            color: "white",
            border: "1px solid black",
            // padding: "10px 15px 10px 15px",
            width: "500px",
            height: "7vh",
          }}
        >
          <div id="container_div">
            <h5 id="company_info" style={{ top: "200px" }}>
              UPDATE COMPANY INFO
            </h5>
            <FontAwesomeIcon icon={faAngleDown} id="font_icon_2" />

            <div id="inside_div">
              <div id="container_div-2">
                <div
                  style={{
                    position: "absolute",
                    top: "150px",
                    left: "100px",
                    color: "black",
                  }}
                >
                  {/* {data?.map((item: any, index: any) => (
                    <div key={index}>
                      <p>Name: {item.name}</p>
                      <p>info: {item.info}</p>
                    </div>
                  ))} */}
                </div>
                {/* <form> */}
                <TextInput
                  type="text"
                  className="inside_div_para"
                  // name="addCompany"
                  value={name}
                  // value={"type"}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your company info here..."
                  // style={{ boxSizing: "border-box" }}
                />

                <TextInput
                  type="text"
                  // name="info"
                  value={info}
                  placeholder="Company Name"
                  id="box_inside_iput"
                  onChange={(e) => setInfo(e.target.value)}
                  // style={{ top: "120px" }}
                />
                <div> </div>
                <AuthButton
                  // type="submit"
                  id="edit_btn"
                  onClick={handleClickFormSubmit}
                >
                  Submit
                </AuthButton>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div>
        </div> */}

        {/* footer */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#FBFAFA",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <p id="footer_p">
            © Copyright 2023 Aviation Mastermind. All Rights Reserved.
          </p>
          <ol id="footer_ol">
            <li>PRIVACY & TERMS</li>
            {/* <li>PRIVACY</li>
            <li>TERM</li> */}
          </ol>
        </div>
      </Container>
    </div>
  );
};

export default EditCompanyInfo;
const Container = styled.div`
    #Rectangle_img{
    height:208vh;
    width:300px;
    
    }
    
    #avilogo_img{
        position:absolute;
        top:30px;
        left:50px;
    }
    
    #logo_ol{
    line-height:30px;
    list-style:none;
    /
    }
    
    #list_ol{
        line-height:30px;
        list-style:none;
       font-size:13px;
     
    }
    li{
        padding-top:15px; 
    }
    
    #list_ol li a{
    text-decoration: none;
     color:#FFFFFF;   
    }
    
    .sidebar_images{
    width:18px;
    }
    
    #go_to_btn{
    position:absolute;
    top:280px;
    left:30px; 
    background-color:#C7B277; 
    padding:8px 15px 8px 15px;
    border:none;
    font-size:13px;
     
    }
    #corp_heading{
        margin-top:19px;
        margin-left:15px;
    }
    #nav-ol{
    display:flex;
    flex-direction:row;
    
    color:#FFFFFF;
    // position:absolute;
    // top:3px;
    // margin-left:250px;
    // left:30px; 
    // width:82%;
    margin-left:220px
    
    }
    #nav-ol li {
    list-style:none; 
   
    
    }
    #nav-ol li a{
        margin-left:5px;
        color:#FFFFFF; 
        text-decoration:none; 
    }
    #nav-ol li img{
    margin-left:40px; 
    color:#FFFFFF; 
    }
    .nav-img{
    width:30px;
    }
    
    #arron_crop_img{
        width:400px;
        margin-bottom:50px;
        margin-left:200px;
        // position:absolute;
        // top:110px;
        // left:370px;
    
    }
    
    #footer_ol{
        display:flex;
        flex-direction:row;
       
    
    }
    #footer_ol li{
        margin-left:50px;
        list-style:none; 
    }
    #footer_p{
        margin-top:15px;
    }

    #edit_btn{
        position:absolute;
        top:345px;
        left:150px;
       background-color:#C7B277; 
    padding:12px 32px 12px 32px;
    border:none;
    font-size:15px;
    }


#font_icon{
  margin-left:200px;
  }
  #font_icon_2{
    position:absolute;
    top:170px;
    left:450px;
    // background-color:yellow; 
  }
#container_div{
    border:1px solid #C7B277;
    width:500px;
    height:20vh;
    position:absolute;
    bottom:75px;
    left:0px;
 
}

#box_inside_iput{
    width:450px;
    height:7vh;
    position:absolute;
    bottom:455px;
    right:30px;
    // margin-top:40px;
    // margin-left:20px;

}

#container_div-2{
    border:1px solid #C7B277;
    width:500px;
    height:60vh;
    position:absolute;
    top:171px;
    // left:1 0px;
 
}
// #inside_div{
// border:1px solid #C7B277;
// width:480px;
// height:50vh;
// margin-top:15px;
// margin-left:10px;
// padding-bottom:130px;
// padding-top:30px;
// padding-left:30px;
// padding-right:50px;
// }

.inside_div_para{
    font-size:12px;
    // width:420px;
    // height:250px;
    // padding:   10px 20px 40px 20px;
    // margin-right:100px;
    // border:1px solid #C7B277; // double border//
width:480px;
height:50vh;
margin-top:15px;
margin-left:10px;
// padding-bottom:130px;
padding-top:130px;
padding-left:30px;
padding-right:50px;
box-sizing:border-box;
box-sizing: border-box;
}

#company_info{
  position:absolute;
  top:165px;
  left:10px;

}
    `;
