import { useNavigate } from "react-router-dom";
import { Pages } from "../../Pages";
import "bootstrap/dist/css/bootstrap.min.css";
import logoafmcart from "../../assets/logoafmcart.png";
import homeicon from "../../assets/homeicon.svg";
import "./ThankYou.css";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="optlogin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 cartlogoleft">
              <img src={logoafmcart} alt="Logo" />
            </div>
            <div className="col-6 homeright">
              <a>
                <img src={homeicon} alt="Home Icon" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="thankyou-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="69"
                  height="69"
                  viewBox="0 0 69 69"
                  fill="none"
                >
                  <path
                    d="M18.3998 34.5L32.1998 46L50.5998 23M34.4998 66.7C30.2712 66.7 26.0841 65.8672 22.1774 64.249C18.2707 62.6308 14.721 60.2589 11.731 57.2689C8.74092 54.2788 6.36909 50.7291 4.75088 46.8225C3.13268 42.9158 2.2998 38.7286 2.2998 34.5C2.2998 30.2715 3.13268 26.0843 4.75088 22.1776C6.36909 18.271 8.74092 14.7213 11.731 11.7312C14.721 8.74116 18.2707 6.36933 22.1774 4.75113C26.0841 3.13293 30.2712 2.30005 34.4998 2.30005C43.0398 2.30005 51.23 5.69254 57.2686 11.7312C63.3073 17.7699 66.6998 25.9601 66.6998 34.5C66.6998 43.04 63.3073 51.2302 57.2686 57.2689C51.23 63.3076 43.0398 66.7 34.4998 66.7Z"
                    stroke="black"
                  />
                </svg>
                <h3>Thank you</h3>
                <p>Your registration is complete</p>
                <a
                  className="sign-btn"
                  onClick={() => navigate(Pages.Dashboard)}
                  style={{ cursor: "pointer" }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
