import React from "react";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import Rectangle from "../dummy/images/Rectangle 66-img.png";
import Group318 from "../dummy/images/Group 318.png";
import Group319 from "../dummy/images/Group 319.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group164 from "../dummy/images/Group 164 (1).png";
import Group325 from "../dummy/images/Group 325 (1).png";
import Group212 from "../dummy/images/Group 212.png";
import { styled } from "styled-components";
import {
  faAngleDoubleDown,
  faAnglesLeft,
  faAnglesRight,
  faArrowAltCircleLeft,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faBangladeshiTakaSign,
  faLeftLong,
  faLongArrowAltRight,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useState, useEffect } from "react";
import { string } from "zod";
import axios from "axios";
import {
  CompanyAdminClient_Messages,
  CompanyAdminClient_Name,
} from "../../actors/company-admin/types";
import { ask } from "lib-js-c-modulo/index.mjs";
const AddCompanyUsers = () => {
  // interface MyObject {
  //   UserID: number;
  //   SubscriptionID: string;
  //   firstName: string;
  //   lastName: string;
  //   registeredon: string;
  //   courseStatus: string;
  //   assessment: string;
  //   assessmentResults: string;
  //   action: string;
  // }

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [items, setItems] = useState([]);
  const [Data, setData] = useState<any>([]);
  const [excelupload, setExcelUpload] = useState([]);
  // ----------------------------------------------
  const [downloadUserData, setDownloadUserData] = useState<any>([]);
  // ----------------------------------------------
  const myName = "add-company-users-gui";

  const readExcel = async (file: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    try {
      let data: any = await promise;
      setItems(data); // Update items state with the new data
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {
          excellValue: data, // Use the updated data directly here
        },
        message: CompanyAdminClient_Messages.ExcellFileUpload,
      });
      console.log("Add Company Update user result excell values", result);
    } catch (error) {
      console.log(error);
    }
  };

  // const jsonData: any[] = userData; // Your JSON data goes here
  // const handleDownload = async () => {

  //   try {
  //     const result = await ask({
  //       from: myName,
  //       to: CompanyAdminClient_Name,
  //       payload: {},
  //       message: CompanyAdminClient_Messages.DownloadSampleExcel,
  //     });
  //     console.log("download report list coming ", result);
  //     setUserData(result?.payload?.returnValue?.allUser);
  //     // setMapdata(result?.payload?.returnValue?.allUser);
  //   } catch (error) {
  //     console.log(error);
  //     return; // Exit the function if there's an error
  //   }

  //   // Create a new workbook and worksheet
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(userData);

  //   // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   // Convert the workbook to a binary XLSX file
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });

  //   // Create a Blob object from the buffer
  //   const blob = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   // Save the Blob as a file using FileSaver.js
  //   saveAs(blob, "example.xlsx");
  // };
  const handleDownload = async () => {
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const columns = [
      // "UserId",
      // "SubscriptionID",
      // "InviteSentDate",
      "First_Name",
      "Last_Name",
      "Designation",
      "Email",
      "Mobile_Number",
      // "RegistrationStatus",
      // "RegistrationDate",
    ];
    const worksheet = XLSX.utils.aoa_to_sheet([columns]);
    // const worksheet = XLSX.utils.json_to_sheet(userData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, "AFM_User_List.xlsx");
  };

  const handleShowingData = async () => {
    try {
      const result = await ask({
        from: myName,
        to: CompanyAdminClient_Name,
        payload: {},
        message: CompanyAdminClient_Messages.GetMyCompanyUsers,
      });
      console.log("download report list coming ", result);
      setData(result?.payload?.returnValue?.allUser);
    } catch (error) {
      console.log(error);
      return; // Exit the function if there's an error
    }
  };

  const handleDownloadUserData = async () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(Data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, "User_Report.xlsx");
  };

  useEffect(() => {
    handleShowingData();
  }, []);

  return (
    <div>
      <Container>
        <div>
          <img src={Rectangle} alt="" id="Rectangle_img" />
          <img src={avilogo} alt="" id="avilogo_img" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: "200px",
              left: "10px",
            }}
          >
            <ol id="logo_ol">
              <li>
                <img src={Group318} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group319} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group320} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group324} alt="" className="sidebar_images" />
              </li>
              <li>
                <img src={Group321} alt="" className="sidebar_images" />
              </li>
            </ol>
            <ol id="list_ol">
              <li>
                <a href="" style={{ color: "#C7B277" }}>
                  COMPANY INFO
                </a>
              </li>
              <li>
                <a href="">MANAGE COMPANY USERS</a>
              </li>
              <li>
                <a href="">COURSE COMPLIANCE REOPRT</a>
              </li>
              <li>
                <a href="">SUBSCRIPTION STATUS</a>
              </li>
              <li>
                <a href="">SUPPORT</a>
              </li>
            </ol>
            <button id="go_to_btn">GO TO COURSE</button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            left: "300px",
            backgroundColor: "#C7B277",
            width: "100%",
          }}
        >
          <p id="corp_heading">Corporate User Dashboard</p>

          <ol id="nav-ol">
            <li>
              <img
                src={vectorimg2}
                alt=""
                className="nav-img"
                style={{ width: "10px" }}
              />
            </li>
            <li>
              <a href="">Back To Dashboard</a>
            </li>
            <li>
              <img src={Group164} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Arron Lee</a>
            </li>
            <li>
              <img src={Group325} alt="" className="nav-img" />
            </li>
            <li>
              <a href="">Log Out</a>
            </li>
          </ol>
        </div>
        <div style={{ position: "absolute", top: "150px", left: "350px" }}>
          <h6 id="status_heading">STATUS</h6>
          <ol id="content_ol">
            <li>
              Total Registered User: <a href="">40</a>
            </li>
            {/* <li id="certified_user_li">
              Certified Users: <a href="">20</a>
            </li> */}
          </ol>
        </div>
        <div
          style={{
            position: "absolute",
            top: "350px",
            left: "350px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <label
              htmlFor="upload_btn"
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "36px",
                textAlign: "center",
                letterSpacing: "0.0859em",
                textTransform: "uppercase",
                backgroundColor: "#C7B277",
                padding: "0 22px",
                boxShadow: "none",
                outline: "none",
                border: "1px solid #C7B277",
                transition: "all 0.4s",
                cursor: "pointer",
              }}
            >
              Upload From Excel
            </label>
            <input
              id="upload_btn"
              type="file"
              style={{ display: "none" }}
              onChange={(e: any) => {
                const file = e?.target?.files[0];
                readExcel(file);
              }}
            />
          </div>
          <h6 id="download_heading">
            <button style={{ border: "none" }} onClick={handleDownload}>
              Download Sample Excel
            </button>
          </h6>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "450px",
            left: "300px",
          }}
        >
          {/* <ol id="input_ol">
            <li>
              <input type="radio" name="" id="" />
            </li>
            <li className="input_second_list">All</li>
            <li>
              <input type="radio" name="" id="" />
            </li>
            <li className="input_second_list">Registered</li>
            <li>
              <input type="radio" name="" id="" />
            </li>
            <li className="input_second_list">Awaiting</li>
          </ol> */}
          {/* <ol id="input_second_ol">
            <li>All</li>
            <li>Registered</li>
            <li>Awaiting</li>
          </ol> */}
        </div>
        <div>
          <table>
            <tr>
              <th>User ID</th>
              <th>Subscription ID</th>
              <th>Invite Sent Date</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Designation</th>
              <th>Email ID </th>
              <th>Registration Status</th>
              <th>Registration Date</th>
              <th>Action &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
            </tr>
            {Data?.map((obj: any) => (
              <>
                <tr>
                  <td>{obj?.id}</td>
                  <td>{obj?.subscriptionId ? "1gh23" : "s324"}</td>
                  <td>
                    {obj?.createdAt
                      ? new Date(obj?.createdAt).toISOString().split("T")[0]
                      : "NA"}
                  </td>
                  <td>{obj?.firstName}</td>
                  <td>{obj?.lastName}</td>
                  <td>{obj?.designation}</td>
                  <td>{obj?.email}</td>
                  <td>{obj?.registrationStatus}</td>
                  <td>
                    {obj?.validateOn
                      ? new Date(obj?.validateOn).toISOString().split("T")[0]
                      : "Not Registered"}
                  </td>
                  <td>
                    <a href="">1. Copy Invite</a>
                    <br />
                    <a href="">2. Delete</a>
                    <br />
                    <a href="">3. Resend Invite</a>
                  </td>
                </tr>
              </>
            ))}
          </table>
        </div>

        <div>{/* <button id="edit_btn">DOWNLOAD REPORT</button> */}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "800px",
            position: "absolute",
            top: "1200px",
            left: "400px",
          }}
        >
          {/* <button className="pre_next_btn">
            <span id="left_side_icon">
              <FontAwesomeIcon icon={faLeftLong} id="font_icon" />
            </span>
            Previous{" "}
          </button>
          <button className="pre_next_btn">
            Next{" "}
            <span id="right_side_icon">
              <FontAwesomeIcon icon={faRightLong} id="font_icon" />
            </span>
          </button> */}
        </div>

        {/* footer */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#FBFAFA",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <p id="footer_p">
            © Copyright 2023 Aviation Mastermind. All Rights Reserved.
          </p>
          <ol id="footer_ol">
            <li>PRIVACY & TERMS</li>
            {/* <li>TERM</li> */}
          </ol>
        </div>
      </Container>
      {/* ************************************************************************************************* */}
      <div>
        <table>
          <tr>
            <th>User ID</th>
            <th>Subscription ID</th>
            <th>Invite Sent Date</th>
            <th>First Name</th>
            <th>Last Name</th>
            {/* ******************* */}
            <th>Course status</th>
            <th>Assessment </th>
            <th>Assessment Result</th>
            <th>Registration Date</th>
            <th>Action &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
          </tr>
          {Data?.map((obj: any) => (
            <>
              <tr>
                <td>{obj?.id}</td>
                <td>{obj?.subscriptionId ? "1gh23" : "s324"}</td>
                <td>
                  {obj?.createdAt
                    ? new Date(obj?.createdAt).toISOString().split("T")[0]
                    : "NA"}
                </td>
                <td>{obj?.firstName}</td>
                <td>{obj?.lastName}</td>
                {/* ********************** */}
                <td>
                  {obj?.certificates?.length ? "Certified" : "In-Progress"}
                </td>
                <td>{obj?.certificates?.length ? "Pass" : "Not Attempted"}</td>
                <td>
                  {obj?.certificates?.length
                    ? obj?.certificates[0].score
                    : "Not Attempted"}
                </td>
                <td>
                  {obj?.validateOn
                    ? new Date(obj?.validateOn).toISOString().split("T")[0]
                    : "Not Registered"}
                </td>
                <td>
                  {/* <a href="">1. Copy Invite</a>
                    <br />
                    <a href="">2. Delete</a> */}
                  <br />
                  {obj?.certificates?.length ? (
                    <a
                      href={`https://afmdevbucket.s3.ap-south-1.amazonaws.com/certificates/${obj?.id}/${obj?.certificates[0]?.courseId}/certificate.png`}
                    >
                      Download Certificate
                    </a>
                  ) : (
                    <a href="#">No Certificate</a>
                  )}
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
      <div>
        <button onClick={() => handleDownloadUserData()}>
          Download User Data
        </button>
      </div>
    </div>
  );
};

export default AddCompanyUsers;
const Container = styled.div`
    #Rectangle_img{
    height:208vh;
    width:300px;
    
    }
    
    #avilogo_img{
        position:absolute;
        top:30px;
        left:50px;
    }
    
    #logo_ol{
    line-height:30px;
    list-style:none;
    /
    }
    
    #list_ol{
        line-height:30px;
        list-style:none;
       font-size:13px;
     
    }
    li{
        padding-top:15px; 
    }
    
    #list_ol li a{
    text-decoration: none;
     color:#FFFFFF;   
    }
    
    .sidebar_images{
    width:18px;
    }
    
    #go_to_btn{
    position:absolute;
    top:280px;
    left:30px; 
    background-color:#C7B277; 
    padding:8px 15px 8px 15px;
    border:none;
    font-size:13px;
     
    }
    #corp_heading{
        margin-top:19px;
        margin-left:15px;
    }
    #nav-ol{
    display:flex;
    flex-direction:row;
    
    color:#FFFFFF;
    // position:absolute;
    // top:3px;
    // margin-left:250px;
    // left:30px; 
    // width:82%;
    margin-left:240px
    
    }
    #nav-ol li {
    list-style:none; 
    
    }
    #nav-ol li a{
        margin-left:5px;
        color:#FFFFFF;  
    }
    #nav-ol li img{
    margin-left:40px; 
    color:#FFFFFF; 
    }
    .nav-img{
    width:30px;
    }
    
#status_heading{
    border:1px solid #C7B277;
    width:400px;
    height:8vh;
    background-color:#062A3B;
    padding-top:18px;
    padding-left:20px;
    color:#FFFFFF;
    position:absolute;
    top:0px;
    // margin-left:40px;
}

#content_ol{
    display:flex;
    flex-direction:row;
    border:1px solid #C7B277;
    width:400px;
    height:17vh;
    // margin-left:40px;
    padding-top:18px;
    padding-left:20px;
    // color:#FFFFFF;
    position:absolute;
    top:47px;
    left:0px;
    // margin-bottom:100px;
}
#content_ol li{
    list-style:none;
    // margin-left:2px;
    // position:absolute;
    color:#050505;

}
#certified_user_li{
    margin-left:14px;
}

#content_ol li a{
    text-decoration:none;
    color:#C7B277;
}

#upload_btn{
  background-color:#C7B277;
  border:none;
  padding:10px 18px 10px 18px;
}

#download_heading{
margin-left:50px;
margin-top:10px;
}

#input_ol{
display:flex;
flex-direction:row;
// margin-left:30px;
position:absolute;
left:0px;
}
#input_ol li{
  margin-left:10px;
  list-style:none;
}
#input_ol li input{
  transform: scale(1.5);
  margin-left:30px;
}
.input_second_list{
  // margin-left:20px;  
  list-style:none;
  

}
// #input_second_ol li{
//   margin-left:30px;
//   list-style:none;
// }


table {
    // font-family: arial, sans-serif;
    border-collapse: collapse;
    width:50%;
    position:absolute;
    top:550px;
    left:320px;

  }
  
  th{
    background-color: #062A3B;
    color:#FFFFFF;
  }
  td, th {
    border: 1px solid black;
    text-align: left;
    padding: 18px;
    font-size:12px;
  }
  
.pre_next_btn{
  padding:8px 15px 8px 15px;
  background-color:#062A3B;
  color:#FFFFFF;
  border:none;
 
}

#left_side_icon{
  margin-right:15px;
}

#right_side_icon{
  margin-left:15px;
}






 #footer_ol{
        display:flex;
        flex-direction:row;
       
    
    }
    #footer_ol li{
        margin-left:50px;
        list-style:none; 
    }
    #footer_p{
        margin-top:15px;
    }

    #edit_btn{
        position:absolute;
        top:600px;
        left:340px;
       background-color:#C7B277; 
    padding:12px 32px 12px 32px;
    border:none;
    font-size:12px;
    }
    `;
