import { Alert } from "@mantine/core";
import { InfoCircle } from "tabler-icons-react";

interface ErrorMessageProps {
  error : string
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <Alert
      icon={<InfoCircle size={16} />}
      title="Error"
      color="red"
      variant="filled"
      style={{ width: "100%" }}
    >
      ERROR: {error}
    </Alert>
  )
};

export default ErrorMessage;
