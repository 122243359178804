import React, { useEffect, useState } from "react";
import {
  CourseClient_Messages,
  CourseClient_Name,
} from "../../actors/course/types";
import { ask } from "lib-js-c-modulo/index.mjs";
import { AuthClient_Messages, AuthClient_Name } from "../../actors/auth/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import userIcon from "./images/usericon.svg";
import avilogo from "./images/logo.svg";
import addNote from "./images/addnote.svg";
import bookMark from "./images/Bookmark.svg";
import leftWing from "./images/leftwing.png";
import rightWing from "./images/rightwing.png";
import rightIcon from "./images/righticon.svg";
import lockIcon from "./images/lockicon.svg";
import wingimg from "../../assets/Images/wing-img.png";
import styled from "@emotion/styled";
import "./CertificateList.css";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";

const CertificateList = () => {
  const myName = "certificate-list";
  const { courseId } = useParams();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        let result = await ask({
          from: myName,
          to: CourseClient_Name,
          payload: {},
          message: CourseClient_Messages.FetchUserCertificates,
        });
        console.log(
          "Certificate list result",
          result?.payload?.returnValue?.userCertificates
        );
        setCertificates(result?.payload?.returnValue?.userCertificates);
      } catch (error) {
        console.log("Caught error", error);
      }
    };
    fetchCertificates();
  }, []);

  const navigateCertificateDownload = async (certificate: any) => {};

  const doLogout = async () => {
    try {
      let result = await ask({
        from: myName,
        to: AuthClient_Name,
        message: AuthClient_Messages.Logout,
      });
      console.log("RESULT FROM LOGOUT", result);

      if (!result!.error) navigate(Pages.Home);
    } catch (err) {
      // navigate(Pages.Dashboard);
      console.log("Logout error", err);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0 mytogclass">
            <div className="toggleitem">
              <div className="custombar">
                <span className="custom_bar"></span>
                <span className="custom_bar"></span>
                <span className="custom_bar"></span>
              </div>
              <div className="closbar">
                <span className="close_bar1"></span>
                <span className="close_bar2"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0 mymainparthtml">
            {/* <!-- leftmenu Blue Part --> */}
            {/* <div className="sidebar" id="mysidebar">
              <div className="innersider">
                <img className="avilogo" src={avilogo} />
                <div className="username">
                  <div className="usericon">
                    <img src={userIcon} />
                  </div>
                  <div className="usertext">
                    <h4>Arron Lee</h4>
                    <h6>
                      <span>Status:</span>{" "}
                      <span className="Acitve">Active</span>{" "}
                      <span>/ Plan:</span> Free
                    </h6>
                  </div>
                </div>
                <div className="viewprofile">
                  <button className="goldenbtn">View Profile</button>
                </div>
                <div className="processbar">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: "10px" }}
                      role="progressbar"
                      aria-valuenow={10}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <span>1% Complete</span>
                </div>
                <div className="buttonmargin">
                  <button className="goldenbtn">COURSE OUTLINE</button>
                </div>
                <div className="quickmenulinks">
                  <h2>MENU</h2>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Notes</a>
                    </li>
                    <li>
                      <a href="#" onClick={() => navigate(Pages.Bookmarks)}>
                        BOOKMARKS
                      </a>
                    </li>
                    <li>
                      <a href="#">View Certificate</a>
                    </li>
                    <li>
                      <a href="#">Help</a>
                    </li>
                    <li>
                      <a onClick={doLogout} href="#">
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <CoursePlayerSidebar
              sessionId={null}
              courseId={courseId}
              isSidebarOpen={isSidebarOpen}
              setSidebarOpen={setSidebarOpen}
              page={Pages.CertificateList}
            />
            {/* <!-- End leftmenu Blue Part --> */}
            {/* <!-- start White Part --> */}
            <div className="mainbody">
              <div className="">
                {/* <!-- Start sticky icon --> */}
                {/* <div className="stikeybutton">
                <div className="sitckyinner">
                  <div>
                    <button className="stikeybuttons addbtn">
                      <img src={addNote} />
                      Add notes
                    </button>
                  </div>
                  <div>
                    <button className="stikeybuttons bookbtn">
                      <img src={bookMark} />
                      BOOKMARK
                    </button>
                  </div>
                </div>
              </div> */}
                {/* <!--End  sticky icon --> */}

                <div className="contentpart">
                  <div className="titlehead">
                    <h2>CERTIFICATES</h2>
                  </div>
                  <div className="quediv">
                    {certificates && certificates.length !== 0 ? (
                      <div className="mycomantable certificate-table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Assessment Date
                              </th>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Status
                              </th>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Score
                              </th>
                              <th
                                scope="col"
                                style={{ background: "#062A3B" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {certificates.map((c: any) => (
                              <>
                                <tr>
                                  <td>{c?.createdAt.substring(0, 10)}</td>
                                  <td>{"Completed"}</td>
                                  <td>{c?.score}</td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    // onClick={() => navigateToTopic(note)}
                                  >
                                    <a
                                      href={`https://afmdevbucket.s3.ap-south-1.amazonaws.com/certificates/${c?.userId}/${c?.courseId}/certificate.png`}
                                    >
                                      Download
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="mycomantable certificate-table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Assessment Date
                              </th>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Status
                              </th>
                              <th scope="col" style={{ background: "#062A3B" }}>
                                Score
                              </th>
                              <th
                                scope="col"
                                style={{ background: "#062A3B" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td>You have no certificates available!</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <footer>
              <div className="footertext">
                <div>
                  {/* <p>
                    © Copyright 2022 Aviation Mastermind. All Rights Reserved.
                  </p> */}
                  <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
                </div>
                <div className="quicklinks">
                  <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

const Container = styled.div`
  #wing-img {
    width: 600px;
    margin-top: 10px;
    margin-left: 50px;
  }
  #cong {
    position: absolute;
    top: 280px;
    left: 660px;
    font-size: 27px;
  }
  #para-p {
    position: absolute;
    top: 330px;
    left: 640px;
    font-size: 20px;
  }
  #para-p2 {
    position: absolute;
    top: 360px;
    left: 570px;
    font-size: 20px;
  }
  #para-p3 {
    position: absolute;
    top: 440px;
    left: 600px;
    font-size: 20px;
  }
  #para-p4 {
    position: absolute;
    top: 470px;
    left: 650px;
    font-size: 20px;
  }
  #down-certificate-btn {
    position: absolute;
    top: 520px;
    left: 680px;
    border: none;
    background-color: #c7b277;
    width: 250px;
    padding: 15px;
    font-size: 14px;
  }
  #instrction {
    color: #000000;
    position: absolute;
    top: 400px;
    left: 700px;
  }

  #slash {
    background-color: #062a3b;
    width: 3px;
    height: 90px;
    position: absolute;
    top: 70px;
    left: 410px;
  }

  #certificate-heading {
    position: absolute;
    top: 100px;
    left: 450px;
  }

  #first-ol {
    display: flex;
    flex-direction: row;
    // justify-content:space-evenly;
    list-style: none;
    background-color: #062a3b;
    color: #c7b277;
    width: 600px;
    height: 80px;
    margin-left: 50px;
    position: absolute;
    top: 255px;
  }

  #first-ol li {
    // margin-left: 100px;
    list-style: none;
    margin-top: 20px;
    text-align: justify;
    margin-right: 50px;
  }

  #second-ol {
    float: right;
    display: flex;
    flex-direction: row;
    // justify-content:space-around;
    background-color: #f0f0f0;
    color: #050505;
    width: 600px;
    height: 80px;
    margin-left: 50px;
    position: absolute;
    top: 336px;
  }

  #second-ol li {
    margin-right: 80px;
    list-style: none;
    margin-top: 20px;
  }
`;

export default CertificateList;
