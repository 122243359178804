import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ScreenOtp"; // Adjust the path for your CSS file
import logoafmcart from "../../assets/logoafmcart.png"; // Adjust the path for your image
import homeIcon from "../../assets/homeicon.svg"; // Adjust the path for your image
import "bootstrap/dist/css/bootstrap.min.css";

function ScreenOtp() {
  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const initialValues = {
    otp: "",
  };

  const handleSubmit = (values: any) => {
    // Handle form submission here
    console.log(values);
  };

  return (
    <section className="optlogin">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 cartlogoleft">
            <img src={logoafmcart} alt="Logo" />
          </div>
          <div className="col-6 homeright">
            <a href="#">
              <img src={homeIcon} alt="Home" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="titleofform">
              <h3>Enter the otp to complete the registration</h3>
            </div>
            <div className="otpmain">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="createform">
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <div className="formfield">
                      <Field
                        type="text"
                        name="otp"
                        className="form-control"
                        placeholder="Enter OTP"
                      />
                      <ErrorMessage
                        name="otp"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between otpcontent">
                    <p>
                      Enter the OTP sent to you on your register email address.
                    </p>
                    <p>
                      <a href="#">Resend OTP</a>
                    </p>
                  </div>
                  <div className="d-flex mbrow gap-20 justify-content-between">
                    <button type="submit" className="sign-btn otpbtn">
                      Verify OTP
                    </button>
                    <button type="button" className="sign-btn otpbtn">
                      Back
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScreenOtp;
