import React, { useEffect, useState } from "react";
import logo from "./images/logo.svg";
import usericon from "./images/usericon.svg";
import whiteusericon from "./images/whiteusericon.svg";
import camera from "./images/camera.svg";
import loginicon from "./images/loginicon.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
// import "./DownloadSamplebox.css";
import Group318 from "../dummy/images/Group 318.png";
import Group320 from "../dummy/images/Group 320.png";
import Group321 from "../dummy/images/Group 321.png";
import Group324 from "../dummy/images/Group 324.png";
import Group319 from "../dummy/images/Group 319.png";
import Group164 from "../dummy/images/Group 164.png";
import vectorimg2 from "../dummy/images/Vector -img2.png";
import Group325 from "../dummy/images/Group 325 (1).png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../Pages";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import {
  SubscriptionClient_Messages,
  SubscriptionClient_Name,
} from "../../actors/subscription/types";
import { ask } from "lib-js-c-modulo/index.mjs";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CheckoutForm from "../common/StripeComponent";
import StripeElements from "../common/StripeElements";
const StripePaymentGateway = () => {
  const navigate = useNavigate();
  const { subscriptionType } = useParams();
  const location = useLocation();
  const myObject = location.state;

  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <StripeElements subscriptionType={subscriptionType} course={myObject} />
      </div>
    </>
  );
};

export default StripePaymentGateway;
