import React from "react";
import { useNavigate, useParams } from "react-router";
import { Pages } from "../../Pages";
import CoursePlayerSidebar from "../common/CoursePlayerSidebar";
import "./AssessmentTryAgain.css";

const AssessmentTryAgain = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  console.log("COURSE ID FROM ASSESSMENT TRY AGAIN", courseId);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0 mymainparthtml">
            <CoursePlayerSidebar />
            <div className="mainbody">
              <div className="box">
                <div className="course-pass-page-wrapper">
                  <div
                    className="tryMaincontent"
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    //   height: "500px",
                    // }}
                  >
                    <svg
                      width="104"
                      height="104"
                      viewBox="0 0 104 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M51.9932 70.7268C48.1628 70.7268 45.0469 73.8684 45.0469 77.7307C45.0469 81.5914 48.1628 84.733 51.9932 84.733C55.8239 84.733 58.9406 81.5914 58.9406 77.7307C58.9406 73.8684 55.8231 70.7268 51.9932 70.7268ZM51.9932 80.3754C50.5659 80.3754 49.4068 79.1883 49.4068 77.7307C49.4068 76.2716 50.5659 75.0844 51.9932 75.0844C53.42 75.0844 54.5831 76.2716 54.5831 77.7307C54.5831 79.1898 53.4216 80.3754 51.9932 80.3754Z"
                        fill="#DF0101"
                      />
                      <path
                        d="M54.9109 19.7086C53.9868 19.4168 53.0063 19.2693 51.9957 19.2693C46.4105 19.2693 41.8673 23.8564 41.8775 29.7051L42.0249 31.1171C42.0249 31.1887 42.03 31.272 42.0354 31.342L44.8669 59.9759L45.0463 61.6526C45.0463 65.5136 48.1638 68.6549 51.9954 68.6549C55.8253 68.6549 58.942 65.5152 58.9323 61.8638L61.9432 31.3424C61.9448 31.3257 61.9479 31.3059 61.9479 31.2876L62.1121 29.7191C62.1183 29.6448 62.1226 29.5685 62.1226 29.4942C62.1226 28.2175 61.8899 26.9802 61.4432 25.8417C60.3358 22.893 57.881 20.5969 54.9109 19.7086ZM54.586 61.6545C54.586 63.1121 53.4257 64.2977 51.9961 64.2977C50.5658 64.2977 49.4051 63.1105 49.3938 61.437L46.3829 31.1288C46.3829 31.0588 46.3782 30.9782 46.3728 30.907L46.2249 29.495C46.2249 26.2603 48.8148 23.628 51.9961 23.628C52.5615 23.628 53.1016 23.7086 53.6331 23.8743C55.337 24.3837 56.7347 25.6922 57.3782 27.4035C57.623 28.0296 57.7522 28.6985 57.7658 29.3915L54.586 61.6545Z"
                        fill="#DF0101"
                      />
                      <path
                        d="M52 102C79.6142 102 102 79.6142 102 52C102 24.3858 79.6142 2 52 2C24.3858 2 2 24.3858 2 52C2 79.6142 24.3858 102 52 102Z"
                        stroke="#DF0101"
                        stroke-width="4"
                        stroke-miterlimit="10"
                      />
                    </svg>

                    <div className="try-container">
                      <h1>SORRY!</h1>
                      <br />
                      <div className="header">
                        <h4>You have not cleared the assessment</h4>
                      </div>
                      <div>
                        <h4>Please reattempt the assessment</h4>
                      </div>
                      <div
                        className="btndiv"
                        // style={{ marginTop: "30px" }}
                      >
                        <button
                          className="trybtn"
                          // style={{ padding: "30px" }}
                          onClick={() =>
                            navigate(`${Pages.Assessment}/${courseId}`)
                          }
                        >
                          TRY AGAIN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 p-0">
        <footer>
          <div className="footertext">
            <div>
              {/* <p>© Copyright 2022 Aviation Mastermind. All Rights Reserved.</p> */}
              <p>{`© Copyright ${new Date().getFullYear()} Aviation Mastermind. All Rights Reserved.`}</p>
            </div>
            <div className="quicklinks">
              <a href={Pages.PrivacyPolicy}>PRIVACY & TERMS</a>
            </div>
          </div>
        </footer>
      </div>
    </div>

    // </div>
  );
};

export default AssessmentTryAgain;
